<template>
    <f7-page name="cuenta" @page:beforein="checkdixpriv()">
        <!-- Top Navbar -->
        <f7-navbar :title="mpdusrinf.email" :subtitle="mpdusrinf.displayName" back-link="Back"></f7-navbar>

        <f7-block class="text-align-center no-margin-bottom">
            <img class="superavatar" :src="mpdusrinf.photoURL || '../static/heros/avatar.jpg'" />
            <br />
            <f7-chip media-bg-color="primary" :text="mpdusrinf.email">
                <font-awesome-icon :icon="['fad', 'circle-user']" class="fa-lg" slot="media"></font-awesome-icon>
            </f7-chip>
        </f7-block>
        <f7-block v-if="loadingPrivileges" class="text-align-center"><f7-preloader></f7-preloader></f7-block>
        <f7-block v-if="isInSendgridSupresionList">
            <f7c-alert type="danger">
                <span>This user email is in Sendgrid suppression list</span>
            </f7c-alert>
            <f7-button @click="deleteFromSendgridSuppressionList()" outline>Subscribe this user again</f7-button>
        </f7-block>
        <f7-block class="margin-bottom no-margin-top">
            <f7-block-title>
                User Information
                <span class="float-right">
                    Last sign in
                    {{ mpdusrinf.lastSignInTime ? $dayjs(mpdusrinf.lastSignInTime).format("MM/DD/YYYY") : "" }}
                </span>
            </f7-block-title>
        </f7-block>
        <f7-list inset class="no-margin-top">
            <f7-list-item
                v-for="(campo, indx) in flds"
                @click="updateValue(indx)"
                :key="indx"
                link="#"
                :header="campo"
                :title="mpdusrinf[indx]"
            >
                <f7-preloader slot="after" v-if="updt == indx"></f7-preloader>
                <font-awesome-icon v-else slot="after" :icon="['fad', 'pencil']"></font-awesome-icon>
            </f7-list-item>
            <f7-list-item
                v-if="Object.keys(rolesByProject).length > 0"
                header="Access time limit"
                :title="userAccessTimeLimitText"
                link="#"
                @click="isAccessTimeLimitPopupOpened = true"
            >
                <font-awesome-icon slot="after" :icon="['fad', 'pencil']"></font-awesome-icon>
            </f7-list-item>
        </f7-list>
        <template v-if="privxInfo.admin && cual == 'core'">
            <f7-block class="margin-bottom"><f7-block-title>Super Admin Privileges</f7-block-title></f7-block>
            <f7-list class="no-margin-top" inset>
                <f7-list-item footer="Projects Creation and global users management" title="Super Admin">
                    <f7-preloader
                        slot="after"
                        class="margin-right-half"
                        v-if="updt == 'admin' || loadingPrivileges"
                    ></f7-preloader>
                    <font-awesome-icon
                        v-if="!loadingPrivileges && mpdusrinf.uid == $store.state.user.uid"
                        slot="after"
                        :class="{ 'text-color-teal': privx.admin }"
                        :icon="privx.admin ? ['fad', 'check'] : ['fad', 'xmark']"
                    ></font-awesome-icon>
                    <f7-toggle
                        v-else-if="!loadingPrivileges"
                        slot="after"
                        ref="adminprivx"
                        @toggle:change="cambiando_admin($event)"
                        color="teal"
                        :checked="privx.admin"
                    ></f7-toggle>
                </f7-list-item>
            </f7-list>
        </template>
        <f7-block class="margin-bottom"><f7-block-title>Roles by project</f7-block-title></f7-block>
        <f7-block class="text-align-center" v-if="!loadingPrivileges && !Object.values(rolesByProject).length">
            User does not belong to {{ cual == "project" ? "this" : "any" }} project
        </f7-block>

        <f7-list class="no-margin-top" accordion-list inset>
            <f7-list-item
                v-for="(projectRolex, projectid) in rolesByProject"
                :key="projectid"
                accordion-item
                :opened="cual == 'project' && projectid == projectInFocus"
                :footer="projectRolex.theProject ? `${projectRolex.theProject.descr || ''}` : 'Loading...'"
                :class="{
                    'skeleton-text skeleton-effect-blink': !projectRolex.theProject,
                    'accordion-item-opened': cual == 'project' && projectid == projectInFocus,
                }"
            >
                <span slot="title" class="bold">
                    {{ projectRolex.theProject ? projectRolex.theProject.name : projectid }}
                </span>
                <f7-accordion-content v-if="projectRolex.theProject">
                    <f7-block
                        class="text-align-center bg-color-yellow padding margin-bottom"
                        v-if="cual == 'project' && mpdusrinf.request && mpdusrinf.request.pid == projectid"
                    >
                        There is an access request to
                        {{ projectRolex.theProject ? projectRolex.theProject.name : projectid }}
                        from this user. You can assign some privileges or
                        <f7-link @click="declineRequest(projectid)">decline the request</f7-link>
                    </f7-block>
                    <f7-list>
                        <f7-block class="text-align-center">Project level privileges</f7-block>
                        <f7-list-item
                            v-for="unpriv in projectprofiles"
                            :key="unpriv.id"
                            :title="unpriv.id === 'full' ? 'write' : unpriv.id"
                            :footer="unpriv.descr"
                            :class="{
                                disabled:
                                    (!projectRolex.admin[unpriv.id] &&
                                        ((unpriv.reqby && projectRolex.admin[unpriv.reqby]) ||
                                            (unpriv.reqs && !projectRolex.admin[unpriv.reqs]))) ||
                                    (unpriv.id === 'phi' &&
                                        (projectRolex.admin['project-admin'] === true ||
                                            projectRolex.admin.full === true)) ||
                                    (unpriv.id !== 'project-admin' && projectRolex.admin['project-admin'] === true),
                            }"
                        >
                            <f7-preloader
                                slot="after"
                                class="margin-right-half"
                                v-if="updt == projectid + unpriv.id"
                            ></f7-preloader>
                            <f7-toggle
                                slot="after"
                                @toggle:change="editaPrivsenProject($event, unpriv.id, projectid)"
                                color="teal"
                                :checked="projectRolex.admin[unpriv.id]"
                            ></f7-toggle>
                        </f7-list-item>
                        <f7-block class="text-align-center">
                            {{ projectRolex.theProject.grouper || "Institutions" }} level privileges
                        </f7-block>
                    </f7-list>
                    <f7-list v-for="unprivinst in projectRolex.prv" :key="unprivinst.id" inset>
                        <f7-list-item
                            divider
                            class="smalldivider"
                            :title="projectRolex.theProject.institutions[unprivinst.id].name"
                        ></f7-list-item>
                        <f7-list-item title="read" footer="User is able to read entities from this institution">
                            <f7-toggle slot="after" color="purple" checked disabled></f7-toggle>
                        </f7-list-item>
                        <f7-list-item
                            v-for="unpriv in institutionProfiles"
                            :key="unpriv.id"
                            :title="unpriv.id === 'create' ? 'write' : unpriv.id"
                            :footer="unpriv.descr"
                        >
                            <f7-preloader
                                slot="after"
                                class="margin-right-half"
                                v-if="updt == projectid + unpriv.id + unprivinst.id"
                            ></f7-preloader>
                            <f7-toggle
                                slot="after"
                                @toggle:change="editaPrivsenInstituion($event, unpriv.id, projectid, unprivinst.id)"
                                color="purple"
                                :checked="
                                    projectRolex.prv[unprivinst.id].det &&
                                    projectRolex.prv[unprivinst.id].det[unpriv.id]
                                "
                                :disabled="
                                    (unpriv.id === 'download' && projectRolex.admin.download) ||
                                    (unpriv.id === 'create' && projectRolex.admin.full) ||
                                    (unpriv.id === 'phi' &&
                                        projectRolex.prv[unprivinst.id].det &&
                                        (projectRolex.prv[unprivinst.id].det.update ||
                                            projectRolex.prv[unprivinst.id].det.create)) ||
                                    (unpriv.id === 'phi' && projectRolex.admin.phi) ||
                                    projectRolex.admin['project-admin'] === true
                                "
                            ></f7-toggle>
                        </f7-list-item>
                    </f7-list>
                    <f7-block>
                        <f7-block-title class="text-color-red text-align-center">Danger zone:</f7-block-title>
                        <f7-block>
                            <f7-button color="red" @click="eliminarDelProyecto(projectid)" outline raised>
                                Remove from {{ projectRolex.theProject ? projectRolex.theProject.name : projectid }}
                            </f7-button>
                        </f7-block>
                    </f7-block>
                </f7-accordion-content>
            </f7-list-item>
        </f7-list>

        <f7-block
            v-if="!loadingPrivileges && (cual == 'core' || (cual == 'project' && !rolesByProject[projectInFocus]))"
            class="margin-top"
        >
            <f7-button large fill @click="abrirprivporvac()">
                <font-awesome-icon :icon="['fad', 'plus']"></font-awesome-icon>
                Add to project
            </f7-button>
        </f7-block>

        <f7-block
            v-if="cual == 'project' && !Object.values(rolesByProject).length && mpdusrinf.request"
            class="margin-top"
        >
            Access requested at
            {{ $dayjs.unix(mpdusrinf.request.wh).format("DD/MM/YYYY @HH:mm:ss") }}
            with reason: {{ mpdusrinf.request.reason }}
            <f7-button large outline color="red" @click="declineRequest(null)">
                <font-awesome-icon class="fa-lg" :icon="['fad', 'hand']"></font-awesome-icon>
                Reject Access Request
            </f7-button>
        </f7-block>

        <f7-popup class="privsenvacunat" :opened="projecprivsopened" @popup:closed="projecprivsopened = false">
            <privs-crear
                v-if="projecprivsopened"
                :uid="mpdusrinf.uid"
                :forceProject="cual == 'project' ? projectInFocus : null"
                :rolesByProject="rolesByProject"
                @neoPrivsProject="neoprivesenproject"
            ></privs-crear>
        </f7-popup>

        <AccessTimeLimitPopup
            :opened="isAccessTimeLimitPopupOpened"
            :userId="mpdusrinf.uid"
            :current-access-limit="rolesByProject[projectInFocus] ? rolesByProject[projectInFocus].accessLimit : null"
            @closed="isAccessTimeLimitPopupOpened = false"
            @reset="handleUserAccessTimeLimitReset"
            @set="setAccessTimeLimit"
        />
    </f7-page>
</template>
<script>
    import { mapGetters, mapState } from "vuex";
    import { commonmixin } from "../mixins/common";
    import privsCrear from "../components/privs_proj_crear.vue";
    import AccessTimeLimitPopup from "../components/AccessTimeLimitPopup.vue";
    export default {
        mixins: [commonmixin],
        components: {
            privsCrear,
            AccessTimeLimitPopup,
        },
        props: ["userInfo", "cual"],
        data() {
            return {
                privx: {},
                rolesByProject: {},
                flds: {
                    // email: 'Access email',
                    displayName: "Display name",
                },
                updt: null,
                projecprivsopened: false,
                isAccessTimeLimitPopupOpened: false,
                loadingPrivileges: false,
                actualizando: null,
                isInSendgridSupresionList: false,
            };
        },
        computed: {
            ...mapState(["profiles", "user"]),
            ...mapGetters(["privxInfo", "projectInFocusInfo"]),
            projectInFocus() {
                return this.$store.state.projectInFocus || this.$store.state.adminprojectFocus;
            },
            mpdusrinf() {
                return this.userInfo || {};
            },
            projectprofiles() {
                let glbpirvs = Object.values(this.profiles.project || {});

                if (!this.projectInFocusInfo.isDeidentificationEnabled) {
                    glbpirvs = glbpirvs.filter((p) => p.id !== "phi");
                }

                return glbpirvs
                    .sort((a, b) => {
                        if (a.o > b.o) {
                            return 1;
                        } else if (a.o < b.o) {
                            return -1;
                        } else {
                            return 0;
                        }
                    })
                    .filter((p) => {
                        return !p.hidden && !(p.id === "project-admin" && !this.privxInfo.admin);
                    });
            },
            institutionProfiles() {
                let instiprivs = Object.values(this.profiles.institution || {});

                if (!this.projectInFocusInfo.isDeidentificationEnabled) {
                    instiprivs = instiprivs.filter((p) => p.id !== "phi");
                }

                return instiprivs
                    .sort((a, b) => {
                        if (a.o > b.o) {
                            return 1;
                        } else if (a.o < b.o) {
                            return -1;
                        } else {
                            return 0;
                        }
                    })
                    .filter((p) => !p.hidden);
            },
            userAccessTimeLimitText() {
                const defaultText = "User has no access time limit";

                if (
                    !this.rolesByProject[this.projectInFocus] ||
                    !this.rolesByProject[this.projectInFocus].accessLimit
                ) {
                    return defaultText;
                }

                const accessLimit = this.rolesByProject[this.projectInFocus].accessLimit;
                const isBeforeToday = this.$dayjs(`${accessLimit.date} ${accessLimit.time}`).isBefore(this.$dayjs());

                if (isBeforeToday) {
                    const timeAgo = this.$dayjs(`${accessLimit.date} ${accessLimit.time}`).fromNow();
                    return `User access expired ${timeAgo}`;
                }

                const timeFromNow = this.$dayjs(`${accessLimit.date} ${accessLimit.time}`).fromNow();
                return `User has access until ${accessLimit.date} at ${accessLimit.time} (${timeFromNow})`;
            },
        },
        mounted() {
            // console.log('mpdusrinf.request',this.mpdusrinf.request)
        },
        methods: {
            handleUserAccessTimeLimitReset() {
                this.$set(this.rolesByProject[this.projectInFocus], "accessLimit", null);
                this.isAccessTimeLimitPopupOpened = false;
            },
            async setAccessTimeLimit(accessLimit) {
                try {
                    console.log("setAccessTimeLimit", accessLimit);

                    this.$f7.dialog.preloader("Updating...");

                    const response = await this.commonExecute(
                        { projectId: this.projectInFocus, userId: this.mpdusrinf.uid, accessLimit },
                        "v2_setUserAccessTimeLimitInProject",
                        false,
                    );

                    console.log("response", response);

                    if (response.error) {
                        throw new Error(response.error.message);
                    }

                    this.$set(this.rolesByProject[this.projectInFocus], "accessLimit", accessLimit);

                    this.$f7.dialog.close();
                    this.isAccessTimeLimitPopupOpened = false;
                } catch (error) {
                    console.error(error.message);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error.message);
                }
            },
            async eliminarDelProyecto(pid) {
                try {
                    this.$f7.dialog.preloader("Removing from project...");
                    let datous = await this.commonExecute(
                        { uid: this.mpdusrinf.uid, pid },
                        "v2_removeFromProject",
                        false,
                    );
                    this.updt = null;
                    if (datous.error) {
                        throw new Error(datous.error.message);
                    }
                    //  let camiosds={};
                    //  camiosds[`privsByProject/${this.mpdusrinf.uid}${pid}`]=null;
                    //  if(this.mpdusrinf.request && this.mpdusrinf.request.pid==pid){
                    //    camiosds[`privsByProject_req/${this.mpdusrinf.uid}${this.pid}`]=null;
                    //  }
                    //  console.log('tratara de guardar esto',camiosds,this.privxInfo)
                    //  await this.$firebase.database().ref().update(camiosds);
                    this.$delete(this.rolesByProject, pid);
                    if (this.mpdusrinf.request && this.mpdusrinf.request.pid == pid) {
                        this.$delete(this.mpdusrinf, "request");
                    }
                    this.$store.commit("setWhatTo", {
                        what: "forceUsersReload",
                        to: true,
                    });

                    if (this.mpdusrinf.uid == this.user.uid) {
                        let localUser = Object.assign({}, this.user);
                        localUser.privsByProject[pid] = null;
                        this.$store.commit("setObjTo", { what: "user", to: localUser });
                    }

                    this.$f7.dialog.close();
                } catch (error) {
                    this.$f7.dialog.close();
                    console.error(error.code, error.message);
                    this.$f7.dialog.alert(error.message, error.code || "Error");
                }
            },
            async declineRequest(conremovalinpid) {
                // console.log('decline requests');
                this.$f7.dialog.preloader("Declining request...");
                let projectid = conremovalinpid || this.projectInFocus;
                let cambios = {};
                cambios[`privsByProject_req/${this.mpdusrinf.uid}${projectid}`] = null;
                if (conremovalinpid) {
                    cambios[`privsByProject/${this.mpdusrinf.uid}${projectid}`] = null;
                }
                await this.$firebase.database().ref().update(cambios);
                this.$f7.dialog.close();
                this.$delete(this.mpdusrinf, "request");
                if (conremovalinpid) {
                    this.$delete(this.rolesByProject, projectid);
                }
                this.$store.commit("setWhatTo", {
                    what: "forceUsersReload",
                    to: true,
                });
                this.avisaOk("Request declined");

                this.$f7.views.main.router.back();
            },
            async cambiando_admin(valor) {
                try {
                    // console.log(valor);
                    if ((valor && !this.privx.admin) || (!valor && this.privx.admin)) {
                        // console.log('ES CAMBIAZO, HACER ALGO');
                        this.updt = "admin";
                        let tosave = valor || null;
                        await this.$firebase.database().ref(`privx/${this.mpdusrinf.uid}/admin`).set(tosave);
                        this.updt = null;
                        this.$set(this.privx, "admin", valor);
                        this.avisaOk("Changes saved OK");
                    }
                } catch (error) {
                    this.updt = null;
                    this.$refs.adminprivx.toggle();
                    console.log(error);
                    return this.$f7.dialog.alert(error.message, error.code);
                }
            },
            updateValue(cual) {
                return this.$f7.dialog.prompt(
                    "Please provide a new " + this.flds[cual],
                    (cambio) => {
                        if (!cambio) {
                            return this.$f7.dialog.alert("New property can not be empty", "Error");
                        }
                        // console.log('new is',cual,cambio);
                        this.guardarCambio({
                            uid: this.mpdusrinf.uid,
                            cual: cual,
                            valor: cambio.trim(),
                        });
                    },
                    () => {},
                    this.mpdusrinf[cual],
                );
            },
            async guardarCambio({ uid, cual, valor }) {
                try {
                    this.updt = cual;
                    let datous = await this.commonExecute({ uid, cual, valor }, "v2_updateUserAccount_core", false);
                    this.updt = null;
                    if (datous.error) {
                        throw new Error(datous.error.message);
                    }
                    // console.log('creacion ok',datous);
                    this.mpdusrinf[cual] = valor;
                    this.avisaOk();
                } catch (error) {
                    this.updt = null;
                    console.error(error.code, error.message);
                    this.$f7.dialog.alert(error.message, error.code || "Error");
                }
            },
            abrirprivporvac() {
                this.projecprivsopened = true;
            },
            async checkdixpriv() {
                try {
                    this.loadingPrivileges = true;
                    // console.log('CUAL ES ',this.cual)
                    //traer privilegios centrales y privilegios por proyecto
                    const call = this.$firebase.functions().httpsCallable("v2_isInSendgridSupresionList");
                    const response = await call({
                        projectId: this.projectInFocusInfo.id,
                        email: this.mpdusrinf.email,
                        dev: this.$store.state.dev,
                    });
                    console.log(response);
                    if (response.error) {
                        throw new Error(response.error);
                    }
                    if (response.data.isInSendgridSupresionList) {
                        this.isInSendgridSupresionList = true;
                    }
                    if (this.cual == "core") {
                        let promesex = [
                            this.$firebase
                                .database()
                                .ref("privsByProject")
                                .orderByChild("uid")
                                .equalTo(this.mpdusrinf.uid)
                                .once("value"),
                            this.$firebase.database().ref(`privx/${this.mpdusrinf.uid}`).once("value"),
                        ];
                        let multisnapsx = await Promise.all(promesex);
                        if (multisnapsx[0].exists()) {
                            multisnapsx[0].forEach((childsnap) => {
                                this.addProjectToUserUI(childsnap.val());
                            });
                        }
                        if (multisnapsx[1].exists()) {
                            this.privx = Object.assign({}, multisnapsx[1].val());
                            //  console.log('sus pvix',this.privx);
                        }
                    } else {
                        let projecinfo = await this.$firebase
                            .database()
                            .ref(`privsByProject/${this.mpdusrinf.uid}${this.projectInFocus}`)
                            .once("value");
                        if (projecinfo.exists()) {
                            this.addProjectToUserUI(projecinfo.val());
                        }
                    }
                    this.loadingPrivileges = false;
                    let institutions = this.rolesByProject?.[this.projectInFocus]?.theProject?.institutions;
                    let myIstitutionId = Object.keys(this.rolesByProject?.[this.projectInFocus]?.prv || {})[0];
                    let restrictAccess = institutions?.[myIstitutionId]?.restrictAccess;
                    let institutionName = institutions?.[myIstitutionId]?.name;
                    if (restrictAccess) {
                        this.$f7.dialog
                            .create({
                                title: `Inactivated institution`,
                                text: `This user belongs to the institution ${institutionName} but it is inactivated, do you want to change the user's institution?`,
                                buttons: [{ text: "NO" }, { text: "YES" }],
                                onClick: async (dialog, index) => {
                                    if (index === 0) {
                                        return;
                                    } else if (index === 1) {
                                        this.abrirprivporvac();
                                    }
                                },
                            })
                            .open();
                    } else if (!Object.values(this.rolesByProject).length) {
                        this.abrirprivporvac();
                    }
                } catch (error) {
                    this.loadingPrivileges = false;
                    console.error(error);
                    this.$f7.dialog.alert(error.message, error.code);
                }
            },
            addProjectToUserUI(projectprivxmeta) {
                // console.log('recibiendo',projectprivxmeta);
                let theProject = this.$store.state.projects[projectprivxmeta.pid];
                if (theProject) {
                    projectprivxmeta.theProject = theProject;
                } else {
                    return;
                }
                if (!projectprivxmeta.admin) {
                    projectprivxmeta.admin = {};
                }
                this.$set(this.rolesByProject, projectprivxmeta.pid, projectprivxmeta);

                if (this.mpdusrinf.request && this.mpdusrinf.request.pid == projectprivxmeta.pid) {
                    this.$delete(this.mpdusrinf, "request");
                    this.requestAcceptedsendEmailToUser();
                }

                this.$store.commit("setWhatTo", {
                    what: "forceUsersReload",
                    to: true,
                });
                if (!theProject) {
                    // console.log('pidiendo info projecto');
                    return this.$firebase
                        .database()
                        .ref("projects/" + projectprivxmeta.pid)
                        .once("value")
                        .then((snpsht) => {
                            // console.log('llegando info proyecto')
                            if (!snpsht.exists()) return;
                            let proyectinfo = snpsht.val() || {};
                            console.log("proyectinfo", proyectinfo);
                            this.$set(this.rolesByProject[projectprivxmeta.pid], "theProject", proyectinfo);
                            this.$store.commit("updtObjProperty", {
                                what: "projects",
                                to: proyectinfo,
                            });
                        })
                        .catch((error) => {
                            console.log("error de retrieval de proyect", error);
                            this.$f7.dialog.alert(error.message);
                        });
                }
            },
            async requestAcceptedsendEmailToUser() {
                try {
                    this.mpdusrinf;
                    let call = this.$firebase.functions().httpsCallable("v2_requestAcceptedsendEmailToUser");
                    const response = await call({
                        projectId: this.projectInFocusInfo.id,
                        email: this.mpdusrinf.email,
                        dev: this.$store.state.dev,
                    });
                    if (response.error) {
                        throw new Error(error.message);
                    }
                } catch (error) {
                    console.log(error);
                    this.$f7.dialog.alert(error.message);
                }
            },
            async borraRequestSilently(pid) {
                if (this.mpdusrinf.request && this.mpdusrinf.request.pid == pid) {
                    await this.$firebase.database().ref(`privsByProject_req/${this.mpdusrinf.uid}${pid}`).set(null);
                    this.$delete(this.mpdusrinf, "request");
                }
            },
            async editaPrivsenProject(neovalor, privilegioid, projectid) {
                try {
                    if (neovalor == false) neovalor = null;
                    //solo actualizar si valor es distinto al que hay
                    let valPrevio = this.rolesByProject[projectid].admin
                        ? this.rolesByProject[projectid].admin[privilegioid]
                        : null;

                    const userInstitutionId = Object.keys(this.rolesByProject?.[projectid]?.prv || {})[0];
                    console.log("neovalor", privilegioid, neovalor, valPrevio);
                    if (valPrevio != neovalor) {
                        console.log("entro");
                        let promises = [];
                        let call = this.$firebase.functions().httpsCallable("v2_updatedPrivsSetNewLog");
                        promises.push(
                            call({
                                dev: this.$store.state.dev,
                                projectid: projectid,
                                uid: this.mpdusrinf.uid,
                                subAction: "At project level",
                                element: `${privilegioid == "full" ? "write" : privilegioid} ${
                                    neovalor ? "on" : "off"
                                }`,
                            }),
                        );
                        //  console.log(this.mpdusrinf.uid,neovalor,privilegioid,projectid);
                        this.updt = projectid + privilegioid;
                        if (!neovalor) {
                            neovalor = null;
                        }

                        if (privilegioid === "project-admin") {
                            if (neovalor === true) {
                                const updates = {};

                                updates[`privsByProject/${this.mpdusrinf.uid}${projectid}/admin/project-admin`] = true;
                                updates[`privsByProject/${this.mpdusrinf.uid}${projectid}/admin/read`] = true;
                                updates[`privsByProject/${this.mpdusrinf.uid}${projectid}/admin/full`] = true;
                                updates[`privsByProject/${this.mpdusrinf.uid}${projectid}/admin/phi`] = true;
                                updates[`privsByProject/${this.mpdusrinf.uid}${projectid}/admin/download`] = true;
                                updates[`privsByProject/${this.mpdusrinf.uid}${projectid}/admin/accounts`] = true;
                                updates[`privsByProject/${this.mpdusrinf.uid}${projectid}/admin/activity-feed`] = true;

                                updates[
                                    `privsByProject/${this.mpdusrinf.uid}${projectid}/prv/${userInstitutionId}/det/create`
                                ] = true;
                                updates[
                                    `privsByProject/${this.mpdusrinf.uid}${projectid}/prv/${userInstitutionId}/det/update`
                                ] = true;
                                updates[
                                    `privsByProject/${this.mpdusrinf.uid}${projectid}/prv/${userInstitutionId}/det/download`
                                ] = true;
                                updates[
                                    `privsByProject/${this.mpdusrinf.uid}${projectid}/prv/${userInstitutionId}/det/phi`
                                ] = true;

                                promises.push(this.$firebase.database().ref().update(updates));
                                await Promise.all(promises);
                                this.$set(this.rolesByProject[projectid].admin, "project-admin", true);
                                this.$set(this.rolesByProject[projectid].admin, "read", true);
                                this.$set(this.rolesByProject[projectid].admin, "full", true);
                                this.$set(this.rolesByProject[projectid].admin, "phi", true);
                                this.$set(this.rolesByProject[projectid].admin, "download", true);
                                this.$set(this.rolesByProject[projectid].admin, "accounts", true);
                                this.$set(this.rolesByProject[projectid].admin, "activity-feed", true);

                                if (!this.rolesByProject?.[projectid]?.prv?.[userInstitutionId]?.det) {
                                    this.$set(this.rolesByProject[projectid].prv[userInstitutionId], "det", {});
                                }

                                this.$set(this.rolesByProject[projectid].prv[userInstitutionId].det, "create", true);
                                this.$set(this.rolesByProject[projectid].prv[userInstitutionId].det, "update", true);
                                this.$set(this.rolesByProject[projectid].prv[userInstitutionId].det, "download", true);
                                this.$set(this.rolesByProject[projectid].prv[userInstitutionId].det, "phi", true);
                            } else {
                                const updates = {};

                                updates[`privsByProject/${this.mpdusrinf.uid}${projectid}/admin/project-admin`] =
                                    neovalor;

                                const userHadAdminReadPrivilege =
                                    this.rolesByProject[projectid].admin &&
                                    this.rolesByProject[projectid].admin["read"] === true;

                                if (!(userHadAdminReadPrivilege && !neovalor)) {
                                    updates[`privsByProject/${this.mpdusrinf.uid}${projectid}/admin/phi`] = neovalor;
                                }

                                promises.push(this.$firebase.database().ref().update(updates));
                                await Promise.all(promises);
                                if (!this.rolesByProject[projectid].admin) {
                                    this.rolesByProject[projectid].admin = {};
                                }

                                this.$set(this.rolesByProject[projectid].admin, "project-admin", neovalor);

                                if (!(userHadAdminReadPrivilege && !neovalor)) {
                                    this.$set(this.rolesByProject[projectid].admin, "phi", neovalor);
                                }
                            }
                        } else if (privilegioid === "full" && neovalor === true) {
                            const updates = {};

                            updates[`privsByProject/${this.mpdusrinf.uid}${projectid}/admin/full`] = true;
                            updates[`privsByProject/${this.mpdusrinf.uid}${projectid}/admin/phi`] = true;

                            updates[
                                `privsByProject/${this.mpdusrinf.uid}${projectid}/prv/${userInstitutionId}/det/create`
                            ] = true;
                            updates[
                                `privsByProject/${this.mpdusrinf.uid}${projectid}/prv/${userInstitutionId}/det/update`
                            ] = true;
                            updates[
                                `privsByProject/${this.mpdusrinf.uid}${projectid}/prv/${userInstitutionId}/det/phi`
                            ] = true;

                            promises.push(this.$firebase.database().ref().update(updates));
                            await Promise.all(promises);
                            if (!this.rolesByProject[projectid].admin) {
                                this.rolesByProject[projectid].admin = {};
                            }

                            this.$set(this.rolesByProject[projectid].admin, "full", true);
                            this.$set(this.rolesByProject[projectid].admin, "phi", true);

                            if (!this.rolesByProject?.[projectid]?.prv?.[userInstitutionId]?.det) {
                                this.$set(this.rolesByProject[projectid].prv[userInstitutionId], "det", {});
                            }

                            this.$set(this.rolesByProject[projectid].prv[userInstitutionId].det, "create", true);
                            this.$set(this.rolesByProject[projectid].prv[userInstitutionId].det, "update", true);
                            this.$set(this.rolesByProject[projectid].prv[userInstitutionId].det, "phi", true);
                        } else if (privilegioid === "read") {
                            const updates = {};

                            updates[`privsByProject/${this.mpdusrinf.uid}${projectid}/admin/read`] = neovalor;

                            const userHadProjectAdminPrivilege =
                                this.rolesByProject[projectid].admin &&
                                this.rolesByProject[projectid].admin["project-admin"] === true;

                            if (!neovalor) {
                                if (!userHadProjectAdminPrivilege) {
                                    updates[`privsByProject/${this.mpdusrinf.uid}${projectid}/admin/phi`] = neovalor;
                                }
                                updates[`privsByProject/${this.mpdusrinf.uid}${projectid}/admin/download`] = neovalor;
                            }

                            promises.push(this.$firebase.database().ref().update(updates));
                            await Promise.all(promises);
                            if (!this.rolesByProject[projectid].admin) {
                                this.rolesByProject[projectid].admin = {};
                            }

                            this.$set(this.rolesByProject[projectid].admin, "read", neovalor);

                            if (!neovalor) {
                                if (!userHadProjectAdminPrivilege) {
                                    this.$set(this.rolesByProject[projectid].admin, "phi", neovalor);
                                }
                                this.$set(this.rolesByProject[projectid].admin, "download", neovalor);
                            }
                        } else if (privilegioid === "download" && neovalor === true) {
                            const updates = {};

                            updates[`privsByProject/${this.mpdusrinf.uid}${projectid}/admin/download`] = true;
                            updates[
                                `privsByProject/${this.mpdusrinf.uid}${projectid}/prv/${userInstitutionId}/det/download`
                            ] = true;

                            promises.push(this.$firebase.database().ref().update(updates));
                            await Promise.all(promises);
                            this.$set(this.rolesByProject[projectid].admin, "download", true);

                            if (!this.rolesByProject?.[projectid]?.prv?.[userInstitutionId]?.det) {
                                this.$set(this.rolesByProject[projectid].prv[userInstitutionId], "det", {});
                            }

                            this.$set(this.rolesByProject[projectid].prv[userInstitutionId].det, "download", true);
                        } else {
                            promises.push(
                                this.$firebase
                                    .database()
                                    .ref(`privsByProject/${this.mpdusrinf.uid}${projectid}/admin/${privilegioid}`)
                                    .set(neovalor),
                            );
                            await Promise.all(promises);

                            if (privilegioid === "phi" && neovalor === true) {
                                this.editaPrivsenInstituion(true, "phi", projectid, userInstitutionId);
                            }
                        }

                        if (!this.rolesByProject[projectid].admin) {
                            this.$set(this.rolesByProject[projectid], "admin", {
                                [privilegioid]: neovalor,
                            });
                        } else {
                            this.$set(this.rolesByProject[projectid].admin, privilegioid, neovalor);
                        }
                        if (neovalor) {
                            this.borraRequestSilently(projectid);
                        } else if (
                            !neovalor &&
                            privilegioid === "read" &&
                            this.rolesByProject[projectid].admin["full"]
                        ) {
                            this.editaPrivsenProject(null, "full", projectid);
                            this.editaPrivsenProject(null, "phi", projectid);
                        }
                        if (this.mpdusrinf.uid == this.user.uid) {
                            let privsSnap = await this.$firebase
                                .database()
                                .ref(`privsByProject/${this.mpdusrinf.uid}${projectid}`)
                                .once("value");

                            if (privsSnap.exists()) {
                                let privs = privsSnap.val() || {};
                                let localUser = Object.assign({}, this.user);
                                localUser.privsByProject[projectid] = privs;
                                this.$store.commit("setObjTo", { what: "user", to: localUser });
                            }
                        }
                        this.updt = null;
                        this.avisaOk();
                    }
                } catch (error) {
                    console.error(error);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error.message, "There was an error while updating the user's privileges");
                }
            },
            async editaPrivsenInstituion(neovalor, privilegioid, projectid, institucionid) {
                try {
                    let valPrevio =
                        this.rolesByProject?.[projectid]?.prv &&
                        this.rolesByProject?.[projectid].prv[institucionid] &&
                        this.rolesByProject[projectid].prv[institucionid].det
                            ? this.rolesByProject[projectid].prv[institucionid].det[privilegioid]
                            : null;
                    if (neovalor == false) neovalor = null;
                    if (valPrevio != neovalor) {
                        //  console.log(this.mpdusrinf.uid,neovalor,privilegioid,projectid,institucionid);
                        this.updt = projectid + privilegioid + institucionid;
                        if (!neovalor) {
                            neovalor = null;
                        }
                        let call = this.$firebase.functions().httpsCallable("v2_updatedPrivsSetNewLog");
                        let promises = [
                            call({
                                dev: this.$store.state.dev,
                                projectid: projectid,
                                uid: this.mpdusrinf.uid,
                                subAction: `At ${this.projectInFocusInfo?.institutions?.[institucionid]?.name}`,
                                element: `${privilegioid} ${neovalor ? "on" : "off"}`,
                            }),
                            this.$firebase
                                .database()
                                .ref(
                                    `privsByProject/${this.mpdusrinf.uid}${projectid}/prv/${institucionid}/det/${privilegioid}`,
                                )
                                .set(neovalor),
                        ];

                        await Promise.all(promises);
                        if (!this.rolesByProject[projectid].prv[institucionid].det) {
                            this.$set(this.rolesByProject[projectid].prv[institucionid], "det", {
                                [privilegioid]: neovalor,
                            });
                        } else {
                            this.$set(this.rolesByProject?.[projectid].prv[institucionid].det, privilegioid, neovalor);
                        }
                        this.updt = null;

                        if (privilegioid === "create" && !neovalor) {
                            this.editaPrivsenInstituion(false, "update", projectid, institucionid);
                        }

                        if (privilegioid === "create" || (privilegioid === "update" && neovalor)) {
                            this.editaPrivsenInstituion(true, "phi", projectid, institucionid);
                        }

                        if (neovalor) {
                            this.borraRequestSilently(projectid);
                        }
                        this.avisaOk();
                    }
                } catch (error) {
                    console.error(error);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error.message, "There was an error while updating the user's privileges");
                }
            },
            neoprivesenproject(payerloadx) {
                this.projecprivsopened = false;
                // console.log('nuevos privilegios',payerloadx);
                this.addProjectToUserUI(payerloadx);
            },
            async deleteFromSendgridSuppressionList() {
                try {
                    this.$f7.dialog.preloader("Deleting from suppression list...");
                    const call = this.$firebase.functions().httpsCallable("v2_sendgridDeleteGlobalSuppression");
                    const response = await call({
                        projectId: this.projectInFocusInfo.id,
                        email: this.mpdusrinf.email,
                        dev: this.$store.state.dev,
                    });
                    console.log(response);
                    if (response.error) {
                        throw new Error(response.error);
                    }
                    this.isInSendgridSupresionList = false;
                    this.$f7.dialog.close();
                } catch (error) {
                    console.log(error);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error.message);
                }
            },
        },
    };
</script>
<style>
    .superavatar {
        width: 150px;
        border-radius: 50%;
        border: 6px solid white;
    }
</style>
