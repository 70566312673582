<template>
    <f7-card
        :class="{
            highlightRequired:
                highlightRequired &&
                preg.req &&
                (!localentity ||
                    (preg.tipo == 'elige_many' &&
                        !Object.values(localentity).some((alt) => {
                            return alt;
                        }))),
        }"
    >
        <f7-list media-list class="no-margin titlebold borbotpix">
            <f7-list-item>
                <span slot="title" v-html="preg.encabezado"></span>
                <span slot="footer" :class="{ 'text-color-red': preg.req }">
                    {{ preg.req ? "Required" : "Optional" }}
                </span>
                <f7-link slot="after" v-if="preg.linfo" :tooltip="preg.linfo">
                    <font-awesome-icon class="fa-lg" :icon="['fad', 'circle-info']" />
                </f7-link>
            </f7-list-item>
        </f7-list>
        <f7-searchbar
            v-if="alternativasActivas(preg) > 5 && alternativasActivas(preg) <= 15"
            disable-button-text="Cancel"
            @input="seteaEste($event.target.value, preg.id)"
            :custom-search="true"
            placeholder="Search"
            :disable-button="false"
            :backdrop="false"
            :clear-button="true"
            class="no-margin no-padding"
        ></f7-searchbar>
        <f7-card-content>
            <f7-block v-if="preg.tipo == 'elige_una' && !preg.req" class="no-margin-top margin-bottom">
                <f7-button @click="setprimitive(preg.id, null, true)">Clear selection</f7-button>
            </f7-block>
            <f7-list class="nowhitespacetitle scrolmaxalt" v-if="alternativasActivas(preg) > 15">
                <f7-list-item
                    smart-select
                    :ref="preg.id"
                    :smart-select-params="{
                        openIn: 'popup',
                        popupTabletFullscreen: true,
                        closeOnSelect: preg.tipo == 'elige_una' ? true : false,
                        virtualList: true,
                        searchbar: true,
                        popupCloseLinkText: preg.tipo == 'elige_una' ? 'Close' : 'Done',
                        cssClass: 'doneButton',
                    }"
                    :title="preg.encabezado"
                >
                    <select
                        v-if="preg.tipo == 'elige_many'"
                        :name="preg.id"
                        multiple
                        @change="selectionaOptionVirtualList(preg.id)"
                    >
                        <option
                            v-for="alt in getArrayOfPregs(preg.alternativas, searchHolders[preg.id], preg.id)"
                            :selected="localentity && localentity[alt.id]"
                            :key="alt.id"
                            :value="alt.id"
                        >
                            {{ alt.tx }}
                        </option>
                    </select>
                    <select
                        v-else-if="preg.tipo == 'elige_una'"
                        :name="preg.id"
                        @change="setprimitive(preg.id, $event.target.value)"
                        :value="localentity"
                    >
                        <option
                            v-for="alt in getArrayOfPregs(preg.alternativas, searchHolders[preg.id], preg.id)"
                            :selected="localentity && localentity == alt.id"
                            :key="alt.id"
                            :value="alt.id"
                        >
                            {{ alt.tx }}
                        </option>
                    </select>
                </f7-list-item>
            </f7-list>
            <f7-list class="nowhitespacetitle scrolmaxalt" v-else-if="preg.tipo == 'elige_many'">
                <f7-list-item
                    v-for="alt in getArrayOfPregs(preg.alternativas, searchHolders[preg.id], preg.id)"
                    @change="selectionaOption(preg.id, alt.id, $event.target.checked)"
                    :checked="localentity && localentity[alt.id]"
                    :key="alt.id"
                    checkbox
                    :value="alt.id"
                    :title="alt.tx"
                ></f7-list-item>
                <f7-list-item
                    v-if="searchHolders[preg.id] && !lenghavailable[preg.id]"
                    title="No results"
                ></f7-list-item>
            </f7-list>
            <f7-list class="nowhitespacetitle scrolmaxalt" v-else-if="preg.tipo == 'elige_una'">
                <f7-list-item
                    v-for="alt in getArrayOfPregs(preg.alternativas, searchHolders[preg.id], preg.id)"
                    :name="`elradio_${preg.id}`"
                    @change="setprimitive(preg.id, alt.id)"
                    :checked="localentity == alt.id"
                    :key="alt.id"
                    radio
                    :value="alt.id"
                    :title="alt.tx"
                ></f7-list-item>
                <f7-list-item
                    v-if="searchHolders[preg.id] && !lenghavailable[preg.id]"
                    title="No results"
                ></f7-list-item>
            </f7-list>
            <f7-list v-else>
                <f7-list-input
                    v-if="preg.tipo == 'input_fecha'"
                    type="datepicker"
                    label="Specify Date"
                    outline
                    placeholder=""
                    @change="setDate(preg.id, $event.target.value, preg.tipo)"
                    readonly
                    :calendar-params="{
                        dateFormat: 'mm/dd/yyyy',
                        value: localentity ? [$dayjs(localentity).toDate()] : [],
                        disabled:
                            preg.lowerLimit && preg.upperLimit
                                ? [
                                      {
                                          to: $dayjs(preg.lowerLimit).subtract(1, 'day').toDate(),
                                      },
                                      {
                                          from: $dayjs(preg.upperLimit).add(1, 'day').toDate(),
                                      },
                                  ]
                                : preg.lowerLimit
                                ? [
                                      {
                                          to: $dayjs(preg.lowerLimit).subtract(1, 'day').toDate(),
                                      },
                                  ]
                                : preg.upperLimit
                                ? [
                                      {
                                          from: $dayjs(preg.upperLimit).add(1, 'day').toDate(),
                                      },
                                  ]
                                : [],
                        locale: 'en',
                        closeOnSelect: true,
                    }"
                ></f7-list-input>
                <f7-list-input
                    v-if="preg.tipo == 'input_dateTime'"
                    type="datepicker"
                    label="Specify date and time"
                    outline
                    placeholder=""
                    @change="setDate(preg.id, $event.target.value, preg.tipo)"
                    readonly
                    :calendar-params="{
                        dateFormat: 'yyyy-mm-dd HH::mm',
                        timePicker: true,
                        value: localentity ? [$dayjs(localentity).toDate()] : [],
                        disabled:
                            preg.lowerLimit && preg.upperLimit
                                ? [
                                      {
                                          to: $dayjs(preg.lowerLimit).subtract(1, 'day').toDate(),
                                      },
                                      {
                                          from: $dayjs(preg.upperLimit).add(1, 'day').toDate(),
                                      },
                                  ]
                                : preg.lowerLimit
                                ? [
                                      {
                                          to: $dayjs(preg.lowerLimit).subtract(1, 'day').toDate(),
                                      },
                                  ]
                                : preg.upperLimit
                                ? [
                                      {
                                          from: $dayjs(preg.upperLimit).add(1, 'day').toDate(),
                                      },
                                  ]
                                : [],
                        locale: 'en',
                    }"
                ></f7-list-input>
                <f7-list-input
                    v-if="preg.tipo == 'input_numero'"
                    label="Value"
                    type="number"
                    outline
                    :value="localentity"
                    :min="preg.lowerLimit != null ? preg.lowerLimit : null"
                    :max="preg.upperLimit != null ? preg.upperLimit : null"
                    @input="setprimitive(preg.id, $event.target.value)"
                    validate
                ></f7-list-input>
                <f7-list-input
                    v-if="preg.tipo == 'input_texto' || preg.tipo == 'input_link'"
                    label="Value"
                    :type="preg.txtarea ? 'textarea' : 'text'"
                    outline
                    :value="localentity"
                    @input="setprimitive(preg.id, $event.target.value)"
                    clear-button
                ></f7-list-input>
            </f7-list>
        </f7-card-content>
    </f7-card>
</template>
<script>
    import { commonmixin } from "../mixins/common";

    export default {
        mixins: [commonmixin],
        props: ["preg", "localentity", "highlightRequired"],
        data() {
            return {
                searchHolders: {},
                lenghavailable: {},
            };
        },
        // watch:{
        //   localentity(nuevoalor,previo){
        //     console.log('se estamodifiando localentity',nuevoalor,previo)
        //   }
        // },
        methods: {
            alternativasActivas(preg) {
                let alternativas = [];
                if (preg.alternativas) {
                    alternativas = Object.values(preg.alternativas).filter((alt) => {
                        return !alt.inct;
                    });
                }
                return alternativas.length;
            },
            seteaEste(query, pregid) {
                // console.log("esta reguntaid", query, pregid);
                let queryalower = query.toLowerCase();
                this.$set(this.searchHolders, pregid, queryalower);
            },
            getArrayOfPregs(alternativas, query, pregid) {
                // console.log("elquery es", query);
                let adeovlver = Object.values(alternativas || {})
                    .filter((unalt) => {
                        return !unalt.inct && (!query || unalt.tx.toLowerCase().includes(query));
                    })
                    .sort((a, b) => {
                        if (a.orden > b.orden) {
                            return 1;
                        } else if (a.orden < b.orden) {
                            return -1;
                        } else {
                            return 0;
                        }
                    });
                this.$set(this.lenghavailable, pregid, adeovlver.length);
                return adeovlver; // this.areOptionsOrderAlphabeticall({ preguntaId: pregid, alternativeItems: adeovlver });
            },
            setprimitive(pregid, value, clear) {
                this.$emit("setprimitive", { pregid, value });
                if (this.$refs[pregid] && clear) {
                    this.$refs[pregid].f7SmartSelect.setValue(null);
                }
            },
            setDate(pregid, value, tipo) {
                if (value) {
                    // console.log("seteando fecha a", value);
                    let fechfix;
                    if (tipo == "input_fecha") {
                        fechfix = this.$dayjs(value, "MM/DD/YYYY").format("YYYYMMDD");
                    } else {
                        fechfix = value;
                    }
                    this.$emit("setDate", { pregid, fechfix });
                }
            },
            selectionaOption(pregid, altid, checked) {
                this.$emit("selectionaOption", { pregid, altid, checked });
            },
            selectionaOptionVirtualList(pregid) {
                let array = this.$refs[pregid].f7SmartSelect.getValue() || [];
                array.forEach((selected) => {
                    this.$emit("selectionaOption", {
                        pregid,
                        altid: selected,
                        checked: true,
                    });
                });
                if (this.localentity) {
                    Object.keys(this.localentity).forEach((alt) => {
                        if (!array.includes(alt)) {
                            this.$emit("selectionaOption", {
                                pregid,
                                altid: alt,
                                checked: false,
                            });
                        }
                    });
                }
            },
        },
    };
</script>
<style>
    .borbotpix {
        border-bottom: 1px dotted gray;
    }
    .scrolmaxalt {
        max-height: 300px;
        overflow-y: auto;
    }
    .highlightRequired {
        border: solid 1px red !important;
    }
    .doneButton .popup-close {
        margin: 10px;
        box-shadow: 2px;
        border-radius: 5%;
        color: white;
        background: #009688;
    }
</style>
