var render = function render(){var _vm=this,_c=_vm._self._c;return _c('f7-page',{attrs:{"name":"cielo-configurations"},on:{"page:afterin":_vm.getAvailableEmailRecipients}},[_c('f7-navbar',[_c('f7-nav-left',[_c('f7-link',{attrs:{"icon-material":"menu","panel-open":"left"}})],1),_vm._v(" "),_c('f7-nav-title',{attrs:{"title":"CIELO configurations"}})],1),_vm._v(" "),_c('f7-block-title',[_vm._v("General")]),_vm._v(" "),_c('f7-list',{attrs:{"media-list":""}},[_c('f7-list-item',{attrs:{"title":"Maintenance mode","footer":"Only super admins will be able to access the app. If you turn this on, regular users will be automatically logged out and will not be able to log in unless you turn it off."}},[_c('f7-toggle',{ref:"isMaintenanceModeEnabled",attrs:{"slot":"after","checked":_vm.cieloConfigurations.isMaintenanceModeEnabled},on:{"toggle:change":_vm.handleMaintenanceModeChanged},slot:"after"})],1)],1),_vm._v(" "),_c('f7-block-title',[_vm._v("Email CIELO users")]),_vm._v(" "),_c('f7-list',[_c('f7-list-input',{attrs:{"label":"Subject","type":"text","placeholder":"Type your subject here","value":_vm.email.subject},on:{"input":function($event){_vm.email.subject = $event.target.value}}}),_vm._v(" "),_c('f7-list-item',{attrs:{"media-item":"","title":"Users recipients","footer":"Click here to select the users to send the email to (leave empty this and projects recipients to send to all users)","disabled":_vm.isLoadingRecipients,"smart-select":"","smart-select-params":{
                pageTitle: 'Select the users recipients',
                virtualList: true,
                searchbar: true,
                searchbarPlaceholder: 'Search user for email or name',
                setValueText: true,
                valueEl: '.after-users-smart',
                formatValueText: _vm.formatAfterTextUsers,
            }}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.email.recipients),expression:"email.recipients"}],attrs:{"multiple":"","name":"recipients"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.email, "recipients", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.availableRecipients),function(user){return _c('option',{key:user.uid,domProps:{"value":user.email}},[_vm._v("\n                    "+_vm._s(user.email)+" "+_vm._s(user.displayName ? `(${user.displayName})` : "")+"\n                ")])}),0),_vm._v(" "),_c('span',{staticClass:"after-users-smart",attrs:{"slot":"after"},slot:"after"})]),_vm._v(" "),_c('f7-list-item',{attrs:{"media-item":"","title":"Projects recipients","footer":"If you select a project as recipient, every member of that project will be added to the recipients list.","smart-select":"","smart-select-params":{
                pageTitle: 'Select the projects (all selected project members will be added as recipients)',
                virtualList: true,
                searchbar: true,
                searchbarPlaceholder: 'Search for project name',
                setValueText: true,
                valueEl: '.after-projects-smart',
                formatValueText: _vm.formatAfterTextProjects,
            }}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.email.projectsRecipients),expression:"email.projectsRecipients"}],attrs:{"multiple":"","name":"projects-recipients"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.email, "projectsRecipients", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.$store.getters.projects),function(project){return _c('option',{key:project.id,domProps:{"value":project.id}},[_vm._v("\n                    "+_vm._s(project.name)+"\n                ")])}),0),_vm._v(" "),_c('span',{staticClass:"after-projects-smart",attrs:{"slot":"after"},slot:"after"})]),_vm._v(" "),_c('f7-list-input',{attrs:{"label":"Body","type":"texteditor","placeholder":"Type your message here","value":_vm.email.body},on:{"texteditor:change":(value) => (_vm.email.body = value)}})],1),_vm._v(" "),_c('f7-button',{staticClass:"margin",attrs:{"fill":""},on:{"click":_vm.sendEmail}},[_vm._v("Send email")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }