<template>
    <f7-page name="perfil" @page:beforein="checkIfUnsubcribed()">
        <!-- Top Navbar -->
        <f7-navbar>
            <f7-nav-left>
                <f7-link icon-material="menu" panel-open="left"></f7-link>
            </f7-nav-left>
            <f7-nav-title subtitle="Manage your preferences" title="My Profile"></f7-nav-title>
        </f7-navbar>
        <f7-list media-list>
            <f7-list-item divider>
                {{ !user.displayName ? "Welcome to CIELO." : "" }} Here you can setup your display name, your avatar and
                phone number
            </f7-list-item>
            <f7-list-item
                header="Display name"
                link="#"
                @click="setMyDisplName()"
                :title="user.displayName || 'Name not specified'"
            >
                <font-awesome-icon slot="after" class="fa-lg" :icon="['fad', 'pen']"></font-awesome-icon>
            </f7-list-item>
            <f7-list-item
                @click="tomarFoto()"
                link="#"
                header="Profile avatar"
                title="select image or take new picture"
            >
                <img :src="user.photoURL || '../static/heros/avatar.jpg'" class="sudoavtrx" slot="media" />
                <font-awesome-icon slot="after" class="fa-lg" :icon="['fad', 'camera']"></font-awesome-icon>
            </f7-list-item>
            <f7-list-item
                header="Phone number"
                link="#"
                @click="openphonePopup"
                :title="user.phone || 'phone number not specified'"
            >
                <font-awesome-icon slot="after" class="fa-lg" :icon="['fad', 'pen']"></font-awesome-icon>
            </f7-list-item>
            <input type="file" id="myimg-input" @change="filesChange($event)" accept="image/*" />
        </f7-list>
        <f7-block class="text-align-center" v-if="loadingSuppressionList"><f7-preloader></f7-preloader></f7-block>
        <f7-block v-if="isInSendgridSupresionList">
            <f7c-alert type="danger">
                <span>Your email is in Sendgrid suppression list</span>
            </f7c-alert>
            <f7-button @click="deleteFromSendgridSuppressionList()" outline>Subscribe again</f7-button>
        </f7-block>
        <f7-popup :opened="phonePopup" @popup:close="(password = null), (phonePopup = false)">
            <f7-navbar
                title="New phone number"
                :subtitle="showPasswordInput ? 'Enter your account password to confirm the change' : ''"
            >
                <f7-nav-right><f7-link popup-close>Close</f7-link></f7-nav-right>
            </f7-navbar>
            <f7-block v-if="preloader" color="black" class="text-align-center">
                <f7-preloader></f7-preloader>
                <br />
                Loading ...
            </f7-block>
            <template v-else>
                <f7-list class="no-margin-top">
                    <f7-list-input
                        label="New phone number"
                        autocomplete="chrome-off off"
                        maxlength="16"
                        info="e.g. +1(555)555-5555"
                        outline
                        :value="formatPhoneNumber(phoneNumber)"
                        @input="phoneNumber = $event.target.value.trim()"
                    />
                    <f7-list-input
                        v-if="showPasswordInput"
                        outline
                        ref="password"
                        label="Your account password"
                        type="password"
                        autocomplete="new-password"
                        :value="password"
                        @input="password = $event.target.value"
                    />
                </f7-list>
                <f7-block><f7-button fill large @click="confirmPassword()">Save</f7-button></f7-block>
            </template>
        </f7-popup>
        <f7-popup :opened="enterCode" @popup:closed="(code1 = null), (code2 = null), (enterCode = null)">
            <f7-page>
                <f7-navbar title="Verifying user">
                    <f7-nav-right><f7-link popup-close>Close</f7-link></f7-nav-right>
                </f7-navbar>
                <f7-block v-if="preloader" color="black" class="text-align-center">
                    <f7-preloader></f7-preloader>
                    <br />
                    Loading ...
                </f7-block>
                <template v-else>
                    <f7-block-title class="whiteBg no-margin padding">
                        To confirm your phone number Enter the code we sent you by SMS
                    </f7-block-title>
                    <f7-block class="text-align-center whiteBg">
                        <f7-list class="no-margin-top no-margin-bottom inlineInputs">
                            <f7-list-input
                                id="firstInput"
                                outline
                                maxlength="3"
                                :value="code1"
                                @input="code1 = $event.target.value"
                            ></f7-list-input>
                        </f7-list>
                        <span>-</span>
                        <f7-list class="no-margin-top no-margin-bottom inlineInputs">
                            <f7-list-input
                                id="secondInput"
                                outline
                                maxlength="3"
                                :value="code2"
                                @input="code2 = $event.target.value"
                            ></f7-list-input>
                        </f7-list>
                    </f7-block>
                    <f7-block>
                        <div class="text-align-right">
                            <span>Did not receive the SMS?</span>
                            <f7-link @click="confirmPassword()">Send it again</f7-link>
                        </div>
                    </f7-block>
                    <f7-block>
                        <f7-button @click="confirmCode()" fill large>Confirm Code</f7-button>
                    </f7-block>
                </template>
            </f7-page>
        </f7-popup>
    </f7-page>
</template>
<script>
    import { mapGetters, mapState } from "vuex";
    import { photomixin } from "../mixins/photomixin";
    import { userHasPrivileges } from "../../functions/services/security";

    export default {
        mixins: [photomixin],
        data() {
            return {
                phonePopup: false,
                password: "",
                enterCode: null,
                code1: null,
                code2: null,
                preloader: null,
                phoneNumber: "+1",
                showPasswordInput: false,
                loadingSuppressionList: false,
                isInSendgridSupresionList: false,
            };
        },
        computed: {
            ...mapState(["user", "dev"]),
            ...mapGetters(["privxInfo"]),
        },

        watch: {
            code1: {
                handler: function (val) {
                    if (val && val.length === 3) {
                        const input = this.$$("#secondInput").find("input")[0];
                        if (input) input.focus();
                    }
                },
            },

            preloader: {
                handler: function (val) {
                    if (!val && this.enterCode === true) {
                        setTimeout(() => {
                            const input = this.$$("#firstInput").find("input")[0];
                            if (input) input.focus();
                        }, 100);
                    }
                },
            },
        },

        methods: {
            async checkIfUnsubcribed() {
                try {
                    this.loadingSuppressionList = true;
                    let user = this.$firebase.auth().currentUser;
                    const email = user.email;
                    const call = this.$firebase.functions().httpsCallable("v2_isInSendgridSupresionList");
                    const response = await call({
                        userCalling: true,
                        email,
                        dev: this.$store.state.dev,
                    });
                    console.log(response);
                    if (response.error) {
                        throw new Error(response.error);
                    }
                    if (response.data.isInSendgridSupresionList) {
                        this.isInSendgridSupresionList = true;
                    }
                    this.loadingSuppressionList = false;
                } catch (error) {
                    this.loadingSuppressionList = false;
                    console.log(error);
                    this.$f7.dialog.alert(error.message);
                }
            },
            async deleteFromSendgridSuppressionList() {
                try {
                    this.$f7.dialog.preloader("Deleting from suppression list...");
                    let user = this.$firebase.auth().currentUser;
                    const email = user.email;
                    const call = this.$firebase.functions().httpsCallable("v2_sendgridDeleteGlobalSuppression");
                    const response = await call({
                        userCalling: true,
                        email,
                        dev: this.$store.state.dev,
                    });
                    console.log(response);
                    if (response.error) {
                        throw new Error(response.error);
                    }
                    this.isInSendgridSupresionList = false;
                    this.$f7.dialog.close();
                } catch (error) {
                    console.log(error);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error.message);
                }
            },
            async setMyDisplName() {
                return this.$f7.dialog.prompt(
                    "Indicate your prefered display name for CIELO",
                    "Set up your name",
                    async (valor) => {
                        try {
                            if (!valor.trim()) {
                                throw new Error("You must provide a name");
                            }
                            this.$f7.dialog.preloader("Saving your name...");
                            await this.$firebase.auth().currentUser.updateProfile({
                                displayName: valor.trim(),
                            });
                            this.$f7.dialog.close();
                            this.$store.commit("updateUserInfo", {
                                cual: "displayName",
                                valor: valor.trim(),
                            });
                        } catch (error) {
                            this.$f7.dialog.close();
                            return this.$f7.dialog.alert(error.message, error.code || "Error");
                        }
                    },
                    () => {},
                    this.user.displayName,
                );
            },
            async procesaFoto(filename) {
                try {
                    let datous = await this.commonExecute({ filename }, "v2_procesaMyAvatar", false);
                    if (datous.error) {
                        throw new Error(datous.error.message);
                    }
                    // console.log('La URL chica es',datous.payload);
                    let neourl = `${datous.payload}&tm=${Date.now()}`;
                    this.$store.commit("updateUserInfo", {
                        cual: "photoURL",
                        valor: neourl,
                    });
                    this.notifLoading.close();
                } catch (error) {
                    console.error(error);
                    this.$f7.dialog.alert(error.message, error.code);
                }
            },
            async openphonePopup() {
                let lastSignInTime = this.$firebase.auth().currentUser.metadata.lastSignInTime;
                let lastSignInTimeUnix = this.$dayjs(lastSignInTime).unix();
                if (this.$dayjs().unix() - lastSignInTimeUnix > 120) {
                    this.showPasswordInput = true;
                }

                this.phonePopup = true;
                if (this.user.phone) this.phoneNumber = this.user.phone;
                this.password = "";
            },
            async confirmPassword() {
                try {
                    this.phoneNumber = this.phoneNumber.replaceAll("(", "");
                    this.phoneNumber = this.phoneNumber.replaceAll(")", "");
                    this.phoneNumber = this.phoneNumber.replaceAll("-", "");
                    this.phoneNumber = this.phoneNumber.replaceAll(" ", "");
                    if (this.phoneNumber.charAt(0) != "+") {
                        let plus = "+";
                        this.phoneNumber = plus.concat(this.phoneNumber);
                    }
                    let valid = /^[0-9\-\+]{12,15}$/.test(this.phoneNumber);
                    if (!this.phoneNumber || !valid) throw new Error("You must provide a valid phone number");
                    this.preloader = true;
                    if (this.showPasswordInput) {
                        let user = this.$firebase.auth().currentUser;
                        let credential = this.$firebase.auth.EmailAuthProvider.credential(user.email, this.password);
                        let correct = await user.reauthenticateWithCredential(credential);
                        // if(!correct)throw new Error('Incorrect password')
                    }
                    let call = this.$firebase.functions().httpsCallable("v2_sendcodeSMS");
                    let response = await call({
                        phoneNumber: this.phoneNumber,
                        dev: this.dev,
                    });
                    if (response.data.error) throw new Error(response.data.error);

                    this.enterCode = true;
                    this.phonePopup = false;
                    this.preloader = false;
                } catch (error) {
                    console.log(error);
                    this.preloader = false;
                    this.$f7.dialog.alert(error);
                }
            },
            async confirmCode() {
                try {
                    if (this.code1.length < 3 || this.code2.length < 3)
                        return this.$f7.dialog.alert("A 6 digits code is needed");
                    let code = this.code1.concat(this.code2);
                    this.preloader = true;
                    let call = this.$firebase.functions().httpsCallable("v2_checkCode");
                    let response = await call({
                        code,
                        phoneNumber: this.phoneNumber,
                        changePhoneNumber: true,
                        dev: this.dev,
                    });
                    if (response.error) throw new Error(error.message);
                    if (response.data.correct) {
                        this.$store.commit("updateUserInfo", {
                            cual: "phone",
                            valor: this.phoneNumber,
                        });
                        this.enterCode = false;
                        this.preloader = false;
                    } else {
                        this.preloader = false;
                        return this.$f7.dialog.alert("Incorrect Code");
                    }
                } catch (error) {
                    this.preloader = false;
                    this.$f7.dialog.alert(error.message);
                    console.log(error);
                }
            },
            formatPhoneNumber(phoneNumberString) {
                if (phoneNumberString) {
                    let cleaned = ("" + phoneNumberString).replace(/\D/g, "");
                    let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
                    if (match) {
                        let intlCode = match[1] ? "+1 " : "";
                        return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
                    }
                    return phoneNumberString;
                }
            },
        },
    };
</script>
<style>
    .sudoavtrx {
        width: 40px;
    }

    #myimg-input {
        display: none;
    }
</style>
