<template>
    <f7-page class="itemafterwithmax">
        <f7-navbar>
            <f7-nav-left back-link="Back"></f7-nav-left>
            <f7-nav-title title="New Bulk Upload"></f7-nav-title>
        </f7-navbar>

        <f7-list media-list class="no-margin-vertical">
            <f7-list-item header="1.- Source file (*.CSV)" :title="fileBulkName || 'No File Selected'">
                <f7-button v-if="!fileBulkName" fill raised @click="tomarArhivo()" slot="after">Select file</f7-button>
            </f7-list-item>
            <f7-list-item title="Processing file..." v-if="processingFileBul">
                <f7-preloader slot="after"></f7-preloader>
            </f7-list-item>
            <template v-if="fileBulkName">
                <f7-list-item divider title="2.- Type of bulk upload"></f7-list-item>
                <f7-list-item
                    :class="{ disabled: !InstitutionsICanCreateFrom.length }"
                    radio
                    value="makeEntities"
                    @change="setTypeOfUpload($event.target.value)"
                    name="typeOfUpload-radio"
                    :checked="typeOfUpload === 'makeEntities'"
                    title="Create new entities"
                    text="indicating which column corresponds to what entity data"
                    :footer="!InstitutionsICanCreateFrom.length ? ' - No privileges for creating patients -' : null"
                ></f7-list-item>
                <f7-list-item
                    :class="{ disabled: !InstitutionsICanEditFrom.length }"
                    radio
                    value="updateEntities"
                    @change="setTypeOfUpload($event.target.value)"
                    name="typeOfUpload-radio"
                    :checked="typeOfUpload === 'updateEntities'"
                    title="Update existing entities"
                    text="indicating which column corresponds to what entity data"
                    :footer="!InstitutionsICanEditFrom.length ? ' - No privileges for updating patients -' : null"
                ></f7-list-item>
                <f7-list-item
                    :class="{ disabled: !InstitutionsICanEditFrom.length }"
                    radio
                    value="createFiles"
                    @change="setTypeOfUpload($event.target.value)"
                    name="typeOfUpload-radio"
                    :checked="typeOfUpload === 'createFiles'"
                    title="Add files to existing entities"
                    text="indicating the column corresponding to the entity id."
                    :footer="!InstitutionsICanEditFrom.length ? ' - No privileges for updating entities - ' : null"
                ></f7-list-item>
            </template>
        </f7-list>
        <template>
            <f7-list v-if="typeOfUpload == 'makeEntities' && fileBulkName" media-list class="no-margin">
                <f7-list-input
                    v-if="InstitutionsICanCreateFrom.length > 1"
                    :label="`3.- Select the ${
                        projectInFocusInfo.grouper || 'Institution'
                    } where to create the entities`"
                    type="select"
                    placeholder="Please choose..."
                    outline
                    :value="institIDToCreateAt"
                    @input="institIDToCreateAt = $event.target.value"
                >
                    <option value="">
                        Select the
                        {{ projectInFocusInfo.grouper || "Institution" }}
                    </option>

                    <option v-for="institux in InstitutionsICanCreateFrom" :key="institux.id" :value="institux.id">
                        {{ institux.name }}
                    </option>
                </f7-list-input>
            </f7-list>
            <template v-if="(typeOfUpload === 'makeEntities' || typeOfUpload === 'updateEntities') && fileBulkName">
                <f7-list media-list class="no-margin">
                    <f7-list-item divider title="4.- Map entity data to CSV columns"></f7-list-item>
                    <f7-list-item
                        class="margin-bottom"
                        v-if="fileBulkName && !processingFileBul"
                        checkbox
                        title="My file has a header row"
                        name="header-checkbox"
                        :checked="tieneHeaders"
                        @change="tieneHeaders = $event.target.checked"
                    ></f7-list-item>
                    <f7-list-item
                        class="margin-bottom"
                        v-if="typeOfUpload === 'makeEntities' && projectInFocusInfo.autoGeneratedId"
                        checkbox
                        title="Auto-generate IDs"
                        name="header-checkbox"
                        :checked="autoGeneratedId"
                        @change="changeAutoGeneratedId($event.target.checked)"
                    ></f7-list-item>
                    <f7-list-item v-if="!processingFileBul" class="anciliaryx" title="Change file delimiter">
                        <f7-input
                            slot="after"
                            outline
                            type="select"
                            :value="delimiter"
                            @input="(delimiter = $event.target.value), parseaBulkFile(false, true)"
                        >
                            <option value=",">,</option>
                            <option value=";">;</option>
                        </f7-input>
                    </f7-list-item>

                    <f7-list-item
                        :disabled="autoGeneratedId && typeOfUpload == 'makeEntities'"
                        title="Entity ID(*)"
                        :class="{ pendingOptionSetWarning: !entityIdColumn }"
                        :footer="
                            entityIdColumn != ''
                                ? `Sample data parsed: ${
                                      tieneHeaders ? secondRow[entityIdColumn] : firstRowis[entityIdColumn]
                                  }`
                                : null
                        "
                    >
                        <f7-input
                            slot="after"
                            label="Data column"
                            type="select"
                            outline
                            placeholder="Please choose..."
                            :value="entityIdColumn"
                            @input="setEntityIdColumn($event.target.value)"
                        >
                            <option value="">Select column index or header</option>
                            <option v-for="(unacol, index) in firstRowis" :key="index" :value="index">
                                {{ tieneHeaders ? unacol : index }}
                            </option>
                        </f7-input>
                    </f7-list-item>
                    <template v-if="typeOfUpload == 'makeEntities' && entityIdColumn">
                        <f7-list-item divider title="Skip entities"></f7-list-item>
                        <f7-list-item
                            checkbox
                            no-hairlines
                            title="Skip entities that already exist"
                            :checked="skippedExistingEntities"
                            @change="getSkippedEntities($event.target.checked)"
                        ></f7-list-item>
                    </template>
                    <f7-list-item
                        v-if="automapped"
                        divider
                        class="leitalictitle"
                        title="Some columns were automatically mapped. Please verify."
                    ></f7-list-item>
                </f7-list>

                <template v-if="entityIdColumn">
                    <f7-card v-for="(onedata, idx) in mapperDatas" :key="onedata.id" :padding="false">
                        <f7-card-header class="albolder">
                            {{ `${onedata.encabezado}${onedata.req && typeOfUpload != "updateEntities" ? "(*)" : ""}` }}
                        </f7-card-header>
                        <f7-card-content :padding="false">
                            <f7-list media-list accordion-list class="no-margin">
                                <f7-list-item
                                    class="anciliaryx"
                                    title="Date format to parse"
                                    v-if="onedata.tipo === 'input_fecha'"
                                >
                                    <f7-input
                                        slot="after"
                                        label="Date Format"
                                        outline
                                        type="select"
                                        :value="onedata.dateformat"
                                        @input="
                                            setNewMappedCol({
                                                idx,
                                                cual: 'dateformat',
                                                neoval: $event.target.value,
                                                col: onedata.mapcol,
                                            })
                                        "
                                    >
                                        <option value="MM/DD/YYYY">MM/DD/YYYY</option>
                                        <option value="MM-DD-YYYY">MM-DD-YYYY</option>
                                        <option value="DD/MM/YYYY">DD/MM/YYYY</option>
                                        <option value="DD-MM-YYYY">DD-MM-YYYY</option>
                                        <option value="D/M/YYYY">D/M/YYYY</option>
                                        <option value="D/MM/YYYY">D/MM/YYYY</option>
                                        <option value="D-M-YYYY">D-M-YYYY</option>
                                        <option value="M/D/YYYY">M/D/YYYY</option>
                                        <option value="M-D-YYYY">M-D-YYYY</option>
                                        <option value="YYYY-MM-DD">YYYY-MM-DD</option>
                                    </f7-input>
                                </f7-list-item>
                                <f7-list-item
                                    class="anciliaryx"
                                    title="Multiple values separator"
                                    v-else-if="onedata.tipo === 'elige_many'"
                                >
                                    <f7-input
                                        slot="after"
                                        label="Multiple values separator"
                                        type="select"
                                        outline
                                        :value="onedata.separator"
                                        @input="
                                            setNewMappedCol({
                                                idx,
                                                cual: 'separator',
                                                neoval: $event.target.value,
                                                col: onedata.mapcol,
                                            })
                                        "
                                    >
                                        <option value="|">|</option>
                                        <option value=",">,</option>
                                        <option value="$">$</option>
                                        <option value=";">;</option>
                                        <option value="#">#</option>
                                    </f7-input>
                                </f7-list-item>
                                <f7-list-item
                                    class="inutreal"
                                    :class="{
                                        pendingOptionSetWarning:
                                            typeOfUpload != 'updateEntities' && onedata.mapcol === '',
                                    }"
                                    title="Data column"
                                    :footer="
                                        onedata.mapcol != 'skip' && onedata.mapcol !== ''
                                            ? `Sample data parsed:${
                                                  tieneHeaders ? secondRow[onedata.mapcol] : firstRowis[onedata.mapcol]
                                              }`
                                            : null
                                    "
                                >
                                    <f7-input
                                        slot="after"
                                        label="Data column"
                                        type="select"
                                        outline
                                        placeholder="Please choose..."
                                        :value="!onedata.mapcol && onedata.mapcol !== 0 ? 'skip' : onedata.mapcol"
                                        @input="
                                            setNewMappedCol({
                                                idx,
                                                cual: 'mapcol',
                                                neoval: $event.target.value,
                                            })
                                        "
                                    >
                                        <!-- <option value="">Select column index or header</option> -->
                                        <option
                                            v-for="(unacol, index) in firstRowis"
                                            :key="index"
                                            :value="index"
                                            v-show="index != entityIdColumn"
                                        >
                                            {{ tieneHeaders ? unacol : index }}
                                        </option>
                                        <option v-if="!onedata.req || typeOfUpload == 'updateEntities'" value="skip">
                                            - SKIP, NO DATA IN THIS FILE -
                                        </option>
                                    </f7-input>
                                </f7-list-item>
                                <f7-list-item
                                    accordion-item
                                    :class="{
                                        warnmaps:
                                            !todosMapeosOk[onedata.id] || !todosMapeosOk[onedata.id][onedata.mapcol],
                                    }"
                                    class="anciliaryx"
                                    :title="
                                        !todosMapeosOk[onedata.id] || !todosMapeosOk[onedata.id][onedata.mapcol]
                                            ? 'Please map all alternatives'
                                            : 'All alternatives mapped OK'
                                    "
                                    v-if="
                                        onedata.mapcol &&
                                        pregsMultiChoice.includes(onedata.tipo) &&
                                        onedata.mapcol != 'skip'
                                    "
                                >
                                    <font-awesome-icon
                                        v-if="!todosMapeosOk[onedata.id] || !todosMapeosOk[onedata.id][onedata.mapcol]"
                                        slot="media"
                                        class="fa-lg text-color-red"
                                        :icon="['fad', 'triangle-exclamation']"
                                    ></font-awesome-icon>
                                    <font-awesome-icon
                                        v-else
                                        slot="media"
                                        class="fa-lg text-color-primary"
                                        :icon="['fad', 'circle-check']"
                                    ></font-awesome-icon>
                                    <f7-accordion-content>
                                        <f7-list class="bg-color-white" inset media-list>
                                            <f7-list-item class="leheaderxmappers" title="Distinct values in file">
                                                <f7-button slot="after" @click="createNewOptionsForAll(idx, onedata)">
                                                    Create options from all new columns
                                                </f7-button>
                                            </f7-list-item>
                                            <f7-list-item
                                                v-for="ununique in getUniques(onedata.mapcol)"
                                                :key="ununique.tx"
                                                :title="ununique.tx"
                                                :class="{
                                                    wanmapper:
                                                        !optionsMapper[onedata.id] ||
                                                        !optionsMapper[onedata.id][onedata.mapcol] ||
                                                        !optionsMapper[onedata.id][onedata.mapcol][ununique.tx] ||
                                                        optionsMapper[onedata.id][onedata.mapcol][ununique.tx] === '',
                                                }"
                                            >
                                                <f7-input
                                                    slot="after"
                                                    outline
                                                    label="map alternatives"
                                                    type="select"
                                                    placeholder="Please choose..."
                                                    @input="
                                                        setAlterMapVal({
                                                            preguntaIdx: idx,
                                                            normKey: ununique.tx,
                                                            alternativeId: $event.target.value,
                                                        })
                                                    "
                                                    :value="
                                                        optionsMapper[onedata.id] &&
                                                        optionsMapper[onedata.id][onedata.mapcol]
                                                            ? optionsMapper[onedata.id][onedata.mapcol][ununique.tx] ||
                                                              ''
                                                            : ''
                                                    "
                                                >
                                                    <option value="">Select potential map</option>
                                                    <option
                                                        v-for="inneralt in onedata.alternamaps"
                                                        :key="inneralt.id"
                                                        :value="inneralt.id"
                                                    >
                                                        {{ inneralt.tx }}
                                                    </option>
                                                    <option value="creaNeo">Create New Option</option>
                                                    <option value="noImport">Do not import</option>
                                                </f7-input>
                                            </f7-list-item>
                                        </f7-list>
                                    </f7-accordion-content>
                                </f7-list-item>
                            </f7-list>
                        </f7-card-content>
                    </f7-card>
                </template>
            </template>
        </template>

        <template v-if="fileBulkName && typeOfUpload === 'createFiles'">
            <f7-list media-list class="no-margin">
                <f7-list-item
                    v-if="!processingFileBul"
                    checkbox
                    title="My file has header row(s)"
                    name="header-checkbox"
                    :checked="tieneHeaders"
                    @change="tieneHeaders = $event.target.checked"
                ></f7-list-item>
                <template v-if="tieneHeaders">
                    <f7-list-input
                        label="Header row(s)"
                        type="select"
                        placeholder="Please choose..."
                        outline
                        :value="headersLastRow"
                        @input="setHeadersLastRow($event.target.value)"
                    >
                        <option v-for="row in posibleHeaderRows" :key="row" :value="row - 1">
                            {{ row == 1 ? "Row 1" : `Row 1 to row ${row}` }}
                        </option>
                    </f7-list-input>
                    <f7-list-input
                        v-if="headersLastRow > 0"
                        label="Select the row where the Entity ID is"
                        type="select"
                        placeholder="Please choose..."
                        outline
                        :value="entityIdRow"
                        @input="setEntityIdRow($event.target.value)"
                    >
                        <option v-for="row in headersLastRow + 1" :key="row" :value="row - 1">
                            {{ `Row ${row}` }}
                        </option>
                    </f7-list-input>
                </template>
                <f7-list-item v-if="!processingFileBul" class="anciliaryx" title="Change file delimiter">
                    <f7-input
                        slot="after"
                        outline
                        type="select"
                        :value="delimiter"
                        @input="(delimiter = $event.target.value), parseaBulkFile(false, true)"
                    >
                        <option value=",">,</option>
                        <option value=";">;</option>
                    </f7-input>
                </f7-list-item>

                <f7-list-input
                    v-if="!processingFileBul"
                    label="3.- Entity id column"
                    type="select"
                    placeholder="Please choose..."
                    outline
                    :value="entityIdColumn"
                    @input="setEntityIdColumn($event.target.value)"
                    :info="
                        entityIdColumn != ''
                            ? `Sample data parsed: ${
                                  tieneHeaders ? secondRow[entityIdColumn] : firstRowis[entityIdColumn]
                              }`
                            : null
                    "
                >
                    <option value="">Select column index</option>
                    <option v-for="(unacol, index) in firstRowis" :key="index" :value="index">
                        {{ tieneHeaders ? unacol : index + 1 }}
                    </option>
                </f7-list-input>
                <template v-if="entityIdColumn != ''">
                    <f7-list-item divider title="4.- Target"></f7-list-item>
                    <f7-list-input
                        v-if="typeOfUpload === 'createFiles'"
                        label="Target file to generate"
                        type="select"
                        placeholder="Please choose..."
                        :value="BUTargetFile"
                        outline
                        @input="BUTargetFile = $event.target.value"
                        :disabled="shouldCreateNewFileField || !enabledCSVFileFields.length"
                    >
                        <option value="">Select target file</option>
                        <option v-for="unfile in enabledCSVFileFields" :key="unfile.id" :value="unfile.id">
                            {{ unfile.encabezado }}
                        </option>
                    </f7-list-input>
                    <f7-list-item
                        v-if="typeOfUpload === 'createFiles'"
                        checkbox
                        no-hairlines
                        title="(Optional) Create a new file field for this project"
                        :checked="shouldCreateNewFileField"
                        @change="
                            (shouldCreateNewFileField = $event.target.checked),
                                skippedEntities ? getSkippedEntities(false) : ''
                        "
                    ></f7-list-item>
                    <template v-if="typeOfUpload === 'createFiles' && shouldCreateNewFileField">
                        <f7-list-input
                            label="New file field header (required)"
                            type="text"
                            placeholder="Please enter a header"
                            outline
                            :value="newFileField.encabezado"
                            @input="newFileField.encabezado = $event.target.value"
                        ></f7-list-input>
                        <f7-list-item
                            checkbox
                            title="Multiple files (leave empty for single file field)"
                            :checked="newFileField.multiple"
                            @change="newFileField.multiple = $event.target.checked"
                        ></f7-list-item>
                        <f7-list-input
                            type="select"
                            label="Supported type of file"
                            placeholder="Please choose..."
                            outline
                            :value="newFileField.tipo"
                            @input="newFileField.tipo = $event.target.value"
                        >
                            <option v-for="file in availableFileTypes" :key="file.id" :value="file.id">
                                {{ file.id === "a" ? "Any" : file.id }}
                            </option>
                        </f7-list-input>
                    </template>
                    <f7-list-item
                        v-if="typeOfUpload === 'createFiles' && !shouldCreateNewFileField"
                        checkbox
                        :class="{ disabled: !BUTargetFile }"
                        no-hairlines
                        title="Skip entities that already have a file in the target"
                        :checked="skippedEntities"
                        @change="getSkippedEntities($event.target.checked)"
                    ></f7-list-item>
                </template>
            </f7-list>
            <template v-if="entityIdColumn != ''">
                <f7-list class="no-margin">
                    <f7-list-item divider title="5.- File tags"></f7-list-item>
                    <f7-list-item
                        :disabled="!tags.length"
                        :after="!tags.length ? 'No tags created or active' : ''"
                        smart-select
                        ref="tagsSelection"
                        :smart-select-params="{
                            openIn: 'popup',
                            virtualList: true,
                            searchbar: true,
                            popupCloseLinkText: 'Done',
                            cssClass: 'doneButton',
                        }"
                        title="Select tags"
                    >
                        <select multiple @change="selectTag()">
                            <option v-for="tag in tags" :selected="selectedTags[tag.id]" :key="tag.id" :value="tag.id">
                                {{ tag.tx }}
                            </option>
                        </select>
                    </f7-list-item>
                </f7-list>
                <f7-list class="no-margin">
                    <f7-list-item divider title="6.- Column to upload"></f7-list-item>
                    <f7-list-input
                        v-if="typeOfUpload === 'createFiles'"
                        label="Last column to upload, ignore the next ones"
                        type="select"
                        placeholder="Please choose..."
                        :value="lastColumn"
                        outline
                        @input="setLastColumn($event.target.value)"
                    >
                        <option :value="null">Upload all columns</option>
                        <option v-for="(unacol, index) in firstRowisNotFiltered" :key="index" :value="index">
                            Upload up to column
                            {{
                                tieneHeaders
                                    ? `${Number(index) + 1} ${unacol.length ? "(" + unacol + ")" : ""}`
                                    : Number(index) + 1
                            }}
                        </option>
                    </f7-list-input>
                </f7-list>
            </template>
        </template>

        <f7-block v-if="(typeOfUpload === 'makeEntities' || typeOfUpload === 'updateEntities') && entityIdColumn != ''">
            <p v-if="typeOfUpload === 'makeEntities' && !institIDToCreateAt">
                To upload and process file you must indicate an Institution where to create the entities
            </p>
            <template v-else>
                <p v-if="typeOfUpload === 'makeEntities'">
                    All required data fields(*) must have a map to a column in the CSV file.Optional data must select a
                    column or skip.
                </p>
                <f7-button @click="processFilePls()" fill large raised>Upload and process file</f7-button>
            </template>
        </f7-block>
        <f7-block v-else-if="typeOfUpload === 'createFiles' && entityIdColumn != ''">
            <p>
                A new CSV file will be generated for each entity found in the imported file that exists in the project,
                containing only its own value.
            </p>
            <f7-button @click="processFilePls()" fill large raised>Upload and process file</f7-button>
        </f7-block>
        <input type="file" id="bulkfile-input" @change="parseaBulkFile($event)" accept=".csv" />

        <f7-link v-if="$f7.device.macos && !fileBulkName" popover-open=".popover-issues-macbook">
            Having trouble uploading files?
        </f7-link>

        <f7-popover class="popover-issues-macbook">
            <f7-block>
                For Macbook M1 users using Google Chrome, if file upload is not working try the following:
                <ol>
                    <li>Go to System preferences > Security and privacy > privacy tab</li>
                    <li>Click on the lock to make changes</li>
                    <li>Search for Full disk access</li>
                    <li>Click on the + button</li>
                    <li>Search for Google Chrome and click open</li>
                    <li>Restart Google Chrome</li>
                </ol>
            </f7-block>
        </f7-popover>

        <f7-popover class="popover-errors-entityid">
            <f7-list media-list v-if="arrayOfErrors.length > 20">
                <f7-list-item
                    v-for="unerror in onlyFirst20Errors"
                    :key="unerror.id"
                    popover-close
                    :title="`Row ${unerror.indiceFila}`"
                    :text="Object.values(unerror.errores).join(',')"
                    :after="`ID: ${allDataArray[unerror.indiceFila][entityIdColumn] || '-'}`"
                ></f7-list-item>
                <f7-list-item
                    v-if="arrayOfErrors.length > 21"
                    :title="`There are ${arrayOfErrors.length - 20} more errors`"
                ></f7-list-item>
            </f7-list>
            <f7-list v-else media-list>
                <f7-list-item
                    v-for="unerror in arrayOfErrors"
                    :key="unerror.id"
                    popover-close
                    :title="`Row ${unerror.indiceFila}`"
                    :text="Object.values(unerror.errores).join(',')"
                    :after="`ID: ${allDataArray[unerror.indiceFila][entityIdColumn] || '-'}`"
                ></f7-list-item>
            </f7-list>
        </f7-popover>

        <f7-toolbar position="bottom">
            <f7-list media-list class="no-margin">
                <f7-list-item
                    link="#"
                    popover-open=".popover-errors-entityid"
                    class="text-color-red"
                    v-if="erroresTotaltes"
                    :title="`${erroresTotaltes} ${erroresTotaltes != 1 ? 'rows' : 'row'} will be skipped`"
                ></f7-list-item>
                <f7-list-item v-else title="With current config no errors detected"></f7-list-item>
            </f7-list>

            <f7-list media-list class="no-margin">
                <f7-list-item
                    :title="`${aImportar} ${aImportar != 1 ? 'rows' : 'row'} will be processed`"
                ></f7-list-item>
            </f7-list>
        </f7-toolbar>
    </f7-page>
</template>

<script>
    import stringSimilarity from "string-similarity";
    import { mapGetters, mapState } from "vuex";
    export default {
        data() {
            return {
                shouldCreateNewFileField: false,
                newFileField: {
                    encabezado: "",
                    tipo: "a",
                    multiple: false,
                },
                institIDToCreateAt: "",
                mapperDatas: [],
                typeOfUpload: "",
                BUTargetFile: "",
                fileBulkName: "",
                processingFileBul: false,
                tieneHeaders: true,
                entityIdColumn: "",
                entityIdRow: "",
                startingDataRow: 2,
                posibleHeaderRows: null,
                headersLastRow: 0,
                firstRowis: [],
                secondRow: [],
                allDataArray: [],
                toProcessStats: {},
                aImportar: 0,
                erroresTotaltes: 0,
                arrayOfErrors: [],
                uniqueCols: {},
                optionsMapper: {},
                todosMapeosOk: {},
                pregsMultiChoice: ["elige_many", "elige_una"],
                automapped: false,
                size: null,
                delimiter: ",",
                lastColumn: null,
                allDataArrayNotFiltered: [],
                firstRowisNotFiltered: null,
                preloaderOn: false,
                selectedTags: {},
                skippedEntities: null,
                skippedExistingEntities: null,
                autoGeneratedId: false,
            };
        },
        computed: {
            ...mapState(["user", "forms_logic", "user", "projectInFocus"]),
            ...mapGetters(["projectInFocusInfo"]),
            myPrivsHere() {
                let allprivs = this.user.privsByProject || {};
                return allprivs[this.$store.state.projectInFocus] || {};
            },
            enabledFiles() {
                return this.projectInFocusInfo.entityFiles ? this.allEnabler(this.projectInFocusInfo.entityFiles) : [];
            },
            enabledData() {
                return this.projectInFocusInfo.entityData ? this.allEnabler(this.projectInFocusInfo.entityData) : [];
            },
            InstitutionsICanCreateFrom() {
                if (this.myPrivsHere.admin && this.myPrivsHere.admin.full) {
                    return Object.values(this.projectInFocusInfo.institutions).filter((uninst) => {
                        return !uninst.inactive;
                    });
                } else if (this.myPrivsHere.prv) {
                    let cuales = Object.values(this.myPrivsHere.prv).filter((potinsti) => {
                        return (
                            potinsti.det &&
                            (potinsti.det.create || potinsti.det.update) &&
                            !this.projectInFocusInfo.institutions[potinsti.id].inactive
                        );
                    });
                    return cuales;
                } else {
                    return [];
                }
            },
            InstitutionsICanEditFrom() {
                if (this.myPrivsHere.admin && this.myPrivsHere.admin.full) {
                    return Object.values(this.projectInFocusInfo.institutions).filter((uninst) => {
                        return !uninst.inactive;
                    });
                } else if (this.myPrivsHere.prv) {
                    let cuales = Object.values(this.myPrivsHere.prv).filter((potinsti) => {
                        return (
                            potinsti.det &&
                            (potinsti.det.update || potinsti.det.create) &&
                            !this.projectInFocusInfo.institutions[potinsti.id].inactive
                        );
                    });
                    return cuales;
                } else {
                    return [];
                }
            },
            tengoupdateprivileges() {
                return (
                    (this.myPrivsHere.admin && this.myPrivsHere.admin.full) ||
                    (this.myPrivsHere.prv &&
                        this.myPrivsHere.prv[this.institIDToCreateAt] &&
                        this.myPrivsHere.prv[this.institIDToCreateAt].det &&
                        (this.myPrivsHere.prv[this.institIDToCreateAt].det.update ||
                            this.myPrivsHere.prv[this.institIDToCreateAt].det.create))
                );
            },
            availableFileTypes() {
                return Object.values(this.forms_logic.tipos_files || {}).filter((fileType) => {
                    return fileType.id === "a" || fileType.id === "csv";
                });
            },
            onlyFirst20Errors() {
                if (this.arrayOfErrors.length == 21) return this.arrayOfErrors.slice(0, 20);
                return this.arrayOfErrors.slice(0, 19);
            },
            tags() {
                let tags = Object.values(this.projectInFocusInfo?.fileTags || {}) || [];
                return tags
                    .filter((tag) => {
                        return !tag.inct;
                    })
                    .sort((a, b) => {
                        let alow = a.tx || a.tx;
                        let blow = b.tx || b.tx;
                        if (alow > blow) {
                            return 1;
                        } else if (alow < blow) {
                            return -1;
                        } else {
                            return 0;
                        }
                    });
            },
            enabledCSVFileFields() {
                return this.enabledFiles.filter((file) => {
                    return file.tipo === "csv" || file.tipo === "a";
                });
            },
        },
        created() {
            this.initialConfigs();

            if (!this.enabledFiles.length) {
                this.shouldCreateNewFileField = true;
            }
            //  this.preHeatFunctions();
        },
        methods: {
            async changeAutoGeneratedId(checked) {
                if (checked) {
                    try {
                        this.$f7.dialog.preloader("Generating IDs...");
                        let call = this.$firebase.functions().httpsCallable("v2_autoGenerateIdsForBulkUpload");
                        let response = await call({
                            allDataArray: this.allDataArray,
                            projectId: this.projectInFocusInfo.id,
                            dev: this.$store.state.dev,
                            startingDataRow: this.startingDataRow || null,
                            hasHeaders: this.tieneHeaders,
                            headersLastRow: this.headersLastRow,
                        });
                        if (response.data.error) throw new Error(response.data.error);
                        this.setAllDataArray(response.data.newArray);
                        this.entityIdColumn = "0";
                        this.autoGeneratedId = true;
                        this.setEntityIdColumn(this.entityIdColumn);
                        this.$f7.dialog.close();
                    } catch (error) {
                        this.$f7.dialog.close();
                        console.log(error);
                        this.$f7.dialog.alert(error.message);
                    }
                } else {
                    this.allDataArray.forEach((row, index) => {
                        this.allDataArray[index].shift();
                    });
                    this.setAllDataArray(this.allDataArray);
                    this.entityIdColumn = null;
                    this.autoGeneratedId = false;
                }
            },
            setEntityIdRow(row) {
                this.entityIdRow = row;
                this.setFirstAndSecondRowis(row);
            },
            async preHeatFunctions() {
                try {
                    const addBulkUploadFileToProcessingQueue = this.$firebase
                        .functions()
                        .httpsCallable("v2_addBulkUploadFileToProcessingQueue");
                    const computeAlternavemapps = this.$firebase.functions().httpsCallable("v2_computeAlternavemapps");
                    let array = [
                        addBulkUploadFileToProcessingQueue({
                            preHeat: true,
                            projectId: this.projectInFocusInfo.id,
                            csvData: [],
                            idColumn: 1,
                        }),
                        computeAlternavemapps({
                            preHeat: true,
                            neoval: null,
                            idx: 0,
                            allDataArray: this.allDataArray,
                            tieneHeaders: this.tieneHeaders,
                            mapperDatas: this.mapperDatas,
                        }),
                    ];
                    let responses = await Promise.allSettled(array);
                    responses.forEach((res) => {
                        if (res.value.data.error) {
                            throw new Error(res.data.error.message);
                        }
                    });
                } catch (error) {
                    console.log(error);
                }
            },
            allEnabler(esteObj) {
                return Object.values(esteObj || {})
                    .filter((unet) => {
                        return !unet.disabled;
                    })
                    .sort((a, b) => {
                        let alow = a.orden;
                        let blow = b.orden;
                        if (alow > blow) {
                            return 1;
                        } else if (alow < blow) {
                            return -1;
                        } else {
                            return 0;
                        }
                    });
            },
            autoMapAlternatives({ columnaIndex, preguntaId, entitySlotIndex, uniques }) {
                let arrayOfUniques = Object.values(uniques || {}) || [];
                let alternativesOfEntityDataSlot =
                    Object.values(this.mapperDatas[entitySlotIndex].alternativas || {}) || [];
                alternativesOfEntityDataSlot = alternativesOfEntityDataSlot.filter((alt) => {
                    return !alt.inct;
                });
                let allPairScores = [];
                arrayOfUniques.forEach((ununiqueobj) => {
                    alternativesOfEntityDataSlot.forEach((unaltObject) => {
                        let score = 0;
                        if (unaltObject.tx === ununiqueobj.tx) {
                            score = 100;
                        } else if (unaltObject.norm === ununiqueobj.norm) {
                            score = 90;
                        } else {
                            score = stringSimilarity.compareTwoStrings(unaltObject.tx, ununiqueobj.tx) * 100;
                        }
                        if (score > 70) {
                            allPairScores.push({
                                score,
                                uniqueNorm: ununiqueobj.norm,
                                uniqueText: ununiqueobj.tx,
                                alternativeId: unaltObject.id,
                                alternativeText: unaltObject.tx,
                            });
                        }
                    });
                });
                allPairScores.sort((a, b) => {
                    if (a.score < b.score) {
                        return 1;
                    } else if (a.score > b.score) {
                        return -1;
                    } else {
                        return 0;
                    }
                });
                let alternativeIdsYaSeteados = {};
                let uniqueNormsYaMapeados = {};
                allPairScores.forEach((unpotscore) => {
                    if (
                        !alternativeIdsYaSeteados[unpotscore.alternativeId] &&
                        !uniqueNormsYaMapeados[unpotscore.uniqueNorm]
                    ) {
                        this.setAlterMapVal({
                            preguntaIdx: entitySlotIndex,
                            normKey: unpotscore.uniqueText, // aqui saque unpotscore.norm,
                            alternativeId: unpotscore.alternativeId,
                        });
                        alternativeIdsYaSeteados[unpotscore.alternativeId] = true;
                        uniqueNormsYaMapeados[unpotscore.uniqueNorm] = true;
                    }
                });
            },
            autoMapColumns() {
                //primero computar scores para todas las parejas
                let firstRowOfFile = this.firstRowis || [];
                let entityDataSlots = this.mapperDatas || [];
                let allPairScores = [];
                firstRowOfFile.forEach((colvalue, colindex) => {
                    if (this.entityIdColumn != colindex && colvalue !== "") {
                        entityDataSlots.forEach((unpotDataSlot, slotindex) => {
                            colvalue = colvalue.toLowerCase();
                            let encabezado = unpotDataSlot?.encabezado?.toLowerCase() || "";
                            let score = 0;
                            if (encabezado === colvalue) {
                                score = 100;
                            } else if (this.leNormalize(encabezado) === this.leNormalize(colvalue)) {
                                score = 90;
                            } else {
                                score = stringSimilarity.compareTwoStrings(encabezado, colvalue) * 100;
                            }
                            if (score > 70) {
                                allPairScores.push({
                                    score,
                                    sourceColIdx: colindex,
                                    sourceColVal: colvalue,
                                    targetDataSlotID: unpotDataSlot.id,
                                    targetDataSlotIndex: slotindex,
                                    targetDataSlotVal: encabezado,
                                });
                            }
                        });
                    }
                });
                allPairScores.sort((a, b) => {
                    if (a.score < b.score) {
                        return 1;
                    } else if (a.score > b.score) {
                        return -1;
                    } else {
                        return 0;
                    }
                });
                //Todo mapear a columnas
                let dataSlotsIDSYaSeteados = {};
                let colsYaMapeadas = {};
                allPairScores.forEach((unpotscore) => {
                    if (
                        !dataSlotsIDSYaSeteados[unpotscore.targetDataSlotID] &&
                        !colsYaMapeadas[unpotscore.sourceColIdx]
                    ) {
                        this.setNewMappedCol({
                            idx: unpotscore.targetDataSlotIndex,
                            cual: "mapcol",
                            neoval: unpotscore.sourceColIdx,
                        });
                        this.automapped = true;
                        dataSlotsIDSYaSeteados[unpotscore.targetDataSlotID] = true;
                        colsYaMapeadas[unpotscore.sourceColIdx] = true;
                    }
                });
            },
            getUniques(mapcol) {
                return this.uniqueCols[`col_${mapcol}`] ? this.uniqueCols[`col_${mapcol}`].uniques : {};
            },
            async updateImportStats() {
                try {
                    let call = this.$firebase.functions().httpsCallable("v2_updateImportStats");
                    let response = await call({
                        toProcessStats: this.toProcessStats,
                        tieneHeaders: this.tieneHeaders,
                        headersLastRow: this.headersLastRow,
                    });
                    if (response.data.error) throw new Error(response.data.error);
                    this.erroresTotaltes = response.data.arrayOfErrors.length;
                    this.aImportar = response.data.largoTotal - this.erroresTotaltes;

                    this.arrayOfErrors = response.data.arrayOfErrors;

                    this.$f7.dialog.close();
                    this.preloaderOn = false;
                } catch (error) {
                    this.$f7.dialog.close();
                    this.preloaderOn = false;
                    console.log(error);
                    this.$f7.dialog.alert(error.message);
                }
            },
            hasNumber(myString) {
                return /\d/.test(myString);
            },
            meetsIDrequirement({ potId }) {
                // console.log('meetsIDrequirement')
                let potentialError = "";
                if (this.projectInFocusInfo.primaryidType) {
                    if (this.projectInFocusInfo.primaryidType === "let" && (!isNaN(potId) || this.hasNumber(potId))) {
                        potentialError = "must not be a number or include a number";
                    } else if (this.projectInFocusInfo.primaryidType === "num" && isNaN(potId)) {
                        potentialError = "must be a number";
                    }
                }
                let stringyfyit = String(potId);
                if (
                    this.projectInFocusInfo.primaryidMinLen &&
                    stringyfyit.length < this.projectInFocusInfo.primaryidMinLen
                ) {
                    potentialError = `must be at least ${this.projectInFocusInfo.primaryidMinLen} ${
                        this.projectInFocusInfo.primaryidType === "num" ? "digits" : "characters"
                    } long`;
                }
                if (
                    this.projectInFocusInfo.primaryidMaxLen &&
                    stringyfyit.length > this.projectInFocusInfo.primaryidMaxLen
                ) {
                    potentialError = `must be at most ${this.projectInFocusInfo.primaryidMaxLen} ${
                        this.projectInFocusInfo.primaryidType === "num" ? "digits" : "characters"
                    } long`;
                }
                return potentialError;
            },
            updateErrors({ sourceOfError, newErrors }) {
                //borrar todos los Errores existentes de este origen
                Object.values(this.toProcessStats).forEach((unpoterror) => {
                    if (sourceOfError == "changeTypeOfUpload")
                        this.$delete(this.toProcessStats[unpoterror.id], "errores");
                    else this.$delete(this.toProcessStats[unpoterror.id].errores, sourceOfError);
                });
                newErrors.forEach((neoError) => {
                    this.$set(this.toProcessStats[`row_${neoError.idx}`].errores, sourceOfError, neoError.rsn);
                });
                this.updateImportStats();
            },
            async getIdNewErrors(esprimero, preloader) {
                if (preloader) this.$f7.dialog.preloader("Checking entities...");
                try {
                    let call = this.$firebase.functions().httpsCallable("v2_getIdNewErrors");
                    let response = await call({
                        allDataArray: this.allDataArray,
                        entityIdColumn: this.entityIdColumn,
                        tieneHeaders: this.tieneHeaders,
                        headersLastRow: this.headersLastRow,
                        typeOfUpload: this.typeOfUpload,
                        projectInFocusInfo: this.projectInFocusInfo,
                        dev: this.$store.state.dev,
                        BUTargetFile: this.BUTargetFile,
                        skippedEntities: this.skippedEntities,
                    });
                    if (response.data.error) throw new Error(response.data.error);
                    let newErrors = response.data.newErrors;
                    this.updateErrors({ sourceOfError: "entityid", newErrors });
                    if (esprimero && (this.typeOfUpload === "makeEntities" || this.typeOfUpload === "updateEntities")) {
                        this.autoMapColumns();
                    }
                    if (preloader) this.$f7.dialog.close();
                } catch (error) {
                    if (this.preloaderOn) this.$f7.dialog.close();
                    console.log(error);
                    this.$f7.dialog.alert(error.message);
                }
            },
            async setEntityIdColumn(entityIdColumn) {
                // if(entityIdColumn==this.entityIdColumn)return
                let esprimero = this.entityIdColumn === "";
                this.preloaderOn = true;
                this.$f7.dialog.preloader("Loading...");
                this.entityIdColumn = entityIdColumn;
                //inicializar

                this.getIdNewErrors(esprimero);
            },
            async validaValToPerm({ loadedData, questionToEval }) {
                try {
                    let newErrors = [];
                    this.allDataArray.forEach((unafila, fila) => {
                        if (!this.tieneHeaders || fila > 0) {
                            let valor = unafila[questionToEval.mapcol];
                            if (questionToEval.req && (valor === null || !String(valor).trim())) {
                                newErrors.push({
                                    idx: fila,
                                    rsn: `${questionToEval.encabezado} is required`,
                                });
                            } else if (questionToEval.mapcol === "" || questionToEval.mapcol === "skip") {
                                return;
                            } else if (
                                questionToEval.tipo === "input_fecha" &&
                                !this.$dayjs(valor, questionToEval.dateformat, true).isValid()
                            ) {
                                newErrors.push({
                                    idx: fila,
                                    rsn: `Invalid Date in ${questionToEval.encabezado}`,
                                });
                            } else if (questionToEval.tipo === "input_numero" && isNaN(valor)) {
                                newErrors.push({
                                    idx: fila,
                                    rsn: `Not a number in ${questionToEval.encabezado}`,
                                });
                            }
                        }
                    });
                    //   if(questionToEval.tipo==='input_texto'){
                    //     //definir siempre primero entityID
                    //   }
                    this.updateErrors({
                        sourceOfError: `dataCheck_${questionToEval.id}`,
                        newErrors,
                    });
                    return {};
                } catch (error) {
                    return { error: error.message };
                }
            },
            setNewMappedCol({ idx, cual, neoval, col }) {
                let toupdaetr = {};
                if (cual === "mapcol") {
                    neoval = neoval === "" || neoval === "skip" ? neoval : parseInt(neoval);
                }
                toupdaetr[cual] = neoval;
                this.mapperDatas.splice(idx, 1, Object.assign({}, this.mapperDatas[idx], toupdaetr));
                if (
                    cual === "mapcol" &&
                    this.pregsMultiChoice.includes(this.mapperDatas[idx].tipo)
                    //&& !this.uniqueCols[`col_${neoval}`]
                ) {
                    //computar valores unicos de alternativas solo para elige una o elige many, y solo si no se han computado aun
                    this.computeAlternavemapps({ neoval, idx });
                } else if (cual === "separator" || cual === "dateformat") {
                    this.computeAlternavemapps({ neoval: col, idx });
                }
                let { error } = this.validaValToPerm({
                    loadedData: this.allDataArray,
                    questionToEval: this.mapperDatas[idx],
                });
            },
            empujaANuevoUniqueDeColumna({ filaIndex, unpotvl, nuevoComputo }) {
                let norm = this.leNormalize(unpotvl);
                if (!nuevoComputo.uniques[unpotvl]) {
                    nuevoComputo.uniques[unpotvl] = {
                        rowsDondeAparece: {
                            [`row_${filaIndex}`]: filaIndex,
                        },
                        skip: false,
                        tx: unpotvl,
                        norm,
                    };
                } else {
                    nuevoComputo.uniques[unpotvl].rowsDondeAparece[`row_${filaIndex}`] = filaIndex;
                }
                return nuevoComputo;
            },
            async computeAlternavemapps({ neoval, idx }) {
                try {
                    if (!this.preloaderOn) this.$f7.dialog.preloader("computing...");

                    let call = this.$firebase.functions().httpsCallable("v2_computeAlternavemapps");
                    let response = await call({
                        neoval,
                        idx,
                        allDataArray: this.allDataArray,
                        tieneHeaders: this.tieneHeaders,
                        mapperDatas: this.mapperDatas,
                    });
                    if (response.data.error) throw new Error(response.data.error);
                    let nuevoComputo = response.data.nuevoComputo;
                    if (!this.uniqueCols[`col_${neoval}`]) {
                        this.$set(this.uniqueCols, `col_${neoval}`, nuevoComputo);
                    } else {
                        this.$set(this.uniqueCols[`col_${neoval}`], "uniques", nuevoComputo.uniques);
                    }
                    //ahora intentar procesar mapeo automatico
                    //this.optionsMapper[preguntaId][columnaIndex][normKey];
                    let columnaIndex = neoval;
                    let preguntaId = this.mapperDatas[idx].id;
                    if (!this.optionsMapper[preguntaId] || !this.optionsMapper[preguntaId][columnaIndex]) {
                        //es primer computo, intentar el mtach automatico //{entitySlotID,entitySlotIndex,normx}
                        this.autoMapAlternatives({
                            columnaIndex,
                            preguntaId,
                            entitySlotIndex: idx,
                            uniques: nuevoComputo.uniques,
                        });
                    }
                    this.checkAllMapsOk({ preguntaIdx: idx });
                    this.$f7.dialog.close();
                    this.preloaderOn = false;
                } catch (error) {
                    this.$f7.dialog.close();
                    this.preloaderOn = false;
                    console.log(error);
                    this.$f7.dialog.alert(error.message);
                }
            },
            processFilePls() {
                if (!this.typeOfUpload) {
                    return this.$f7.dialog.alert("You must indicate the type of upload process");
                }
                if (!this.entityIdColumn) {
                    return this.$f7.dialog.alert("You must indicate the column corresponding to the entity ID");
                }
                if (!this.allDataArray.length) {
                    return this.$f7.dialog.alert("No data to be imported: empty CSV file");
                }

                if (!this.enabledFiles.length && !this.shouldCreateNewFileField) {
                    return this.$f7.dialog.alert(
                        "There are no enabled file fields in the project. You must create a new file field to import data",
                    );
                }

                if (this.typeOfUpload === "makeEntities" || this.typeOfUpload === "updateEntities") {
                    if (this.typeOfUpload === "makeEntities" && !this.institIDToCreateAt) {
                        return this.$f7.dialog.alert("You must indicate an Institution where to create the entities");
                    }
                    let faltaUnMapeoUnaObligatorio = this.mapperDatas.some((unaquest) => {
                        return unaquest.req && unaquest.mapcol === "";
                    });
                    if (this.typeOfUpload === "makeEntities" && faltaUnMapeoUnaObligatorio) {
                        return this.$f7.dialog.alert(`All mandatory fields(*) need to be mapped to a CSV column`);
                    }
                    let payloaderToSend = {};
                    this.mapperDatas.forEach((unaque) => {
                        let questMetaTosend = {
                            qid: unaque.id,
                            mapcol: unaque.mapcol || unaque.mapcol === 0 ? unaque.mapcol : "skip",
                            separator: unaque.separator || null,
                            dateformat: unaque.dateformat || null,
                            optionsMapper: this.optionsMapper[unaque.id]
                                ? this.optionsMapper[unaque.id][unaque.mapcol]
                                : {},
                        };
                        payloaderToSend[unaque.id] = questMetaTosend;
                    });
                    if (this.typeOfUpload === "updateEntities") this.callcreaNeoEntityt(payloaderToSend);
                    else this.checkExistingEntities(payloaderToSend);
                } else {
                    if (!this.shouldCreateNewFileField && !this.BUTargetFile) {
                        return this.$f7.dialog.alert("You must indicate a file field to import the data");
                    }

                    this.bulkUploadFiles();
                }
            },
            async bulkUploadFiles() {
                if (this.shouldCreateNewFileField) {
                    if (!this.newFileField.encabezado) {
                        return this.$f7.dialog.alert("You must specify a name for the new file field");
                    }
                    if (!this.newFileField.tipo) {
                        return this.$f7.dialog.alert("You must specify a type for the new file field");
                    }
                }
                try {
                    // Filtrar archivos
                    this.$f7.dialog.preloader("Processing files...");

                    const filterAllDataArray = this.$firebase.functions().httpsCallable("v2_filterAllDataArray");

                    const filterResponse = await filterAllDataArray({
                        allDataArray: this.allDataArray,
                        toProcessStats: this.toProcessStats,
                        tieneHeaders: this.tieneHeaders,
                        headersLastRow: this.headersLastRow,
                    });

                    const { allDataArrayFiltered, error } = filterResponse.data;

                    if (error) {
                        throw new Error(error);
                    }
                    const addBulkUploadFileToProcessingQueue = this.$firebase
                        .functions()
                        .httpsCallable("v2_addBulkUploadFileToProcessingQueue");
                    const response = await addBulkUploadFileToProcessingQueue({
                        projectId: this.projectInFocusInfo.id,
                        csvData: allDataArrayFiltered,
                        hasHeaders: this.tieneHeaders,
                        headersLastRow: this.headersLastRow,
                        idColumn: this.entityIdColumn,
                        targetFileFieldId: this.BUTargetFile,
                        shouldCreateNewFileField: this.shouldCreateNewFileField,
                        newFileField: this.newFileField,
                        dev: this.$store.state.dev,
                        tags: Object.values(this.selectedTags).length ? this.selectedTags : null,
                        fileBulkName: this.fileBulkName,
                        startingDataRow: this.headersLastRow > 0 ? this.startingDataRow : null,
                    });

                    if (response.data.error) {
                        throw new Error(response.data.error.message);
                    }

                    this.$f7.dialog.close();

                    this.$f7.toast.show({
                        text: "File was successfully added to the processing queue. You'll be notified when it is processed via email.",
                        closeTimeout: 6000,
                        closeButton: true,
                        horizontalPosition: "center",
                    });

                    this.$f7.views.main.router.back();
                } catch (error) {
                    console.error(error);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error.message, error.code);
                }
            },
            fxndclean(rawIdentity) {
                let a = String(rawIdentity)?.trim().toUpperCase(); //asegurar que sea string o forzar string, y hacer mayuscula
                let b = a.split(".").join(""); //sacar puntos
                let c = b.split("-").join(""); //sacar dashes
                let d = c.split(" ").join(""); //sacar espacios
                let e = d.split(",").join(""); //sacar comas
                let f = e.split("/").join(""); //sacar slashes
                return f;
            },
            async checkExistingEntities(payloaderToSend) {
                this.$f7.dialog.preloader("Checking unique entities");
                try {
                    let call = this.$firebase.functions().httpsCallable("v2_checkUniqueEntities");
                    let response = await call({
                        allDataArray: this.allDataArray,
                        dev: this.$store.state.dev,
                        entityIdColumn: this.entityIdColumn,
                    });
                    let uniqueEntities = response.data.uniqueEntities;
                    this.$f7.dialog.close();
                    if (uniqueEntities.length != this.allDataArray.length) {
                        if (!this.tengoupdateprivileges) {
                            this.$f7.dialog
                                .create({
                                    text: "Some entities already exists, but you don`t have UPDATE privileges, do you want to continue uploading the other ones?",
                                    buttons: [
                                        {
                                            text: "YES",
                                            onClick: () => {
                                                this.callcreaNeoEntityt(payloaderToSend, uniqueEntities);
                                            },
                                        },
                                        {
                                            text: "CANCEL",
                                        },
                                    ],
                                })
                                .open();
                        } /* else {
                        this.$f7.dialog
                            .create({
                                text: 'Some entities already exists, do you want to overwrite them?',
                                buttons: [
                                    {
                                        text: 'YES',
                                        onClick: () => {
                                            this.callcreaNeoEntityt(
                                                payloaderToSend
                                            );
                                        },
                                    },
                                    {
                                        text: 'NO',
                                        onClick: () => {
                                            this.callcreaNeoEntityt(
                                                payloaderToSend,
                                                uniqueEntities
                                            );
                                        },
                                    },
                                    {
                                        text: 'CANCEL',
                                    },
                                ],
                            })
                            .open();
                    } */
                    } else this.callcreaNeoEntityt(payloaderToSend, uniqueEntities);
                } catch (error) {
                    this.$f7.dialog.close();
                    console.log(error);
                    this.$f7.dialog.alert(error.message);
                }
            },
            async callcreaNeoEntityt(payloaderToSend, uniqueEntities) {
                let missingMapping = this.mapperDatas.some((onedata) => {
                    return (
                        onedata.mapcol &&
                        this.pregsMultiChoice.includes(onedata.tipo) &&
                        onedata.mapcol != "skip" &&
                        (!this.todosMapeosOk[onedata.id] || !this.todosMapeosOk[onedata.id][onedata.mapcol])
                    );
                });
                if (missingMapping) return this.$f7.dialog.alert("Missing alternatives mapping");

                this.$f7.dialog.preloader("Checking Data...");

                try {
                    let call = this.$firebase.functions().httpsCallable("v2_filterAllDataArray");
                    let response = await call({
                        allDataArray: uniqueEntities ? uniqueEntities : this.allDataArray,
                        toProcessStats: this.toProcessStats,
                        tieneHeaders: this.tieneHeaders,
                        mapperDatas: this.mapperDatas,
                        headersLastRow: this.headersLastRow,
                    });
                    if (response.data.error) {
                        throw new Error(response.data.error);
                    }
                    let allDataArrayFiltered = response.data.allDataArrayFiltered;
                    this.$f7.dialog.close();

                    this.$f7.dialog.preloader("Uploading and processing...");
                    let datous = await this.commonExecute(
                        {
                            projectid: this.projectInFocusInfo.id,
                            allDataArray: allDataArrayFiltered,
                            institIDToCreateAt: this.institIDToCreateAt,
                            payloaderToSend,
                            tieneHeaders: this.tieneHeaders,
                            entityIdColumn: this.entityIdColumn,
                            updateEntities: this.typeOfUpload === "updateEntities",
                        },
                        "v2_procesaBulkcrea",
                        false,
                    );
                    if (datous.error) {
                        throw new Error(datous.error.message);
                    }
                    let payload = datous.payload;

                    this.completedInfo(datous);
                    this.$f7.dialog.close();
                } catch (error) {
                    console.log(error);
                    this.$f7.dialog.close();
                    console.error(error.code, error.message);
                    this.$f7.dialog.alert(error.message, error.code || "Error");
                }
            },
            async completedInfo(allinfo) {
                if (Object.keys(allinfo.newField || {}).length) {
                    // Si se creó un nuevo campo en el bulk upload, se actualiza el proyecto
                    this.$store.commit("addItemNivefour", {
                        a1: "projects",
                        b2: this.projectInFocus,
                        c3: "entityFiles",
                        d4: allinfo.newField.id,
                        e5: allinfo.newField,
                    });
                }

                if (allinfo.newEntitiesByQuestion && Object.values(allinfo.newEntitiesByQuestion).length) {
                    let projectinfo = Object.assign({}, this.projectInFocusInfo);
                    Object.keys(allinfo.newEntitiesByQuestion).forEach((entitidataid) => {
                        if (!projectinfo.entityData[entitidataid].alternativas)
                            projectinfo.entityData[entitidataid].alternativas = {};
                        projectinfo.entityData[entitidataid].alternativas = Object.assign(
                            projectinfo.entityData[entitidataid].alternativas,
                            allinfo.newEntitiesByQuestion[entitidataid],
                        );
                    });

                    this.$store.commit("addItemNivethree", {
                        a1: "projects",
                        b2: this.projectInFocus,
                        c3: "entityData",
                        d4: projectinfo.entityData,
                    });
                }
                this.$f7.views.main.router.back();
                this.$f7.toast.show({
                    text: "Bulk upload success!",
                    position: "bottom",
                    horizontalPosition: "center",
                    closeTimeout: 2000,
                });
            },
            leNormalize(str) {
                let lstring = String(str || "");
                return lstring.trim().toUpperCase();
            },
            initialConfigs() {
                this.mapperDatas = this.enabledData.map((undata) => {
                    undata.mapcol = "";
                    undata.separator = "|";
                    undata.dateformat = "MM/DD/YYYY";
                    //leNormalize
                    let prepotAltesr = Object.values(undata.alternativas || {});
                    let potAltesr = prepotAltesr.filter((alt) => {
                        return !alt.inct;
                    });
                    if (potAltesr.length) {
                        undata.alternamaps = {};
                        potAltesr.forEach((unalter) => {
                            unalter.norm = this.leNormalize(unalter.tx);
                            undata.alternamaps[unalter.id] = unalter;
                        });
                    }
                    return undata;
                });
                this.institIDToCreateAt =
                    this.InstitutionsICanCreateFrom.length != 1 ? "" : this.InstitutionsICanCreateFrom[0].id;
                this.typeOfUpload =
                    !this.InstitutionsICanCreateFrom.length && this.enabledFiles.length
                        ? "createFiles"
                        : !this.InstitutionsICanEditFrom.length
                        ? "makeEntities"
                        : "";
            },
            setAlterMapVal({ preguntaIdx, normKey, alternativeId }) {
                let columnaIndex = this.mapperDatas[preguntaIdx].mapcol;
                let preguntaId = this.mapperDatas[preguntaIdx].id;
                if (!this.optionsMapper[preguntaId]) {
                    this.$set(this.optionsMapper, preguntaId, {
                        [columnaIndex]: { [normKey]: alternativeId },
                    });
                } else if (!this.optionsMapper[preguntaId][columnaIndex]) {
                    this.$set(this.optionsMapper[preguntaId], columnaIndex, {
                        [normKey]: alternativeId,
                    });
                } else {
                    this.$set(this.optionsMapper[preguntaId][columnaIndex], normKey, alternativeId);
                }
                //aqui actualizar errores solo para preguntas mandatorias
                if (this.mapperDatas[preguntaIdx].req) {
                    let newErrors = [];
                    if (alternativeId === "noImport") {
                        //configurar el skip
                        this.$set(this.uniqueCols[`col_${columnaIndex}`].uniques[normKey], "skip", true);
                        //No va a importar esta altenrativa, buscar todas las rowsDonde Aparece
                        let uniquesHolder = this.uniqueCols[`col_${columnaIndex}`] || {};
                        let preUniqueInfo = uniquesHolder.uniques || {};
                        let todasASkipearEnestaCola = Object.values(preUniqueInfo)
                            .filter((ununique) => {
                                return ununique.skip;
                            })
                            .map((ununique) => {
                                return ununique.tx;
                            });
                        let preRowsDondeAparece = preUniqueInfo[normKey] || {};
                        let rowsDondeAparece = preRowsDondeAparece.rowsDondeAparece || {};
                        Object.values(rowsDondeAparece).forEach((rowindex) => {
                            let elvalor = this.allDataArray[rowindex][columnaIndex];
                            if (this.mapperDatas[preguntaIdx].tipo === "elige_many") {
                                let cuantasQuedan = elvalor
                                    .split(this.mapperDatas[preguntaIdx].separator)
                                    .filter((unpotval) => {
                                        return !todasASkipearEnestaCola.includes(
                                            unpotval, // aqui saque this.leNormalize(unpotval)
                                        );
                                    }).length;
                                if (!cuantasQuedan) {
                                    //agregar error
                                    newErrors.push({
                                        idx: rowindex,
                                        rsn: `${todasASkipearEnestaCola.join(" & ")} omitted in mandatory field ${
                                            this.mapperDatas[preguntaIdx].encabezado
                                        }`,
                                    });
                                }
                            } else {
                                //agregar error
                                newErrors.push({
                                    idx: rowindex,
                                    rsn: `${todasASkipearEnestaCola.join(" & ")} omitted in mandatory field ${
                                        this.mapperDatas[preguntaIdx].encabezado
                                    }`,
                                });
                            }
                        });
                    }
                    this.updateErrors({
                        sourceOfError: `altCheck_${this.mapperDatas[preguntaIdx].id}_alts_${normKey}`,
                        newErrors,
                    });
                }
                this.checkAllMapsOk({ preguntaIdx });
            },
            checkAllMapsOk({ preguntaIdx }) {
                //todosMapeosOk getUniques
                let preguntaId = this.mapperDatas[preguntaIdx].id;
                let columnaIndex = this.mapperDatas[preguntaIdx].mapcol;
                let uniquesEstaColumna = this.uniqueCols[`col_${columnaIndex}`]
                    ? this.uniqueCols[`col_${columnaIndex}`].uniques
                    : {};
                let pregOBjet = this.optionsMapper[preguntaId] || {};
                let colObjET = pregOBjet[columnaIndex] || {};
                let todosMapeosOk = Object.values(uniquesEstaColumna).every((ununique) => {
                    return colObjET[ununique.tx] || colObjET[ununique.tx] === 0;
                });
                if (!this.todosMapeosOk[preguntaId]) {
                    this.$set(this.todosMapeosOk, preguntaId, {
                        [columnaIndex]: todosMapeosOk,
                    });
                } else {
                    this.$set(this.todosMapeosOk[preguntaId], columnaIndex, todosMapeosOk);
                }
            },
            parseaBulkFile(e, selectedFile) {
                let file;
                if (selectedFile) {
                    file = this.selectedFile;
                } else {
                    file = e.target.files[0];
                    this.selectedFile = file;
                }
                if (file) {
                    this.size = file.size;
                    this.processingFileBul = true;
                    this.fileBulkName = file.name;
                    this.$papa.parse(file, {
                        delimiter: this.delimiter,
                        worker: true,
                        complete: (results, file) => {
                            let preladata = results.data || [];

                            this.setAllDataArray(preladata);
                            if (this.autoGeneratedId) this.changeAutoGeneratedId(true);
                        },
                    });
                }
            },
            setAllDataArray(preladata) {
                let ladata = preladata.filter((row) => {
                    return !row.every((col) => {
                        return !col;
                    });
                });
                let posibleHeaderRows = ladata.length - 1;
                if (posibleHeaderRows > 10) this.posibleHeaderRows = 10;
                else this.posibleHeaderRows = posibleHeaderRows;
                let firstRowis = ladata[0] || [];
                this.firstRowis = firstRowis.filter((col) => {
                    return col.length;
                });
                this.secondRow = ladata[1] || [];
                this.allDataArray = ladata;
                this.allDataArrayNotFiltered = ladata;
                this.firstRowisNotFiltered = firstRowis;
                if (this.headersLastRow != 0) {
                    this.setHeadersLastRow(this.headersLastRow);
                }
                let toPopulate = {};
                ladata.forEach((unadat, indiceFila) => {
                    toPopulate[`row_${indiceFila}`] = {
                        id: `row_${indiceFila}`,
                        indiceFila,
                        errores: {},
                    };
                });
                this.toProcessStats = Object.assign({}, toPopulate);
                this.processingFileBul = false;
            },
            tomarArhivo() {
                this.$$("#bulkfile-input").click();
            },
            setLastColumn(col) {
                this.lastColumn = col;
                if (this.lastColumn) {
                    this.allDataArray = this.allDataArrayNotFiltered.map((row) => {
                        return row.filter((col, i) => {
                            return i <= this.lastColumn;
                        });
                    });
                } else {
                    this.allDataArray = this.allDataArrayNotFiltered;
                }
            },
            setHeadersLastRow(row) {
                row = Number(row);
                this.headersLastRow = row;
                this.startingDataRow = row + 2;
                //if(this.startingDataRow>2) this.setFirstAndSecondRowis(row);
                if (this.entityIdColumn) this.setEntityIdColumn("");
            },
            setFirstAndSecondRowis(row) {
                row = Number(row);
                this.firstRowis = this.allDataArrayNotFiltered[row];
                this.secondRow = this.allDataArrayNotFiltered[row + 1];
            },
            selectTag() {
                let array = this.$refs["tagsSelection"].f7SmartSelect.getValue() || [];
                array.forEach((selected) => {
                    this.selectedTags[selected] = true;
                });
                if (this.selectedTags) {
                    Object.keys(this.selectedTags).forEach((tag) => {
                        if (!array.includes(tag)) {
                            delete this.selectedTags[tag];
                        }
                    });
                }
            },
            getSkippedEntities(checked) {
                if (!checked) {
                    this.skippedEntities = null;
                } else {
                    this.skippedEntities = true;
                }
                let esprimero = false;
                let preloader = true;

                this.getIdNewErrors(esprimero, preloader);
            },
            setTypeOfUpload(type) {
                this.typeOfUpload = type;
                this.entityIdColumn = "";
                this.parseaBulkFile(false, true);
            },
            createNewOptionsForAll(idx, onedata) {
                let uniques = this.getUniques(onedata.mapcol);
                Object.values(uniques).forEach((ununique) => {
                    if (this.optionsMapper[onedata.id]?.[onedata.mapcol]?.[ununique.tx]) return;
                    this.setAlterMapVal({
                        preguntaIdx: idx,
                        normKey: ununique.tx,
                        alternativeId: "creaNeo",
                    });
                });
            },
        },
    };
</script>
<style>
    #bulkfile-input {
        display: none;
    }
    .popover-errors-entityid {
        width: 400px !important;
        max-height: 50vh;
        overflow-y: auto;
    }
    .inutreal.media-item .item-title {
        color: #115300 !important;
    }
    .albolder {
        font-weight: bolder !important;
    }
    .anciliaryx.media-item .item-title {
        color: #2e2e2e !important;
        font-size: 13px !important;
    }
    .anciliaryx.media-item .item-title-row {
        padding-top: 12px;
    }
    .anciliaryx.media-item .item-inner {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }
    .anciliaryx.media-item {
        background: #e3e3e3 !important;
    }
    .warnmaps .item-link {
        background-color: #fff1b7 !important;
    }
    .wanmapper .item-after select,
    .pendingOptionSetWarning .item-after select {
        background-color: #ffcc00 !important;
    }
    .pendingOptionSetCritical .item-after select {
        background-color: #ffcc00 !important;
    }
    .leheaderxmappers.media-item {
        background-color: #e3e3e3 !important;
        height: 30px !important;
        line-height: 30px !important;
    }
    .leheaderxmappers.media-item .item-content,
    .leheaderxmappers.media-item .item-inner {
        margin-top: 0px;
        padding-top: 0px;
        padding-bottom: 0px;
        margin-bottom: 0px;
        height: 30px !important;
        max-height: 30px !important;
        line-height: 30px !important;
        min-height: 30px !important;
    }
    .leheaderxmappers.media-item .item-title-row {
        padding-top: 0px !important;
    }
    .leheaderxmappers .item-after {
        position: relative;
        top: 0px !important;
    }
    .anciliaryx .item-after {
        position: relative;
        top: -7px;
    }
    .itemafterwithmax .item-after {
        max-width: 70% !important;
    }
    .leitalictitle.item-divider {
        font-style: italic;
    }
</style>
