<template>
    <f7-view name="popneo">
        <f7-page bg-color="red">
            <f7-navbar
                subtitle="Creating"
                :title="`${
                    newEntity.id
                        ? (projectInFocusInfo.entity || 'entity') +
                          ' ' +
                          projectInFocusInfo.primaryid +
                          ' ' +
                          newEntity.id
                        : 'New ' + (projectInFocusInfo.entity ? projectInFocusInfo.entity : 'entity')
                }`"
            >
                <f7-nav-right><f7-link @click="closingAttempt" large>Close</f7-link></f7-nav-right>
            </f7-navbar>
            <f7-list class="no-margin-top" v-if="!newEntity.id">
                <f7-list-item divider :title="`New ${projectInFocusInfo.entity || 'entity'}`"></f7-list-item>
                <f7-block-title v-if="loadingAutoGeneratedId"><f7-preloader></f7-preloader></f7-block-title>
                <f7-list-input
                    v-else
                    id="newEntityId"
                    outline
                    :label="`Indicate new ${projectInFocusInfo.primaryid || 'id'} ${
                        projectInFocusInfo.autoGeneratedId ? '(Auto-generated)' : ''
                    }`"
                    :type="
                        !projectInFocusInfo.primaryidType || projectInFocusInfo.primaryidType == 'let'
                            ? 'text'
                            : 'number'
                    "
                    :value="prentityid"
                    autocomplete="off"
                    :info="
                        !projectInFocusInfo.primaryidType
                            ? 'can be alphanumeric'
                            : projectInFocusInfo.primaryidType == 'let'
                            ? 'must be letters only'
                            : 'must be an integer'
                    "
                    @input="prehcng($event.target.value)"
                    @keyup.native.enter="gonext()"
                ></f7-list-input>
                <f7-list-item divider :title="projectInFocusInfo.grouper || 'Institution'"></f7-list-item>
                <f7-list-input
                    v-if="InstitutionsICanCreateFrom.length > 1"
                    label="Select origin"
                    type="select"
                    outline
                    placeholder="Please choose..."
                    :value="newEntity.instid"
                    @input="newEntity.instid = $event.target.value"
                >
                    <option value="">
                        Select from
                        {{ projectInFocusInfo.grouper || "Institution" }}
                    </option>

                    <option v-for="institux in InstitutionsICanCreateFrom" :key="institux.id" :value="institux.id">
                        {{ institux.name }}
                    </option>
                </f7-list-input>
                <f7-list-item
                    v-else
                    header="Origin"
                    :title="projectInFocusInfo.institutions[InstitutionsICanCreateFrom[0].id].name"
                ></f7-list-item>
            </f7-list>
            <template v-else>
                <f7-row>
                    <f7-col width="100" medium="50">
                        <f7-block-title class="margin" v-if="entityDatas.length">
                            {{ projectInFocusInfo.entity || "entity" }} data
                        </f7-block-title>
                        <div v-for="preg in entityDatas" :key="preg.id">
                            <entity-card
                                class="margin-bottom borderBottom"
                                v-if="!preg.depde || canBeShown(newEntity.entityData, preg.depde)"
                                :preg="preg"
                                :localentity="newEntity.entityData[preg.id]"
                                :highlightRequired="highlightRequired"
                                @selectionaOption="selectionaOption"
                                @setDate="setDate"
                                @setprimitive="setprimitive"
                            ></entity-card>
                        </div>
                    </f7-col>
                    <f7-col width="100" medium="50">
                        <f7-link v-if="$f7.device.macos" popover-open=".popover-issues-macbook" class="margin">
                            Having trouble uploading files?
                        </f7-link>

                        <f7-popover class="popover-issues-macbook">
                            <f7-block>
                                For Macbook M1 users using Google Chrome, if file upload is not working try the
                                following:
                                <ol>
                                    <li>Go to System preferences > Security and privacy > privacy tab</li>
                                    <li>Click on the lock to make changes</li>
                                    <li>Search for Full disk access</li>
                                    <li>Click on the + button</li>
                                    <li>Search for Google Chrome and click open</li>
                                    <li>Restart Google Chrome</li>
                                </ol>
                            </f7-block>
                        </f7-popover>

                        <f7-block-title v-if="entityFiles.length" class="margin">
                            {{ projectInFocusInfo.entity || "entity" }} files
                        </f7-block-title>
                        <div v-for="preg in entityFiles" :key="preg.id">
                            <f7-card
                                class="margin-bottom borderBottom"
                                :class="{
                                    highlightRequired: highlightRequired && preg.req && !newEntity.entityFiles[preg.id],
                                }"
                            >
                                <f7-list media-list class="no-margin titlebold borbotpix">
                                    <f7-list-item>
                                        <span
                                            slot="title"
                                            v-html="
                                                `${preg.encabezado} (${
                                                    preg.tipo == 'a' ? 'any extension' : '.' + preg.tipo
                                                })`
                                            "
                                        ></span>
                                        <f7-link slot="after" v-if="preg.linfo" :tooltip="preg.linfo">
                                            <font-awesome-icon class="fa-lg" :icon="['fad', 'circle-info']" />
                                        </f7-link>
                                        <span
                                            slot="footer"
                                            :class="{
                                                'text-color-red': preg.req,
                                            }"
                                        >
                                            {{ preg.req ? "Required" : "Optional" }}
                                        </span>
                                    </f7-list-item>
                                </f7-list>
                                <f7-card-content>
                                    <f7-list class="fixfamarg" media-list>
                                        <f7-list-item
                                            v-if="!preg.many && newEntity.entityFiles[preg.id]"
                                            :title="newEntity.entityFiles[preg.id].name"
                                            :footer="`${newEntity.entityFiles[preg.id].size} KB`"
                                        >
                                            <f7-link color="gray" @click="deleteUploaded(preg.id, null)" slot="after">
                                                <font-awesome-icon :icon="['fad', 'trash']"></font-awesome-icon>
                                            </f7-link>
                                            <font-awesome-icon
                                                :icon="['fad', 'check']"
                                                slot="media"
                                                color="teal"
                                            ></font-awesome-icon>
                                        </f7-list-item>
                                        <template v-else-if="preg.many && newEntity.entityFiles[preg.id]">
                                            <f7-list-item
                                                v-for="unyp in newEntity.entityFiles[preg.id]"
                                                :key="unyp.id"
                                                :title="unyp.name"
                                                :footer="`${unyp.size} KB`"
                                            >
                                                <f7-link
                                                    color="gray"
                                                    @click="deleteUploaded(preg.id, unyp.id)"
                                                    slot="after"
                                                >
                                                    <font-awesome-icon :icon="['fad', 'trash']"></font-awesome-icon>
                                                </f7-link>
                                                <font-awesome-icon
                                                    :icon="['fad', 'check']"
                                                    slot="media"
                                                    color="teal"
                                                ></font-awesome-icon>
                                            </f7-list-item>
                                        </template>
                                        <f7-list-item
                                            v-if="uploadFocus.itemid == preg.id"
                                            :title="uploadFocus.file.name"
                                        >
                                            <f7-progressbar
                                                v-if="!processing"
                                                slot="subtitle"
                                                :progress="progress"
                                            ></f7-progressbar>
                                            <f7-progressbar v-else slot="subtitle" infinite></f7-progressbar>
                                            <f7-preloader slot="media"></f7-preloader>
                                            <f7-chip slot="after" outline :text="`${progress}%`"></f7-chip>
                                        </f7-list-item>
                                        <f7-list-item v-else-if="!newEntity.entityFiles[preg.id] || preg.many">
                                            <f7-button
                                                :class="{
                                                    disabled: uploadFocus.itemid,
                                                }"
                                                fill
                                                @click="setUploadFocus(preg)"
                                                raised
                                                color="purple"
                                                slot="after"
                                            >
                                                add new file
                                            </f7-button>
                                        </f7-list-item>
                                    </f7-list>
                                </f7-card-content>
                            </f7-card>
                        </div>
                    </f7-col>
                </f7-row>
            </template>
            <input
                type="file"
                id="filoinput"
                @change="filesChange($event)"
                :accept="uploadFocus.type == '.a' ? null : uploadFocus.type"
            />
            <f7-block>
                <f7-row>
                    <f7-col><f7-button class="cancelButton" @click="closingAttempt" large>Cancel</f7-button></f7-col>
                    <f7-col>
                        <f7-button color="teal" :class="{ disabled: !prentityid }" @click="gonext()" large raised fill>
                            {{ !newEntity.id ? "Next" : "Create" }}
                        </f7-button>
                    </f7-col>
                </f7-row>
            </f7-block>
        </f7-page>
    </f7-view>
</template>
<script>
    import { mapGetters, mapState } from "vuex";
    import { commonmixin } from "../mixins/common";
    import entityCard from "../components/entity_card.vue";
    export default {
        mixins: [commonmixin],
        props: ["InstitutionsICanCreateFrom"],
        components: { entityCard },
        data() {
            return {
                prentityid: null,
                newEntity: {
                    id: null,
                    instid: null,
                    entityFiles: {},
                    entityData: {},
                },
                uploadFocus: { type: ".pdf", file: {}, itemid: null },
                uploadMeta: {},
                progress: 0,
                processing: false,
                task: null,
                highlightRequired: null,
                unix: null,
                loadingAutoGeneratedId: false,
            };
        },
        computed: {
            ...mapState(["entityDraft"]),
            ...mapGetters(["projectInFocusInfo", "userPrivsSelectedProject"]),
            entityDatas() {
                return Object.values(this.projectInFocusInfo.entityData || {})
                    .filter((uset) => {
                        return !uset.disabled && !this.noMandatorySinAlternativas(uset);
                    })
                    .sort((a, b) => {
                        if (a.orden > b.orden) {
                            return 1;
                        } else if (a.orden < b.orden) {
                            return -1;
                        } else {
                            return 0;
                        }
                    });
            },
            entityFiles() {
                return Object.values(this.projectInFocusInfo.entityFiles || {})
                    .filter((uset) => {
                        return !uset.disabled;
                    })
                    .sort((a, b) => {
                        if (a.orden > b.orden) {
                            return 1;
                        } else if (a.orden < b.orden) {
                            return -1;
                        } else {
                            return 0;
                        }
                    });
            },
        },
        mounted() {
            if (this.projectInFocusInfo.autoGeneratedId && !this.entityDraft) this.autoGenerateId();
            if (this.entityDraft) {
                this.$f7.dialog.confirm(
                    "There is a draft saved, do you want to continue with it?",
                    () => {
                        this.prentityid = this.entityDraft.id;
                        this.newEntity = Object.assign({}, this.entityDraft);
                    },
                    () => {
                        this.$store.commit("setWhatTo", {
                            what: "entityDraft",
                            to: null,
                        });
                    },
                );
            }
            //console.log('instipot',this.InstitutionsICanCreateFrom);
            if (this.InstitutionsICanCreateFrom.length == 1) {
                this.newEntity.instid = this.InstitutionsICanCreateFrom[0].id;
            }
            const input = this.$$("#newEntityId").find("input")[0];
            if (input) {
                setTimeout(() => {
                    input.focus();
                }, 500);
            }
            //  console.log('rpoject',this.projectInFocusInfo)
        },
        methods: {
            async autoGenerateId() {
                this.loadingAutoGeneratedId = true;
                try {
                    let datous = await this.commonExecute(
                        {
                            projectId: this.projectInFocusInfo.id,
                            institutionId: Object.keys(this.userPrivsSelectedProject?.prv || {})[0] || null,
                        },
                        "v2_autoGenerateId",
                        false,
                    );
                    console.log("datous", datous);
                    if (datous.error) {
                        throw new Error(datous.error.message);
                    }
                    this.prentityid = datous.newId;
                    this.loadingAutoGeneratedId = false;
                } catch (error) {
                    console.log(error);
                    this.$f7.dialog.alert(error.message);
                    this.loadingAutoGeneratedId = false;
                }
            },
            noMandatorySinAlternativas(uset) {
                return (
                    (uset.tipo == "elige_una" || uset.tipo == "elige_many") &&
                    !uset.req &&
                    (!uset.alternativas || !Object.keys(uset.alternativas).length)
                );
            },
            hasNumber(myString) {
                return /\d/.test(myString);
            },
            prehcng(value) {
                if (
                    this.projectInFocusInfo.primaryidType &&
                    this.projectInFocusInfo.primaryidType == "num" &&
                    (value.includes(".") || value.includes(",") || !Number.isInteger(Number(value)))
                ) {
                    return this.$f7.dialog.alert(
                        `${this.projectInFocusInfo.primaryid || "id"} must be an integer, no floats allowed`,
                        "Type error",
                    );
                } else if (
                    this.projectInFocusInfo.primaryidType &&
                    this.projectInFocusInfo.primaryidType == "let" &&
                    value &&
                    (this.hasNumber(value) || !isNaN(value) || /[^a-zA-Z]/.test(value))
                ) {
                    return this.$f7.dialog.alert(
                        `${
                            this.projectInFocusInfo.primaryid || "id"
                        } must contain only letters, no numbers or other symbols are allowed`,
                        "Type error",
                    );
                } else {
                    this.prentityid = value;
                }
            },
            deleteUploaded(entityid, subkey) {
                if (subkey) {
                    this.$delete(this.newEntity.entityFiles[entityid], subkey);
                } else {
                    this.$delete(this.newEntity.entityFiles, entityid);
                }
            },
            async setUploadFocus(preg) {
                try {
                    //console.log('tratndo de subir',preg);
                    this.uploadFocus.type = `.${preg.tipo}`;

                    this.$f7.dialog.preloader("Requesting auth...");
                    let datous = await this.commonExecute(
                        {
                            projectid: this.projectInFocusInfo.id,
                            targetid: this.newEntity.id,
                            itemid: preg.id,
                        },
                        "v2_dameTokenPara",
                        false,
                    );
                    if (datous.error) {
                        throw new Error(datous.error.message);
                    }
                    //  console.log(datous.payload);
                    this.uploadMeta = Object.assign({}, datous.payload);
                    await this.$firebase.auth().currentUser.reload();
                    let user = this.$firebase.auth().currentUser;
                    await user.getIdToken(true);

                    this.$f7.dialog.close();
                    this.$$("#filoinput").click();
                } catch (error) {
                    this.$f7.dialog.close();
                    console.error(error.code, error.message);
                    this.$f7.dialog.alert(error.message, error.code || "Error");
                }
            },
            filesChange(e) {
                let file = e.target.files[0];
                if (file) {
                    // console.log(file)
                    this.checkINameExists(file);
                }
            },
            alsnapshot(snapshot) {
                let percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                let nentero = Math.round(percentage);
                if (!isNaN(nentero)) {
                    this.progress = nentero;
                }
                //  console.log('snapshot',this.uploadFocus.file);
            },
            alerror(err) {
                console.log(err.code);
                this.progress = 0;
                this.uploadMeta = Object.assign({});
                this.uploadFocus.itemid = null;
                this.$f7.dialog.alert(err.code, "upload error");
            },
            altermino(filename) {
                this.progress = 100;
                this.processing = true;
                let fileid = this.uploadMeta.imn.split(".").shift();
                let tosave = {
                    name: this.uploadFocus.file.name,
                    size: this.uploadFocus.file.size,
                    url: `/neocore/${this.$firebase.auth().currentUser.uid}/${this.projectInFocusInfo.id}/${
                        this.uploadMeta.tid
                    }/${this.unix}/${filename}`,
                    id: fileid,
                };
                if (this.projectInFocusInfo.entityFiles[this.uploadMeta.itemid].many) {
                    if (!this.newEntity.entityFiles[this.uploadMeta.itemid]) {
                        this.$set(this.newEntity.entityFiles, this.uploadMeta.itemid, {});
                    }
                    this.$set(this.newEntity.entityFiles[this.uploadMeta.itemid], fileid, tosave);
                } else {
                    this.$set(this.newEntity.entityFiles, this.uploadMeta.itemid, tosave);
                }
                this.processing = false;
                this.uploadMeta = Object.assign({});
                this.uploadFocus.itemid = null;
                this.unix = null;
            },
            checkINameExists(filereceive) {
                let filename = filereceive.name;
                let nameExists = this.entityFiles.some((file) => {
                    file.name == filename;
                });
                if (nameExists) {
                    return this.$f7.dialog.confirm(
                        "There is already a file with this name, do you want to upload it anaways?",
                        () => {
                            this.subirArchivox(filereceive);
                        },
                    );
                } else {
                    this.subirArchivox(filereceive);
                }
            },
            subirArchivox(filereceive) {
                let filename = filereceive.name;
                this.uploadFocus.file = Object.assign(
                    {},
                    {
                        name: filename,
                        size: (filereceive.size / 1024).toFixed(1),
                    },
                );
                // console.log('fresh seteandofile',this.uploadFocus.file,filereceive);
                let extenx = filename.split(".").pop().toLowerCase();
                let filexpetedSplitted = this.uploadMeta.imn.split(".");
                let extenpermit = filexpetedSplitted.pop();

                document.getElementById("filoinput").value = "";
                if (extenpermit !== "a" && extenx !== extenpermit) {
                    return this.$f7.dialog.alert(`File extension missmatch (required ${extenpermit})`);
                } else {
                    this.uploadFocus.itemid = this.uploadMeta.itemid;
                    this.progress = 0;
                    //  console.log('SUBIENDO A',`neocore/${this.$firebase.auth().currentUser.uid}/${this.uploadMeta.pid}/${this.uploadMeta.tid}/${this.uploadMeta.imn}`)
                    if (extenpermit == "a") {
                        filexpetedSplitted.push(extenx);
                        this.uploadMeta.imn = filexpetedSplitted.join(".");
                    }
                    //console.log('FILE RECEIVNES ES',filereceive,this.uploadMeta.imn)

                    this.unix = this.$dayjs().unix();

                    this.task = this.$firebase
                        .storage()
                        .ref(
                            `/neocore/${this.$firebase.auth().currentUser.uid}/${this.projectInFocusInfo.id}/${
                                this.uploadMeta.tid
                            }/${this.unix}/${filename}`,
                        )
                        .put(filereceive);
                    //  console.log('seteandofile',this.uploadFocus.file,filereceive);
                    this.task.on("state_changed", this.alsnapshot, this.alerror, this.altermino(filename));
                }
            },
            selectionaOption({ pregid, altid, checked }) {
                if (!this.newEntity.entityData[pregid]) {
                    this.$set(this.newEntity.entityData, pregid, {});
                }
                this.$set(this.newEntity.entityData[pregid], altid, checked);
            },
            setDate({ pregid, fechfix }) {
                this.$set(this.newEntity.entityData, pregid, fechfix);
                console.log("this.newEntity.entityData", this.newEntity.entityData);
            },
            setprimitive({ pregid, value }) {
                this.$set(this.newEntity.entityData, pregid, value);
                if (!value) delete this.newEntity.entityData[pregid];
            },
            async gonext() {
                try {
                    //  console.log('going next')
                    if (!this.newEntity.id) {
                        if (!this.newEntity.instid) {
                            return this.$f7.dialog.alert("Please specify institution of origin", "Missing Institution");
                        }
                        this.$f7.dialog.preloader("Validating...");
                        // console.log('verygin if entity existes',this.prentityid);
                        let datous = await this.commonExecute(
                            {
                                projectid: this.projectInFocusInfo.id,
                                entityid: this.prentityid,
                            },
                            "v2_checkEntityExists",
                            false,
                        );
                        if (datous.error) {
                            throw new Error(datous.error.message);
                        }
                        this.$f7.dialog.close();
                        let preinstitution = datous.payload;
                        if (preinstitution) {
                            return this.$f7.dialog.alert(
                                `${this.projectInFocusInfo.entity || "Entity"} ${
                                    this.prentityid
                                } is already created at ${this.projectInFocusInfo.institutions[preinstitution].name}.`,
                                `${this.projectInFocusInfo.entity || "Entity"}  already exists`,
                            );
                        } else {
                            this.newEntity.id = this.prentityid;
                        }
                    } else {
                        let faltaData = this.entityDatas.some((unentity) => {
                            return (
                                !unentity.disabled &&
                                unentity.req &&
                                (!this.newEntity?.entityData?.[unentity.id] ||
                                    (unentity.tipo == "elige_many" &&
                                        !Object.values(this.newEntity?.entityData?.[unentity.id]).some((alt) => {
                                            return alt;
                                        }))) &&
                                (!unentity.depde || this.canBeShown(this.newEntity?.entityData, unentity.depde))
                            );
                        });
                        if (faltaData) {
                            this.highlightRequired = true;
                            return this.$f7.dialog.alert("Please complete all required data fields", "Missing data");
                        }
                        let faltanFiles = this.entityFiles.some((unentity) => {
                            return !unentity.disabled && unentity.req && !this.newEntity.entityFiles[unentity.id];
                        });
                        if (faltanFiles) {
                            this.highlightRequired = true;
                            return this.$f7.dialog.alert(
                                "Make sure you uploaded all required data files",
                                "Missing files",
                            );
                        }

                        this.$f7.dialog.preloader("Creating...");
                        let datous = await this.commonExecute(
                            {
                                projectid: this.projectInFocusInfo.id,
                                entity: this.newEntity,
                            },
                            "v2_createEntity",
                            false,
                        );
                        if (datous.error) {
                            throw new Error(datous.error.message);
                        }
                        // console.log(datous.payload)
                        this.$f7.dialog.close();
                        this.$emit("generated", datous.payload);
                        this.$store.commit("setWhatTo", {
                            what: "entityDraft",
                            to: null,
                        });
                    }
                } catch (error) {
                    this.$f7.dialog.close();
                    console.error(error);
                    this.$f7.dialog.alert(error.message, error.code);
                }
            },
            closingAttempt() {
                if (!this.newEntity.id) return this.$emit("cierra");
                this.$f7.dialog
                    .create({
                        text: "Do you want to save your progress as draft?",
                        buttons: [
                            {
                                text: "NO",
                                onClick: () => {
                                    this.$store.commit("setWhatTo", {
                                        what: "entityDraft",
                                        to: null,
                                    });
                                    this.$emit("cierra");
                                },
                            },
                            {
                                text: "YES",
                                onClick: () => {
                                    this.$store.commit("setObjTo", {
                                        what: "entityDraft",
                                        to: this.newEntity,
                                    });
                                    this.$emit("cierra");
                                },
                            },
                        ],
                    })
                    .open();
            },
        },
    };
</script>
<style>
    #filoinput {
        display: none;
    }
    .borbotpix {
        border-bottom: 1px dotted gray;
    }
    .borderBottom {
        border-bottom: solid 1px gray;
    }
</style>
