async function userHasPrivileges({ userId, projectId, requiredAdminPrivs, requiredInstitutionPrivs, db }) {
    // IMPORTANT: This function does not check PHI privileges, use isUserAllowedToSeePHIData() instead
    const superAdminSnap = await db.ref(`privx/${userId}/admin`).once('value');

    if (superAdminSnap.exists()) {
        return true;
    }

    const privsSnapshot = await db.ref(`privsByProject/${userId}${projectId}`).once('value');
    const userPrivs = privsSnapshot.val();
    const adminPrivs = Object.keys(userPrivs.admin || {});

    if (!userPrivs) {
        return false;
    }

    if (requiredAdminPrivs) {
        if (userPrivs.admin) {
            return requiredAdminPrivs.every((priv) => {
                if (priv === 'accounts') {
                    return adminPrivs.includes('project-admin') || adminPrivs.includes('accounts');
                }

                return adminPrivs.includes(priv);
            });
        }
    } else if (requiredInstitutionPrivs) {
        if (userPrivs.prv) {
            const userInstitutionId = Object.keys(userPrivs.prv)[0];

            if (!userInstitutionId) {
                return false;
            }

            const institutionPrivs = Object.keys(userPrivs.prv[userInstitutionId].det || {});

            return requiredInstitutionPrivs.every((priv) => {
                // Create and update privileges were merged into one, so if the user has one of them, we let it pass
                if (priv === 'create' || priv === 'update') {
                    return institutionPrivs.includes('create') || institutionPrivs.includes('update');
                } else if (priv === 'read') {
                    return true;
                }

                return institutionPrivs.includes(priv);
            });
        }
    }

    return false;
}

async function isUserAllowedToSeePHIData({ userId, projectId, entities, db }) {
    // Get user privileges
    const privsSnapshot = await db.ref(`privsByProject/${userId}${projectId}`).once('value');
    const userPrivs = privsSnapshot.val();

    if (!userPrivs) return false;

    if (userPrivs.admin && userPrivs.admin.phi) {
        return true;
    }

    // Get project
    const projectSnapshot = await db.ref(`projects/${projectId}`).once('value');
    const project = projectSnapshot.val();

    if (!project) return false;

    const entityHasPHIData =
        Object.values(project.entityData)
            .filter((ed) => !ed.disabled)
            .some((e) => !e.notPhi) &&
        Object.values(project.entityFiles)
            .filter((ef) => !ef.disabled)
            .some((e) => !e.notPhi);

    if (!entityHasPHIData) return true;

    // Get entities
    const entitiesSnapshot = await Promise.all(
        entities.map((entityId) => db.ref(`paxsByProject/${projectId}/${entityId}`).once('value'))
    );
    const entitiesData = entitiesSnapshot.map((snapshot) => snapshot.val());

    const userInstitution = Object.keys(userPrivs.prv)[0];

    return entitiesData.every((entity) => entity.instid === userInstitution);
}

async function isEntityFieldPHI({ fieldId, projectId, isFile = false, isData = false, db }) {
    if (!isFile && !isData) {
        throw new Error('Either isFile or isData must be true');
    }
    if (!fieldId || !projectId) {
        throw new Error('fieldId and projectId are required');
    }

    const fieldRef = isFile
        ? `projects/${projectId}/entityFiles/${fieldId}`
        : `projects/${projectId}/entityData/${fieldId}`;
    const fieldSnapshot = await db.ref(fieldRef).once('value');

    return fieldSnapshot.exists() && !fieldSnapshot.val().notPhi;
}

async function hasEntitiesFromAnotherInstitutions({ userId, projectId, entities = [], db }) {
    const privsSnapshot = await db.ref(`privsByProject/${userId}${projectId}`).once('value');
    const userPrivs = privsSnapshot.val();

    const userInstitution = Object.keys(userPrivs.prv || {})[0];

    if (!userInstitution) {
        return false;
    }

    const snapshots = await Promise.all(
        entities.map((entityId) => db.ref(`paxsByProject/${projectId}/${entityId}/instid`).once('value'))
    );

    return snapshots.some((snapshot) => snapshot.exists() && snapshot.val() !== userInstitution);
}

async function isMaintenanceModeEnabled({ db }) {
    const snapshot = await db.ref('cieloConfigurations/isMaintenanceModeEnabled').once('value');
    return snapshot.exists() && snapshot.val() === true;
}

module.exports = {
    userHasPrivileges,
    isUserAllowedToSeePHIData,
    isEntityFieldPHI,
    hasEntitiesFromAnotherInstitutions,
    isMaintenanceModeEnabled,
};
