<template>
    <f7-page
        @page:beforein="getEntities({})"
        :infinite="infiniteScroll"
        @infinite="getNextEntities"
        @page:beforeout="infiniteScroll = false"
        name="projectdata"
    >
        <f7-navbar>
            <f7-nav-left>
                <f7-link icon-material="menu" panel-open="left"></f7-link>
            </f7-nav-left>
            <f7-nav-title subtitle="Manage &amp; download" title="Project Data"></f7-nav-title>
            <f7-nav-right>
                <f7-block class="no-padding-horizontal">
                    <f7-link @click="showInactive = !showInactive" tooltip="Toggle inactive entities visibility">
                        <font-awesome-icon v-if="!showInactive" :icon="['fad', 'eye-slash']"></font-awesome-icon>
                        <font-awesome-icon v-else :icon="['fad', 'eye']"></font-awesome-icon>
                    </f7-link>
                </f7-block>
                <f7-block
                    v-if="(myPrivsHere.admin && myPrivsHere.admin['project-admin']) || suprivx.admin"
                    class="no-padding-horizontal"
                >
                    <f7-link @click="goToDeletedEntities()" tooltip="See deleted entities">
                        <font-awesome-icon :icon="['fad', 'user-slash']"></font-awesome-icon>
                    </f7-link>
                </f7-block>
                <f7-block
                    v-if="userHasDownloadPrivilegeInInstitution || userHasDownloadPrivilegeInProject"
                    class="no-padding-horizontal"
                >
                    <f7-link
                        :tooltip="entitiesSelected.length ? 'Download selected' : 'Select entities to download'"
                        @click="decargameselected()"
                    >
                        <font-awesome-icon
                            :color="entitiesSelected.length ? 'teat' : 'gray'"
                            :icon="['fad', 'cloud-arrow-down']"
                        ></font-awesome-icon>
                    </f7-link>
                </f7-block>
                <f7-block
                    v-if="userHasDownloadPrivilegeInInstitution || userHasDownloadPrivilegeInProject"
                    class="no-padding-horizontal"
                >
                    <f7-link tooltip="See entities bundles download history" @click="navigateToBundlesHistory">
                        <font-awesome-icon :icon="['fad', 'clock-rotate-left']"></font-awesome-icon>
                        <f7-badge v-if="bundlesNotDownloaded > 0" class="margin-right bundleBadge" color="yellow">
                            <p class="newBundle">{{ bundlesNotDownloaded }}</p>
                        </f7-badge>
                    </f7-link>
                </f7-block>
                <f7-block class="no-padding-horizontal">
                    <f7-link tooltip="Refresh" @click="refreshEntities()">
                        <font-awesome-icon :icon="['fad', 'rotate']"></font-awesome-icon>
                    </f7-link>
                </f7-block>
            </f7-nav-right>
            <f7-subnavbar v-if="InstitutionsICanCreateFrom.length && projectInFocusInfo.published">
                <f7-segmented raised>
                    <f7-button @click="popupOpened = true" tab-link-active>
                        Add New {{ projectInFocusInfo.entity || "entity" }}
                    </f7-button>
                </f7-segmented>
            </f7-subnavbar>
        </f7-navbar>
        <f7-progressbar v-if="loading" infinite></f7-progressbar>

        <f7-block v-if="!projectInFocusInfo.published" class="isreadonlyx no-margin">
            This project is inactive: it's no longer collecting new data.
        </f7-block>

        <f7-block class="no-margin-vertical">
            <span v-if="loading">Loading entities...</span>
            <span v-else>
                Exploring {{ dinalist.length }} {{ dinalist.length == 1 ? "entity" : "entities" }}.
                {{ showInactive ? "(Including inactive entities)" : "" }}
            </span>
            <br />
            <small
                v-if="
                    projectInFocusInfo.isDeidentificationEnabled &&
                    (!userHasPHIAdminPrivilege || !userHasPHIInstitutionPrivilege)
                "
            >
                De-identification is enabled for this project.
                <b class="text-color-red">Entity IDs with red asterisks (*) are de-identified</b>
                .
            </small>
        </f7-block>
        <div class="data-table unsetxoverflow card no-margin">
            <table class="lesticky">
                <thead class="projectDataThead">
                    <tr class="text-align-center">
                        <th
                            v-if="userHasDownloadPrivilegeInInstitution || userHasDownloadPrivilegeInProject"
                            class="ledays"
                        >
                            <f7-checkbox
                                :disabled="!thereIsAtLeastOneDownloadableEntityBundle"
                                @change="checkAllEntiSelected"
                                color="purple"
                                :indeterminate="!allvisibleSelected && entitiesSelected.length > 0"
                                :checked="allvisibleSelected"
                            ></f7-checkbox>
                        </th>
                        <th
                            class="ledays label-cell no-padding-left holdea"
                            :class="{ 'no-padding-horizontal': searchIDON }"
                        >
                            <template v-if="!searchIDON">
                                <f7-link @click="searchOneEntity">
                                    <font-awesome-icon
                                        class="fa-lg"
                                        :icon="['fad', 'magnifying-glass']"
                                    ></font-awesome-icon>
                                </f7-link>
                                {{ projectInFocusInfo.primaryid || "id" }}
                                <f7-link @click="setsort('id', null)" :color="sortby.col == 'id' ? 'teal' : 'gray'">
                                    <font-awesome-icon
                                        class="fa-lg"
                                        :icon="
                                            sortby.col == 'id'
                                                ? sortby.asc
                                                    ? ['fad', 'sort-up']
                                                    : ['fad', 'sort-down']
                                                : ['fad', 'sort']
                                        "
                                    ></font-awesome-icon>
                                </f7-link>
                            </template>
                            <f7-list v-else no-hairlines-md class="no-margin">
                                <f7-list-input
                                    type="text"
                                    name="noatcomplete"
                                    id="nocomplete"
                                    input-id="elsearher"
                                    outline
                                    class="srchinpute no-padding-left"
                                    autocomplete="off"
                                    :placeholder="`Search ${projectInFocusInfo.primaryid || 'id'}`"
                                    clear-button
                                    :value="queryentytiid"
                                    @input="queryentytiid = $event.target.value"
                                >
                                    <f7-link slot="media" @click="inactivaSearch()">
                                        <font-awesome-icon
                                            class="fa-lg"
                                            :icon="['fad', 'circle-xmark']"
                                        ></font-awesome-icon>
                                    </f7-link>
                                </f7-list-input>
                            </f7-list>
                        </th>
                        <th class="ledays label-cell">
                            {{ projectInFocusInfo.grouper || "Institutions" }}
                            <f7-link
                                v-if="userHasReadPrivilegeInProject && userCanFilter && institofiltered.length"
                                popover-open=".filterinstitutions"
                            >
                                <f7-icon material="filter_list">
                                    <f7-badge v-if="selectedInstitutions.length" color="red">
                                        {{ selectedInstitutions.length }}
                                    </f7-badge>
                                </f7-icon>
                            </f7-link>
                        </th>
                        <th class="ledays">
                            Created at
                            <f7-link
                                @click="setsort('createdAd', null)"
                                :color="sortby.col == 'createdAd' ? 'teal' : 'gray'"
                            >
                                <font-awesome-icon
                                    class="fa-lg"
                                    :icon="
                                        sortby.col == 'createdAd'
                                            ? sortby.asc
                                                ? ['fad', 'sort-up']
                                                : ['fad', 'sort-down']
                                            : ['fad', 'sort']
                                    "
                                ></font-awesome-icon>
                            </f7-link>
                        </th>
                        <th class="ledays label-cell">
                            All files
                            <f7-link
                                @click="setsort('allfiles', null)"
                                :color="sortby.col == 'allfiles' ? 'teal' : 'gray'"
                            >
                                <font-awesome-icon
                                    class="fa-lg"
                                    :icon="
                                        sortby.col == 'allfiles'
                                            ? sortby.asc
                                                ? ['fad', 'sort-up']
                                                : ['fad', 'sort-down']
                                            : ['fad', 'sort']
                                    "
                                ></font-awesome-icon>
                            </f7-link>
                            <br />
                            <span v-if="!visiblFiles.length">(No Files visible)</span>
                        </th>
                        <th v-for="unadat in visiblData" :key="unadat.id" class="ledays label-cell">
                            <f7-list media-list>
                                <f7-list-item>
                                    <div slot="title" class="maxWidth250 font12">{{ unadat.encabezado }}</div>
                                    <f7-link
                                        slot="after"
                                        v-if="
                                            (unadat.tipo == 'elige_many' || unadat.tipo == 'elige_una') && userCanFilter
                                        "
                                        @click="
                                            setpopoverContent({
                                                target: $event.target,
                                                dataid: unadat.id,
                                            })
                                        "
                                    >
                                        <f7-icon material="filter_list">
                                            <f7-badge
                                                v-if="
                                                    filterByPatDataCol[unadat.id] &&
                                                    filterByPatDataCol[unadat.id].length
                                                "
                                                color="red"
                                            >
                                                {{ filterByPatDataCol[unadat.id].length }}
                                            </f7-badge>
                                        </f7-icon>
                                    </f7-link>
                                </f7-list-item>
                            </f7-list>
                        </th>
                        <th v-for="unadat in visiblFiles" :key="unadat.id" class="ledays label-cell">
                            <f7-list>
                                <f7-list-item>
                                    <div slot="title" class="maxWidth250 font12">{{ unadat.encabezado }}</div>
                                    <f7-link
                                        slot="after"
                                        v-if="!unadat.req"
                                        @click="setsort(unadat.id, 'entityFiles')"
                                        :color="sortby.col == unadat.id ? 'teal' : 'gray'"
                                    >
                                        <font-awesome-icon
                                            class="fa-lg"
                                            :icon="
                                                sortby.col == unadat.id
                                                    ? sortby.asc
                                                        ? ['fad', 'sort-up']
                                                        : ['fad', 'sort-down']
                                                    : ['fad', 'sort']
                                            "
                                        ></font-awesome-icon>
                                    </f7-link>
                                </f7-list-item>
                            </f7-list>
                        </th>
                    </tr>
                </thead>

                <tbody class="bg-color-white">
                    <tr
                        v-for="unenti in dinalist"
                        :key="unenti.id"
                        class="text-align-center"
                        :class="unenti.isDeidentified ? 'bg-deidentified' : 'bg-color-white'"
                    >
                        <td v-if="userHasDownloadPrivilegeInInstitution || userHasDownloadPrivilegeInProject">
                            <f7-checkbox
                                :disabled="!userCanDownloadEntityBundle(unenti)"
                                :value="unenti.id"
                                :checked="entitiesSelected.indexOf(unenti.id) >= 0"
                                @change="checkEntiSelected"
                            ></f7-checkbox>
                        </td>
                        <td class="label-cell">
                            <div :class="unenti.referenceEntity ? 'display-flex flex-direction-row' : ''">
                                <f7-button
                                    @click="goToEntity(unenti)"
                                    raised
                                    :color="unenti.inactive ? 'red' : 'teal'"
                                    class="no-margin noupper"
                                    style="width: 100%"
                                >
                                    {{ unenti.displayid }}
                                    <span class="text-color-red" v-if="unenti.isDeidentified">*</span>
                                </f7-button>
                                <f7-button
                                    v-if="unenti.referenceEntity"
                                    fill
                                    class="margin-left-half"
                                    style="width: 50px"
                                    @click="handleViewReference(unenti)"
                                    tooltip="View referenced entity"
                                >
                                    <font-awesome-icon :icon="['fad', 'link']"></font-awesome-icon>
                                </f7-button>
                            </div>
                        </td>
                        <td class="label-cell font11">
                            {{ getEntityInstitutionName(unenti) }}
                        </td>
                        <td>
                            {{ createdAtTimezone(projectInFocusInfo, unenti) }}
                            <br />
                            <span class="font11">{{ createdAtUTC(projectInFocusInfo, unenti) }}</span>
                        </td>
                        <td class="label-cell">
                            <font-awesome-icon
                                v-if="tieneAllfiles(unenti.entityFiles)"
                                class="text-color-teal"
                                :icon="['fad', 'check-double']"
                            ></font-awesome-icon>
                            <span v-else>-</span>
                        </td>
                        <td v-for="unadat in visiblData" :key="unadat.id" class="label-cell">
                            {{
                                unenti.entityData &&
                                unenti.entityData[unadat.id] &&
                                (!unadat.depde || canBeShown(unenti.entityData, unadat.depde))
                                    ? visualizeAnswer(unenti, unadat.id)
                                    : ""
                            }}
                        </td>
                        <td v-for="unadat in visiblFiles" :key="unadat.id" class="label-cell">
                            <font-awesome-icon
                                v-if="unenti.entityFiles && unenti.entityFiles[unadat.id]"
                                :icon="['fad', 'check']"
                            ></font-awesome-icon>
                        </td>
                    </tr>
                    <tr v-if="!loading && !allentities.length">
                        <td class="backgroundColorWhite" colspan="100">No data</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <f7-popup class="fullScreen" :opened="popupOpened" @popup:closed="popupOpened = false">
            <new-entity
                :InstitutionsICanCreateFrom="InstitutionsICanCreateFrom"
                @cierra="popupOpened = false"
                @generated="generado"
                v-if="popupOpened"
            ></new-entity>
        </f7-popup>

        <f7-popover class="filterinstitutions" @popover:closed="getFilteredEntities()">
            <f7-list>
                <f7-list-item
                    v-for="institution in institofiltered"
                    :key="institution.id"
                    @change="filterByField($event, 'institution')"
                    checkbox
                    :value="institution.id"
                    popover-close
                    :title="institution.name"
                ></f7-list-item>
            </f7-list>
        </f7-popover>

        <f7-popover class="filterPatDataStructured" @popover:closed="getFilteredEntities()">
            <f7-list class="filterloclpop">
                <f7-list-item
                    v-for="unaoptx in listToFilterToShow"
                    :key="unaoptx.id"
                    @change="filterByField($event, filterByPatDatOpened)"
                    :checked="
                        filterByPatDataCol[filterByPatDatOpened] &&
                        filterByPatDataCol[filterByPatDatOpened].includes(unaoptx.id)
                    "
                    checkbox
                    :value="unaoptx.id"
                    popover-close
                    :title="unaoptx.tx"
                ></f7-list-item>
            </f7-list>
        </f7-popover>
        <f7-popup :opened="deletedEntitiesPopup" @popup:closed="deletedEntitiesPopup = false">
            <f7-page>
                <f7-navbar>
                    <f7-nav-title title="Deleted Entities"></f7-nav-title>
                    <f7-nav-right><f7-link popup-close>Close</f7-link></f7-nav-right>
                </f7-navbar>
                <f7-searchbar
                    search-container=".deletedEntities-search-list"
                    search-in=".item-title"
                    placeholder="Search entity"
                ></f7-searchbar>
                <f7-block v-if="deletedEntitiesPreloader" class="text-align-center">
                    <f7-preloader></f7-preloader>
                    <br />
                    Loading deleted entities...
                </f7-block>
                <f7-list class="searchbar-not-found no-margin-top">
                    <f7-list-item title="Nothing found"></f7-list-item>
                </f7-list>
                <f7-list v-if="!deletedEntities.length">
                    <f7-list-item>There are no deleted entities</f7-list-item>
                </f7-list>
                <f7-list v-else media-list class="deletedEntities-search-list no-margin-top">
                    <f7-list-item
                        v-for="(entity, i) in deletedEntities"
                        :key="i"
                        :title="entity.id"
                        :subtitle="`Created on ${$dayjs.unix(entity.createdAd).format('MM-DD-YYYY')}`"
                        :after="`Institution: ${
                            projectInFocusInfo &&
                            projectInFocusInfo.institutions &&
                            projectInFocusInfo.institutions[entity.instid]
                                ? projectInFocusInfo.institutions[entity.instid].name
                                : ''
                        }`"
                    ></f7-list-item>
                </f7-list>
            </f7-page>
        </f7-popup>

        <f7-popup class="fullScreen" :opened="isReferencePopupOpened" @popup:closed="isReferencePopupOpened = false">
            <f7-page>
                <f7-navbar>
                    <f7-nav-title
                        :title="`Viewing referenced entity ID: ${
                            referenceEntityFocused ? referenceEntityFocused.displayid : 'Unknown'
                        }`"
                    ></f7-nav-title>
                    <f7-nav-right><f7-link popup-close>Close</f7-link></f7-nav-right>
                </f7-navbar>

                <template v-if="referenceProjectFocused && referenceEntityFocused">
                    <f7c-alert type="info" class="margin">
                        This entity is a reference from project
                        <b>{{ referenceProjectFocused.name }}</b>
                        with
                        <b>ID {{ referenceEntityFocused.displayid }}</b>
                        . This data is
                        <b>read-only</b>
                        . If you need to edit this entity, please go to the original project .
                    </f7c-alert>

                    <f7c-alert v-if="referenceEntityFocused.isDeidentified" type="info" class="margin">
                        This entity was
                        <b>de-identified</b>
                        . You can only view non-sensitive data.
                    </f7c-alert>

                    <f7-row>
                        <f7-col>
                            <f7-block-title>Entity Data</f7-block-title>
                            <f7-list>
                                <f7-list-item
                                    v-for="data in filterInactives(referenceProjectFocused.entityData)"
                                    :key="data.id"
                                    :title="data.encabezado"
                                    :after="visualizeAnswer(referenceEntityFocused, data.id, referenceProjectFocused)"
                                ></f7-list-item>
                            </f7-list>
                        </f7-col>
                        <f7-col>
                            <div v-if="referenceEntityFocused.entityFiles">
                                <f7-block-title>Entity Files</f7-block-title>
                                <f7-list>
                                    <f7-list-item
                                        media-list
                                        v-for="fileField in referenceProjectFocused.entityFiles"
                                        :key="fileField.id"
                                    >
                                        <b slot="title">{{ fileField.encabezado }}</b>
                                        <template v-if="fileField.many">
                                            <div
                                                slot="subtitle"
                                                v-for="file in Object.values(
                                                    referenceEntityFocused.entityFiles[fileField.id] || {},
                                                )"
                                            >
                                                <div v-if="file.hiddenDueToPHI"></div>
                                                <div
                                                    v-else
                                                    class="display-flex flex-direction-row justify-content-space-between"
                                                >
                                                    <div>{{ file.name }}</div>
                                                    <div v-if="userCanDownloadFilesInReferenceProject">
                                                        <f7-link
                                                            @click="
                                                                getDownloadUrl(fileField, file.id, file.name, {
                                                                    itemid: referenceProjectFocused.entityFiles[
                                                                        fileField.id
                                                                    ].id,
                                                                    fileid: fileField.id,
                                                                })
                                                            "
                                                        >
                                                            <font-awesome-icon
                                                                :icon="['fad', 'download']"
                                                            ></font-awesome-icon>
                                                        </f7-link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                v-if="
                                                    !Object.values(
                                                        referenceEntityFocused.entityFiles[fileField.id] || {},
                                                    ).length
                                                "
                                                slot="subtitle"
                                            >
                                                No files uploaded
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div slot="subtitle">
                                                <div
                                                    v-if="
                                                        referenceEntityFocused.entityFiles &&
                                                        referenceEntityFocused.entityFiles[fileField.id]
                                                    "
                                                    class="display-flex flex-direction-row justify-content-space-between"
                                                >
                                                    <div
                                                        v-if="
                                                            referenceEntityFocused.entityFiles[fileField.id]
                                                                .hiddenDueToPHI
                                                        "
                                                    ></div>
                                                    <template v-else>
                                                        <div>
                                                            {{ referenceEntityFocused.entityFiles[fileField.id].name }}
                                                        </div>
                                                        <div v-if="userCanDownloadFilesInReferenceProject">
                                                            <f7-link
                                                                @click="
                                                                    getDownloadUrl(
                                                                        fileField,
                                                                        null,
                                                                        referenceEntityFocused.entityFiles[fileField.id]
                                                                            .name,
                                                                        {
                                                                            itemid: referenceProjectFocused.entityFiles[
                                                                                fileField.id
                                                                            ].id,
                                                                            fileid: fileField.id,
                                                                        },
                                                                    )
                                                                "
                                                            >
                                                                <font-awesome-icon
                                                                    :icon="['fad', 'download']"
                                                                ></font-awesome-icon>
                                                            </f7-link>
                                                        </div>
                                                    </template>
                                                </div>
                                                <div v-else>No file uploaded</div>
                                            </div>
                                        </template>
                                    </f7-list-item>
                                </f7-list>
                            </div>
                        </f7-col>
                    </f7-row>
                </template>
            </f7-page>
        </f7-popup>
    </f7-page>
</template>
<script>
    import { mapGetters, mapState } from "vuex";
    import newEntity from "../components/new_entity.vue";
    import LockedContentPHI from "../components/LockedContentPHI.vue";
    import { commonmixin } from "../mixins/common";

    export default {
        mixins: [commonmixin],
        components: { newEntity, LockedContentPHI },
        data() {
            return {
                infiniteScroll: true,
                queryentytiid: "",
                searchIDON: false,
                popupOpened: false,
                loading: false,
                entitiesSelected: [],
                selectedInstitutions: [],
                sortby: {
                    col: null,
                    asc: true,
                    subprop: null,
                },
                filterByPatDataCol: {},
                filterByPatDatOpened: null,
                filterByPatDataSearch: "",
                showInactive: false,

                filterBy: [],
                sortBy: "createdAd",
                orderDirection: "ASC",
                limit: 40,

                areAllEntitiesFetched: false,
                ENTITIES_PER_PAGE: 40,
                deletedEntities: [],
                deletedEntitiesPopup: false,
                deletedEntitiesPreloader: false,

                referenceEntityFocused: null,
                referenceProjectFocused: null,
                isReferencePopupOpened: false,
            };
        },
        computed: {
            ...mapState([
                "user",
                "projectInFocus",
                "allentities",
                "lastEntityId",
                "shouldForceRefreshEntities",
                "institofil",
                "goToProyectDataFromMenu",
                "bundlesHistory",
            ]),
            ...mapGetters(["projectInFocusInfo", "userHasPrivileges", "userPrivsSelectedProject"]),
            suprivx() {
                return this.user.privx || {};
            },
            myPrivsHere() {
                let allprivs = this.user.privsByProject || {};
                return allprivs[this.$store.state.projectInFocus] || {};
            },
            InstitutionsICanCreateFrom() {
                if (this.myPrivsHere?.admin && this.myPrivsHere?.admin?.full) {
                    return Object.values(this.projectInFocusInfo.institutions).filter((uninst) => {
                        return !uninst.inactive;
                    });
                } else if (this.myPrivsHere?.prv) {
                    let cuales = Object.values(this.myPrivsHere.prv).filter((potinsti) => {
                        return (
                            potinsti.det &&
                            (potinsti.det.create || potinsti.det.update) &&
                            !this.projectInFocusInfo.institutions[potinsti.id].inactive
                        );
                    });
                    return cuales;
                } else {
                    return [];
                }
            },
            visiblData() {
                return this.projectInFocusInfo.entityData ? this.onlyvisible(this.projectInFocusInfo.entityData) : [];
            },
            visiblFiles() {
                return this.projectInFocusInfo.entityFiles ? this.onlyvisible(this.projectInFocusInfo.entityFiles) : [];
            },
            dinalist() {
                let prliestalalista = this.allentities?.filter((unaentityx) => {
                    return (
                        (this.showInactive || !unaentityx.inactive) &&
                        (!this.queryentytiid || unaentityx.displayid.toLowerCase().includes(this.queryentytiid)) &&
                        (!this.selectedInstitutions.length || this.selectedInstitutions.includes(unaentityx.instid)) &&
                        !this.projectInFocusInfo?.institutions?.[unaentityx?.instid]?.noVisible
                    );
                });
                let potfiltros = Object.keys(this.filterByPatDataCol);
                let lalista = prliestalalista;
                if (potfiltros.length) {
                    lalista = prliestalalista.filter((unaentityx) => {
                        return potfiltros.every((unaopx) => {
                            if (this.projectInFocusInfo.entityData[unaopx].tipo == "elige_una") {
                                return this.filterByPatDataCol[unaopx].includes(unaentityx.entityData?.[unaopx]);
                            } else if (this.projectInFocusInfo.entityData[unaopx].tipo == "elige_many") {
                                return this.filterByPatDataCol[unaopx].some((unat) => {
                                    return unaentityx.entityData?.[unaopx] && unaentityx.entityData?.[unaopx][unat];
                                });
                            }
                        });
                    });
                }
                if (this.sortby.col) {
                    let respos = this.sortby.asc ? 1 : -1;
                    let resneg = this.sortby.asc ? -1 : 1;
                    if (this.sortby.subprop) {
                        lalista.sort((a, b) => {
                            let alow = a[this.sortby.subprop][this.sortby.col] ? 1 : 0;
                            let blow = b[this.sortby.subprop][this.sortby.col] ? 1 : 0;
                            if (alow > blow) {
                                return respos;
                            } else if (alow < blow) {
                                return resneg;
                            } else {
                                return 0;
                            }
                        });
                    } else {
                        lalista.sort((a, b) => {
                            let alow;
                            let blow;
                            if (this.sortby.col == "allfiles") {
                                alow = this.tieneAllfiles(a?.entityFiles || {});
                                blow = this.tieneAllfiles(b?.entityFiles || {});
                            } else {
                                alow = a[this.sortby.col];
                                blow = b[this.sortby.col];
                            }

                            if (alow > blow) {
                                return respos;
                            } else if (alow < blow) {
                                return resneg;
                            } else {
                                return 0;
                            }
                        });
                    }
                }
                this.entitiesSelected = [];
                return lalista;
            },
            allvisibleSelected() {
                return (
                    this.dinalist.length > 0 &&
                    this.dinalist.every((untimex) => {
                        return this.entitiesSelected.includes(untimex.id);
                    })
                );
            },
            listToFilterToShow() {
                if (this.filterByPatDatOpened) {
                    return Object.values(
                        this.projectInFocusInfo?.entityData?.[this.filterByPatDatOpened]?.alternativas || {},
                    ).filter((unalt) => {
                        return !unalt.inct;
                    });
                } else {
                    return [];
                }
            },
            enabledFiles() {
                return this.projectInFocusInfo.entityFiles ? this.allEnabler(this.projectInFocusInfo.entityFiles) : [];
            },
            enabledData() {
                return this.projectInFocusInfo.entityData ? this.allEnabler(this.projectInFocusInfo.entityData) : [];
            },
            InstitutionsICanEditFrom() {
                if (this.myPrivsHere?.admin && this.myPrivsHere?.admin.full) {
                    return Object.values(this.projectInFocusInfo.institutions).filter((uninst) => {
                        return !uninst.inactive;
                    });
                } else if (this.myPrivsHere?.prv) {
                    let cuales = Object.values(this.myPrivsHere?.prv).filter((potinsti) => {
                        return (
                            potinsti.det &&
                            (potinsti.det.update || potinsti.det.create) &&
                            !this.projectInFocusInfo.institutions[potinsti.id].inactive
                        );
                    });
                    return cuales;
                } else {
                    return [];
                }
            },
            userHasReadPrivilegeInProject() {
                return this.userHasPrivileges({
                    requiredAdminPrivs: ["read"],
                });
            },
            userHasDownloadPrivilegeInInstitution() {
                return this.userHasPrivileges({
                    requiredInstitutionPrivs: ["download"],
                });
            },
            userHasDownloadPrivilegeInProject() {
                return this.userHasPrivileges({
                    requiredAdminPrivs: ["download"],
                });
            },
            userCanDownloadDeidentifiedBundles() {
                if (!this.projectInFocusInfo.isDeidentificationEnabled) {
                    return false;
                }

                return true;
            },
            thereIsAtLeastOneDownloadableEntityBundle() {
                return this.dinalist.some((entity) => {
                    return this.userCanDownloadEntityBundle(entity);
                });
            },
            institofiltered() {
                return this.institofil.filter((inst) => {
                    return !inst.noVisible;
                });
            },
            bundlesNotDownloaded() {
                let bundles = this.bundlesHistory || {};
                let count = 0;
                Object.values(bundles || {}).forEach((bundle) => {
                    if (bundle.status === 1) count++;
                });
                return count;
            },
            userHasPHIAdminPrivilege() {
                return this.userHasPrivileges({
                    requiredAdminPrivs: ["phi"],
                });
            },
            userHasPHIInstitutionPrivilege() {
                return this.userHasPrivileges({
                    requiredInstitutionPrivs: ["phi"],
                });
            },
            userCanFilter() {
                if (this.projectInFocusInfo.isDeidentificationEnabled) {
                    return this.userHasPHIAdminPrivilege;
                }

                return true;
            },
            userCanDownloadFilesInReferenceProject() {
                const referenceProject = this.referenceProjectFocused;
                const originalProject = this.projectInFocusInfo;

                const userHasAdminPrivilegeInReferenceProject = this.userHasPrivileges({
                    requiredAdminPrivs: ["project-admin"],
                    project: referenceProject,
                });

                if (userHasAdminPrivilegeInReferenceProject) {
                    return true;
                }

                const myInstitutionId = Object.keys(this.userPrivsSelectedProject?.prv || {})[0] || null;
                const mappedInstitutionId = originalProject?.institutions?.[myInstitutionId]?.referencedInstitutionId;

                const userHasDownloadPrivilegeInReferencedInstitution =
                    this.user?.privsByProject?.[referenceProject.id]?.prv?.[mappedInstitutionId]?.det?.download;

                return userHasDownloadPrivilegeInReferencedInstitution;
            },
        },
        mounted() {
            this.getMeThese(["forms_logic"]);
        },
        watch: {
            shouldForceRefreshEntities: {
                handler(value) {
                    if (value) {
                        this.$f7.dialog.alert("Some entities have been recently updated. Entities will be refreshed.");
                        this.refreshEntities();
                        this.$store.commit("setWhatTo", {
                            what: "shouldForceRefreshEntities",
                            to: false,
                        });
                    }
                },
            },
            user() {
                if (this.allentities.length) this.getFilteredEntities();
            },
        },
        methods: {
            getEntityInstitutionName(entity) {
                if (entity.isDeidentified) {
                    const institution = this.projectInFocusInfo?.institutions?.[entity?.instid] || {};

                    if (institution) {
                        return institution?.name || entity?.instid;
                    } else {
                        return entity?.instid;
                    }
                }

                const institution = this.projectInFocusInfo?.institutions?.[entity?.instid] || {};
                return institution?.name || entity?.instid;
            },
            userCanDownloadNonDeidentifiedBundles(entities) {
                if (this.projectInFocusInfo.isDeidentificationEnabled) {
                    const myInstitutionId = Object.keys(this.userPrivsSelectedProject?.prv || {})[0] || null;
                    const areAllEntitiesFromMyInstitution = entities.every((entityId) => {
                        const entity = this.dinalist.find((e) => e.id === entityId);

                        if (!entity) {
                            return false;
                        }

                        return entity.instid === myInstitutionId;
                    });

                    if (areAllEntitiesFromMyInstitution) {
                        return this.userHasPrivileges({
                            requiredInstitutionPrivs: ["phi"],
                        });
                    }

                    const isUserAllowed = this.userHasPrivileges({
                        requiredAdminPrivs: ["phi"],
                    });

                    return isUserAllowed;
                }

                return true;
            },
            userCanDownloadEntityBundle(entity) {
                if (this.userHasDownloadPrivilegeInProject) {
                    return true;
                } else if (this.userHasDownloadPrivilegeInInstitution) {
                    return this.myPrivsHere?.prv?.[entity.instid]?.det?.download;
                }

                return false;
            },
            userHasPHIPrivilegeInInstitution(institutionId) {
                const userPrivs = this.myPrivsHere;
                const userInstitution = Object.keys(userPrivs?.prv || {})[0];

                if (!userPrivs || !userInstitution) {
                    return false;
                }

                if (userInstitution === institutionId) {
                    return true;
                }

                return userPrivs?.admin && (userPrivs?.admin.phi || userPrivs?.admin["project-admin"]);
            },
            isEntityDataPHI(entityDataId) {
                if (this.projectInFocusInfo.entityData[entityDataId].notPhi) {
                    return false;
                } else {
                    return true;
                }
            },
            isEntityFilePHI(entityFileId) {
                if (this.projectInFocusInfo.entityFiles[entityFileId].notPhi) {
                    return false;
                } else {
                    return true;
                }
            },

            userHasPHIPrivilege(institutionId) {
                if (
                    this.myPrivsHere?.admin &&
                    (this.myPrivsHere?.admin.phi || this.myPrivsHere?.admin["project-admin"])
                ) {
                    return true;
                }

                const institutionPrivileges = Object.keys(this.myPrivsHere?.prv || {});

                if (institutionPrivileges.includes(institutionId)) {
                    return true;
                }

                return false;
            },

            navigateToBundlesHistory() {
                this.$f7.views.main.router.navigate("/bundles-history/");
            },

            activasearch() {
                this.searchIDON = true;
                setTimeout(() => {
                    document.getElementById("elsearher").focus();
                }, 300);
            },
            inactivaSearch() {
                this.searchIDON = false;
                this.queryentytiid = "";
            },
            async decargameselected() {
                if (!this.entitiesSelected.length) {
                    return false;
                }

                if (this.projectInFocusInfo.isDeidentificationEnabled) {
                    if (!this.userCanDownloadNonDeidentifiedBundles(this.entitiesSelected)) {
                        this.downloadMultipleEntitiesBundle({
                            downloadPHIInfo: true,
                            deidentify: true,
                        });
                    } else if (this.userCanDownloadDeidentifiedBundles) {
                        this.$f7.dialog
                            .create({
                                text: "Do you want to download the<br>de-identified version of the bundle(s)?",
                                title: "De-identification",
                                buttons: [
                                    {
                                        text: "Cancel",
                                        bold: false,
                                    },
                                    {
                                        text: "Yes",
                                        bold: false,
                                        onClick: () => {
                                            this.downloadMultipleEntitiesBundle({
                                                downloadPHIInfo: true,
                                                deidentify: true,
                                            });
                                        },
                                    },
                                    {
                                        text: "No",
                                        bold: true,
                                        onClick: () => {
                                            this.downloadMultipleEntitiesBundle({
                                                downloadPHIInfo: true,
                                                deidentify: false,
                                            });
                                        },
                                    },
                                ],
                            })
                            .open();
                    }
                } else {
                    this.downloadMultipleEntitiesBundle({ downloadPHIInfo: true, deidentify: false });
                }

                /* // PHI Download has been temporarily disabled
            if (true || !userHasPrivilegeInSomeSelectedEntities) {
                this.downloadMultipleEntitiesBundle({ downloadPHIInfo: true });
                return;
            }

            this.$f7.dialog
                .create({
                    text: 'Do you want to include Personal Health Information in the bundle(s)?',
                    title: 'Personal Health Information (PHI)',
                    buttons: [
                        {
                            text: 'Cancel',
                            bold: false,
                        },
                        {
                            text: 'Exclude',
                            bold: false,
                            onClick: () => {
                                this.downloadMultipleEntitiesBundle({
                                    downloadPHIInfo: false,
                                });
                            },
                        },
                        {
                            text: 'Include',
                            bold: true,
                            onClick: () => {
                                this.downloadMultipleEntitiesBundle({
                                    downloadPHIInfo: true,
                                });
                            },
                        },
                    ],
                })
                .open(); */
            },

            async downloadMultipleEntitiesBundle({ downloadPHIInfo = false, deidentify = false }) {
                this.$f7.dialog.preloader(
                    "Generating ZIP file. This action may take a few minutes depending on the size of the bundle...",
                );
                try {
                    let datous = await this.commonExecute(
                        {
                            projectid: this.projectInFocusInfo.id,
                            entities: this.entitiesSelected,
                            downloadPHIInfo,
                            deidentify,
                        },
                        "v2_generaetMultipleZip",
                        false,
                    );
                    if (datous.error) {
                        throw new Error(datous.error.message);
                    }

                    if (datous.asyncProcessWasTriggered) {
                        this.$f7.dialog.close();
                        this.$f7.dialog.alert(
                            "The ZIP file is being generated. You will be notified via email when it is ready.",
                        );
                        return;
                    }

                    this.openDownloadedFile({
                        downloadname: datous.name,
                        url: datous.payload,
                    });

                    this.$f7.dialog.close();
                } catch (error) {
                    this.$f7.dialog.close();
                    console.error(error.code, error.message);
                    this.$f7.dialog.alert(error.message, error.code || "Error");
                }
            },

            openDownloadedFile({ downloadname, url }) {
                let fileLink = document.createElement("a");
                fileLink.href = url;
                fileLink.classList.add("external");
                fileLink.setAttribute("download", downloadname);
                // fileLink.download=downloadname;
                fileLink.setAttribute("target", "_blank");
                document.body.appendChild(fileLink);
                fileLink.click();
                this.$f7.dialog.close();
            },
            checkEntiSelected(event) {
                const value = event.target.value;
                if (event.target.checked) {
                    if (!this.entitiesSelected.includes(value)) {
                        this.entitiesSelected.push(value);
                    }
                } else {
                    this.entitiesSelected.splice(this.entitiesSelected.indexOf(value), 1);
                }
            },
            checkAllEntiSelected(event) {
                Object.values(this.dinalist).forEach((unenti) => {
                    if (this.userCanDownloadEntityBundle(unenti)) {
                        let suindiexer = this.entitiesSelected.indexOf(unenti.id);
                        if (event.target.checked) {
                            if (suindiexer == -1) {
                                this.entitiesSelected.push(unenti.id);
                            }
                        } else {
                            if (suindiexer > -1) {
                                this.entitiesSelected.splice(suindiexer, 1);
                            }
                        }
                    }
                });
            },
            actualizaFiltro(event) {
                const value = event.target.value;
                if (!this.filterByPatDataCol[this.filterByPatDatOpened]) {
                    this.$set(this.filterByPatDataCol, this.filterByPatDatOpened, []);
                }
                let existe = this.filterByPatDataCol[this.filterByPatDatOpened].includes(value);
                if (event.target.checked && !existe) {
                    this.filterByPatDataCol[this.filterByPatDatOpened].push(value);
                } else if (!event.target.checked && existe) {
                    this.filterByPatDataCol[this.filterByPatDatOpened].splice(
                        this.filterByPatDataCol[this.filterByPatDatOpened].indexOf(value),
                        1,
                    );
                    if (!this.filterByPatDataCol[this.filterByPatDatOpened].length) {
                        this.$delete(this.filterByPatDataCol, this.filterByPatDatOpened);
                    }
                }
            },
            setpopoverContent({ target, dataid }) {
                this.filterByPatDataSearch = "";
                this.filterByPatDatOpened = dataid;
                this.$f7.popover.open(".filterPatDataStructured", target);
            },
            setsort(cual, filesordata) {
                let neo = {
                    col: cual,
                    subprop: filesordata,
                    asc: true,
                };
                if (this.sortby.col == cual) {
                    neo.asc = !this.sortby.asc;
                }
                this.sortby = Object.assign({}, neo);
            },
            filterByField(e, field) {
                const { checked, value } = e.target;

                const filteredField = this.filterBy.find((f) => f.field === field);

                if (checked) {
                    if (!filteredField) {
                        this.filterBy.push({
                            field,
                            values: [value],
                        });
                    } else {
                        filteredField.values.push(value);
                    }
                } else {
                    if (filteredField) {
                        filteredField.values.splice(filteredField.values.indexOf(value), 1);
                        if (!filteredField.values.length) {
                            this.filterBy.splice(this.filterBy.indexOf(filteredField), 1);
                        }
                    }
                }
                if (field == "institution") this.checkFilterInsti(e);
                else this.actualizaFiltro(e);
            },
            getFilteredEntities() {
                this.$store.commit("setWhatTo", { what: "allentities", to: [] });
                this.$store.commit("setWhatTo", { what: "lastEntityId", to: null });
                this.areAllEntitiesFetched = false;
                this.getEntities({ force: true });
            },
            checkFilterInsti(event) {
                const value = event.target.value;
                let existe = this.selectedInstitutions.includes(value);
                if (event.target.checked && !existe) {
                    this.selectedInstitutions.push(value);
                } else if (!event.target.checked && existe) {
                    this.selectedInstitutions.splice(this.selectedInstitutions.indexOf(value), 1);
                }
            },
            goToEntity(entity) {
                let index = this.allentities.indexOf(entity);
                this.$f7.views.main.router.navigate(`/entity/`, {
                    props: {
                        index,
                        entity: entity,
                    },
                });
            },
            tieneAllfiles(susfiles = {}) {
                if (!this.visiblFiles.length) return;
                return this.visiblFiles.every((visiblfile) => {
                    return susfiles[visiblfile.id];
                });
            },
            getAnswerValue(value, entityDataId, project = null) {
                let entityData = null;

                if (project) {
                    entityData = project.entityData[entityDataId];
                } else {
                    entityData = this.projectInFocusInfo.entityData[entityDataId];
                }

                const typeOfAnswer = entityData.tipo;

                switch (typeOfAnswer) {
                    case "elige_many":
                        const alternatives = entityData?.alternativas;
                        return Object.keys(value || {})
                            .map((alternativeId) => {
                                const answer = alternatives?.[alternativeId];
                                if (!answer) return value[alternativeId];
                                if (answer.inct) return `${answer.tx} (Inactivated)`;
                                return alternatives?.[alternativeId]?.tx;
                            })
                            .join(", ");

                    case "elige_una":
                        const answer = entityData?.alternativas?.[value];
                        if (!answer) return value;
                        if (answer.inct) return `${answer.tx} (Inactivated)`;
                        return answer.tx;

                    case "input_fecha":
                        if (!value) return value;
                        return this.$dayjs(value).format("MM-DD-YYYY");

                    case "input_dateTime":
                        if (!value) return value;
                        return this.$dayjs(value).format("MM-DD-YYYY HH:mm");

                    default:
                        return value;
                }
            },
            visualizeAnswer(entity, entityDataId, entityProject = null) {
                if (entity.isDeidentified) {
                    // Obtener la respuesta que viene en un objeto
                    const answer = entity.entityData[entityDataId] || {};

                    if (answer.deidentificationType === "Kept") {
                        return this.getAnswerValue(answer.value, entityDataId, entityProject);
                    }

                    return answer.value;
                }

                const answerValue = entity?.entityData?.[entityDataId] || null;
                return this.getAnswerValue(answerValue, entityDataId, entityProject);
            },
            getNextEntities() {
                if (this.loading || this.areAllEntitiesFetched) {
                    return;
                }
                this.$store.commit("setWhatTo", {
                    what: "lastEntityId",
                    to: this.allentities?.[this.allentities.length - 1]?.id,
                });
                this.getEntities({ force: true });
            },
            searchOneEntity() {
                this.$f7.dialog.prompt("Search entity by ID", async (value) => {
                    try {
                        if (value) {
                            this.$f7.dialog.preloader("Searching entity...");

                            const response = await this.commonExecute(
                                {
                                    projectId: this.projectInFocusInfo.id,
                                    entityId: value,
                                },
                                "v2_getEntityById",
                                false,
                            );

                            const { error, entity } = response;

                            if (error) throw Error(error.message);

                            this.goToEntity(entity);

                            this.$f7.dialog.close();
                        } else {
                            this.$f7.dialog.alert("You must enter an ID");
                        }
                    } catch (error) {
                        console.error(error);
                        this.$f7.dialog.close();
                        this.$f7.dialog.alert(error.message, error.code);
                    }
                });
            },
            async getEntities({ force }) {
                if (this.goToProyectDataFromMenu) {
                    this.$store.commit("setWhatTo", {
                        what: "allentities",
                        to: [],
                    });
                    this.$store.commit("setWhatTo", { what: "goToProyectDataFromMenu", to: false });
                    this.$store.commit("setWhatTo", { what: "lastEntityId", to: null });
                }
                if (!force && this.allentities.length > 0) {
                    // Hack para esconder preloader
                    return setTimeout(() => {
                        this.infiniteScroll = false;
                    }, 1000);
                }
                this.infiniteScroll = true;
                if (this.$store.state.activeTab !== "/projectdata/") return;

                try {
                    this.loading = true;
                    const { entities, allowedInstitutionsToRead, error, projectReference } = await this.commonExecute(
                        {
                            projectId: this.projectInFocusInfo.id,
                            sortBy: this.sortBy,
                            filterBy: this.filterBy,
                            orderDirection: this.orderDirection,
                            lastEntityId: this.lastEntityId,
                            limit: this.limit,
                        },
                        "v2_getEntities",
                        false,
                    );
                    if (error) throw Error(error.message);

                    if (entities.length < this.ENTITIES_PER_PAGE) {
                        this.areAllEntitiesFetched = true;

                        // Hack: infinite scroll preloader seguía girando
                        const preloader = this.$$(".infinite-scroll-preloader")[0];
                        if (preloader) preloader.style.display = "none";
                    }
                    this.$store.commit("setWhatTo", {
                        what: "allentities",
                        to: [...this.allentities, ...entities],
                    });

                    this.$store.commit("setWhatTo", {
                        what: "institofil",
                        to: allowedInstitutionsToRead,
                    });

                    this.referenceProjectFocused = projectReference;

                    if (this.userHasDownloadPrivilegeInInstitution || this.userHasDownloadPrivilegeInProject) {
                        await this.fetchBundlesHistory();
                    }
                    this.loading = false;
                } catch (error) {
                    this.loading = false;
                    this.$f7.dialog.alert(error.message, error.code);
                    console.error(error);
                }
            },
            async fetchBundlesHistory() {
                if (
                    this.bundlesHistory &&
                    Object.values(this.bundlesHistory || {})?.[0]?.projectId == this.projectInFocus
                )
                    return;
                try {
                    const response = await this.commonExecute(
                        { projectId: this.projectInFocus },
                        "v2_getUserBundlesHistory",
                    );

                    if (response.error) {
                        throw Error(response.error);
                    }
                    this.$store.commit("setObjTo", { what: "bundlesHistory", to: response.bundlesHistory });
                    this.loading = false;
                } catch (error) {
                    console.error(error.message);
                    this.$f7.dialog.alert(error.message, "There was an error while fetching your bundles history");
                    this.loading = false;
                }
            },
            async refreshEntities() {
                this.$store.commit("setWhatTo", { what: "allentities", to: [] });
                this.getEntities({ force: true });
            },
            generado(nuevopaciente) {
                let local = this.allentities;
                local.push(nuevopaciente);
                this.$store.commit("setWhatTo", { what: "allentities", to: local });
                this.popupOpened = false;
                this.avisaOk("Successfull operation");
            },
            onlyvisible(esteObj) {
                return Object.values(esteObj || {})
                    .filter((unet) => {
                        return unet.visible && !unet.disabled;
                    })
                    .sort((a, b) => {
                        let alow = a.orden;
                        let blow = b.orden;
                        if (alow > blow) {
                            return 1;
                        } else if (alow < blow) {
                            return -1;
                        } else {
                            return 0;
                        }
                    });
            },
            handleFileAddedToQueue() {
                this.$f7.toast.show({
                    text: "File was successfully added to the processing queue. You'll be notified when it is processed via email.",
                    closeTimeout: 6000,
                    closeButton: true,
                    horizontalPosition: "center",
                });
            },
            allEnabler(esteObj) {
                return Object.values(esteObj || {})
                    .filter((unet) => {
                        return !unet.disabled;
                    })
                    .sort((a, b) => {
                        let alow = a.orden;
                        let blow = b.orden;
                        if (alow > blow) {
                            return 1;
                        } else if (alow < blow) {
                            return -1;
                        } else {
                            return 0;
                        }
                    });
            },
            async goToDeletedEntities() {
                try {
                    this.deletedEntitiesPopup = true;
                    this.deletedEntitiesPreloader = true;
                    let snap = await this.$firebase
                        .database()
                        .ref(`paxsByProject_deleted/${this.projectInFocusInfo.id}`)
                        .once("value");
                    this.deletedEntities = Object.values(snap.val() || {});
                    this.deletedEntitiesPopup = true;
                    this.deletedEntitiesPreloader = false;
                } catch (error) {
                    this.deletedEntitiesPopup = false;
                    this.deletedEntitiesPreloader = false;
                    console.log(error);
                    this.$f7.dialog.alert(error.message);
                }
            },

            handleViewReference(entity) {
                console.log("handleViewReference", entity);
                this.referenceEntityFocused = entity.referenceEntity;
                this.isReferencePopupOpened = true;
            },
            filterInactives(entityData) {
                return Object.values(entityData || {}).filter((entity) => {
                    return !entity.disabled;
                });
            },
            getNumberOfFiles(entity, fileId, project) {
                if (project?.entityFiles[fileId].many) {
                    const total = Object.keys(entity?.entityFiles?.[fileId] || {}).length;
                    return `${total} files uploaded`;
                }

                if (entity?.entityFiles[fileId]?.id !== undefined) {
                    return "1 file uploaded";
                }

                return "No files uploaded";
            },
            async getDownloadUrl(preg, subfile = null, downloadname, referenceOptions = {}) {
                console.log("getDownloadUrl", { preg, subfile, downloadname });
                try {
                    let enlatntig = subfile
                        ? this.referenceEntityFocused.entityFiles[preg.id][subfile]
                        : this.referenceEntityFocused.entityFiles[preg.id];
                    this.$f7.dialog.preloader("Generating download...");
                    this.fileToHandle = Object.assign({}, enlatntig);
                    if (this.referenceEntityFocused && this.referenceProjectFocused) {
                        let datous = await this.commonExecute(
                            {
                                projectid: this.projectInFocusInfo.id,
                                entityid: this.referenceEntityFocused.id,
                                institutionid: this.referenceEntityFocused.instid,
                                itemid: referenceOptions.itemid || preg.id,
                                fileid: enlatntig.id,
                                isReferenceEntity: true,
                            },
                            "v2_reqdownload",
                            false,
                        );
                        if (datous.error) {
                            throw new Error(datous.error.message);
                        }
                        let fileinfo = datous.payload;
                        this.openDownloadedFile({ downloadname, url: fileinfo.ulr });
                        this.fileToHandle = null;
                    } else {
                        let datous = await this.commonExecute(
                            {
                                projectid: this.projectInFocusInfo.id,
                                entityid: this.referenceEntityFocused.id,
                                institutionid: this.referenceEntityFocused.instid,
                                itemid: preg.id,
                                fileid: enlatntig.id,
                                isReferenceEntity: true,
                            },
                            "v2_reqdownload",
                            false,
                        );
                        if (datous.error) {
                            throw new Error(datous.error.message);
                        }
                        let fileinfo = datous.payload;
                        this.openDownloadedFile({ downloadname, url: fileinfo.ulr });
                        this.fileToHandle = null;
                    }
                } catch (error) {
                    this.$f7.dialog.close();
                    console.error(error.code, error.message);
                    this.$f7.dialog.alert(error.message, error.code || "Error");
                }
            },
            openDownloadedFile({ downloadname, url }) {
                let fileLink = document.createElement("a");
                fileLink.href = url;
                fileLink.classList.add("external");
                fileLink.setAttribute("download", downloadname);
                // fileLink.download=downloadname;
                fileLink.setAttribute("target", "_blank");
                document.body.appendChild(fileLink);
                fileLink.click();
                this.$f7.dialog.close();
            },
        },
    };
</script>
<style>
    .font11 {
        font-size: 11px !important;
    }
    .font12 {
        font-size: 12px !important;
    }
    .holdea {
        min-width: 200px;
    }

    .noupper {
        text-transform: unset !important;
    }

    .filterloclpop {
        max-height: 400px;
        overflow-y: auto;
    }

    .srchinpute .item-media {
        width: 20px !important;
        min-width: 20px !important;
        position: relative;
        top: -5px;
    }

    .srchinpute .item-content.item-input {
        padding-left: 0px !important;
    }

    .srchinpute input {
        background: #ececec !important;
        padding: 2px 6px !important;
        border-radius: 5px !important;
    }

    .srchinpute .item-inner {
        margin-left: 8px !important;
    }

    .lesticky .ledays,
    .lesticky.deleteItem {
        position: sticky;
        top: 0;
        z-index: 10;
        background: #fff !important;
    }

    .unsetxoverflow {
        overflow-x: unset !important;
        box-shadow: none;
    }

    .fullScreen {
        width: 100% !important;
        height: 100% !important;
        left: 0 !important;
        top: 0 !important;
        margin: 0 !important;
    }

    .projectDataThead {
        position: sticky;
        z-index: 99;
    }

    .backgroundColorWhite {
        background-color: white;
    }
    .newBundle {
        color: black;
    }
    .bundleBadge {
        position: absolute;
        top: 7px;
        left: 30px;
    }
    .bg-deidentified {
        background-color: #f5f5f5;
    }
    .displayInherit {
        display: inherit;
    }
    .maxWidth250 {
        max-width: 250px;
        min-width: 100px;
        white-space: break-spaces;
    }
    .filterinstitutions {
        width: auto;
        overflow-y: auto;
        max-height: 70vh;
    }
</style>
