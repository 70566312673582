<template>
    <f7-popup :opened="opened" @popup:opened="getInstitutionsFromReferenceProject" @popup:closed="$emit('closed')">
        <f7-page>
            <f7-navbar
                :title="`Map ${
                    institution ? institution.name : 'this institution'
                } to an institution in referenced project`"
            >
                <f7-nav-right>
                    <f7-link popup-close>Cancel</f7-link>
                </f7-nav-right>
            </f7-navbar>

            <f7-row class="text-align-center margin" v-if="isLoading">
                <f7-col>
                    <f7-preloader color="primary"></f7-preloader>
                </f7-col>
            </f7-row>
            <template v-else-if="institutionsFromReferenceProject.length > 0">
                <f7-block-title>Institutions in referenced project</f7-block-title>
                <f7-list media-list>
                    <f7-list-item
                        v-for="inst in institutionsFromReferenceProject"
                        :key="`${institution.id}-${inst.id}`"
                        :title="inst.name"
                    >
                        <div slot="after">
                            <f7-toggle
                                @toggle:change="handleInstitutionMapped(inst.id, $event)"
                                :checked="institution.referencedInstitutionId === inst.id"
                                :disabled="isAlreadyMappedToAnotherInstitution(inst.id)"
                            />
                        </div>
                        <div slot="footer">
                            <span v-if="isAlreadyMappedToAnotherInstitution(inst.id)">
                                <font-awesome-icon
                                    :icon="['fad', 'circle-check']"
                                    class="text-color-primary fa-sm"
                                ></font-awesome-icon>
                                Already mapped to another institution
                            </span>
                        </div>
                    </f7-list-item>
                </f7-list>
            </template>
            <f7-list v-else>
                <f7-list-item title="No institutions found in referenced project"></f7-list-item>
            </f7-list>
        </f7-page>
    </f7-popup>
</template>
<script>
    import { computed } from "vue";
    import { mapState, mapGetters } from "vuex";
    import { commonmixin } from "../mixins/common";

    export default {
        props: {
            opened: {
                type: Boolean,
                default: false,
            },
            institution: {
                type: Object,
                default: () => {},
            },
        },

        data() {
            return {
                isLoading: true,
            };
        },

        mixins: [commonmixin],

        computed: {
            ...mapGetters(["projectInFocusInfo"]),
            ...mapState(["institutionsFromReferenceProject"]),
        },

        methods: {
            async getInstitutionsFromReferenceProject() {
                if (this.institutionsFromReferenceProject.length > 0) {
                    this.isLoading = false;
                    return;
                }

                this.isLoading = true;

                const response = await this.commonExecute(
                    { projectId: this.$store.state.projectInFocus },
                    "getInstitutionsFromReferenceProject",
                    false,
                );

                console.log("response", response.institutions);

                this.isLoading = false;

                if (response.error) {
                    return this.$f7.dialog.alert(response.error.message, "Error");
                }

                this.$store.commit("setWhatTo", {
                    what: "institutionsFromReferenceProject",
                    to: response.institutions,
                });
            },

            async handleInstitutionMapped(referencedInstitutionId, selected) {
                this.$f7.dialog.preloader("Mapping institution...");

                this.$store.commit("mapInstitutionToReferenceProject", {
                    projectId: this.$store.state.projectInFocus,
                    institutionId: this.institution.id,
                    referencedInstitutionId,
                    selected,
                });

                const response = await this.commonExecute(
                    {
                        projectId: this.$store.state.projectInFocus,
                        institutionId: this.institution.id,
                        referencedInstitutionId,
                        selected,
                    },
                    "mapInstitutionToReferenceProject",
                    false,
                );

                console.log("response", response);

                this.$f7.dialog.close();

                if (response.error) {
                    return this.$f7.dialog.alert(response.error.message, "Error");
                }
            },

            isAlreadyMappedToAnotherInstitution(referencedInstitutionId) {
                // Check in this.projectInFocusInfo.institutions array
                return Object.values(this.projectInFocusInfo.institutions || {}).some(
                    (inst) =>
                        inst.id !== this.institution.id && inst.referencedInstitutionId === referencedInstitutionId,
                );
            },
        },
    };
</script>
