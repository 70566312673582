<template>
    <f7-page @page:beforein="requestChartInfo()" name="dashboard">
        <f7-navbar>
            <f7-nav-left>
                <f7-link icon-material="menu" panel-open="left"></f7-link>
            </f7-nav-left>
            <f7-nav-title subtitle="Project Overview" title="Dashboard"></f7-nav-title>
            <f7-nav-right>
                <f7-link tooltip="Download project reports as Excel file" popover-open=".report-type-popover">
                    <font-awesome-icon :icon="['fad', 'download']"></font-awesome-icon>
                </f7-link>
                <f7-link tooltip="Refresh" @click="requestChartInfo(true)">
                    <font-awesome-icon :icon="['fad', 'rotate']"></font-awesome-icon>
                </f7-link>
            </f7-nav-right>

            <f7-popover class="report-type-popover" @popover:closed="selectedReportTypes = {}">
                <template v-if="selectedReportTypes.entitiesAnswersCount">
                    <f7-block-title class="margin-vertical-half">
                        Choose an entity data with multiple choice
                    </f7-block-title>
                    <f7-list>
                        <f7-list-item
                            v-for="entityData in entityDatas"
                            :key="entityData.id"
                            @click="handleSelectedEntityDataChange(entityData.id)"
                            link="#"
                        >
                            {{ entityData.encabezado }}
                        </f7-list-item>
                    </f7-list>
                </template>
                <template v-else>
                    <f7-block-title class="margin-vertical-half">Choose a report type</f7-block-title>
                    <f7-list media-list>
                        <f7-list-item
                            v-for="reportType in reportTypes"
                            :key="reportType.id"
                            @click="handleSelectedReportTypeChange(reportType.id)"
                            link="#"
                        >
                            <p slot="title" class="no-margin">
                                {{ reportType.name }}
                            </p>
                            <p slot="text" class="no-margin">
                                {{ reportType.description }}
                            </p>
                        </f7-list-item>
                    </f7-list>
                </template>
            </f7-popover>
        </f7-navbar>
        <f7-block v-if="!projectInFocusInfo.published" class="isreadonlyx no-margin">
            This project is inactive: it's no longer collecting new data.
        </f7-block>

        <f7-progressbar v-if="loading" infinite></f7-progressbar>
        <f7-block>
            <p class="no-margin-top">
                <small v-if="updatedAt" class="margin-bottom">
                    Last updated: {{ loading ? "updating..." : $dayjs.unix(updatedAt).fromNow() }}
                </small>
            </p>
            <f7-list class="no-margin entimeta">
                <f7-list-item :title="`${projectInFocusInfo.entity || 'entity'} count`">
                    <h2 v-if="!loading" slot="after" class="text-align-center no-margin-vertical">
                        {{ totals }}
                    </h2>
                    <f7-preloader slot="after" v-else></f7-preloader>
                </f7-list-item>
            </f7-list>
            <f7-block-title class="text-color-primary bold">Status of category files</f7-block-title>
            <f7-list class="no-margin">
                <f7-list-item v-if="!Object.keys(filesCumulative || {}).length">No files visible</f7-list-item>
                <f7-list-item v-else v-for="file in filesCumulative" :key="file.id" :title="file.encabezado">
                    <h2 v-if="!loading" slot="after" class="text-align-center no-margin-vertical">
                        {{ totals == 0 ? 0 : Math.round((file.counter / totals) * 100) }}%
                    </h2>
                    <f7-preloader slot="after" v-else></f7-preloader>
                </f7-list-item>
            </f7-list>

            <f7-block-title class="text-color-primary bold">Cumulative count over time</f7-block-title>
            <f7-card class="no-margin-horizontal no-margin-bottom">
                <f7-card-content class="text-align-center alto160">
                    <f7-preloader v-if="loading"></f7-preloader>
                    <line-chart
                        v-else-if="letimecharter.datasets[0].data.length && letimecharter.datasets[0].data[0].x"
                        :chart-data="letimecharter"
                        :height="160"
                    ></line-chart>
                    <f7-block v-else>No data</f7-block>
                </f7-card-content>
            </f7-card>

            <f7-block-title class="text-color-primary bold">
                Contributions by {{ projectInFocusInfo.grouper || "Institutions" }}
            </f7-block-title>
            <f7-card class="no-margin-horizontal no-margin-bottom">
                <f7-card-content class="text-align-center alto160">
                    <bar-chart
                        :chart-data="contributions"
                        :height="160"
                        :chartOptions="noLegendOptionChart"
                    ></bar-chart>
                </f7-card-content>
                <f7-card-content>
                    <f7-list class="no-margin-top" v-if="!loading" accordion-list>
                        <f7-list-item accordion-item>
                            <span slot="title">
                                <span class="bold">
                                    Detail of Contributions by {{ projectInFocusInfo.grouper || "Institutions" }}
                                </span>
                                (click to expand)
                            </span>
                            <f7-accordion-content>
                                <f7-list class="listadoinsticiones badgebold">
                                    <f7-list-item
                                        v-for="insti in institofil"
                                        :key="insti.id"
                                        :title="insti.name"
                                        :badge-color="insti.inactive ? null : 'blue'"
                                        :badge="insti.contr || '0'"
                                    >
                                        <f7-link
                                            :tooltip="insti.inactive ? 'Inactive' : 'Active'"
                                            class="unsetcurosr"
                                            slot="media"
                                        >
                                            <font-awesome-icon
                                                :icon="['fad', 'circle']"
                                                :color="insti.inactive ? 'red' : 'teal'"
                                            ></font-awesome-icon>
                                        </f7-link>
                                    </f7-list-item>
                                </f7-list>
                            </f7-accordion-content>
                        </f7-list-item>
                    </f7-list>
                </f7-card-content>
            </f7-card>
            <f7-block v-if="loading && !Object.values(chartersToDraw).length" class="text-align-center">
                <f7-preloader></f7-preloader>
                <br />
                Loading data...
            </f7-block>
            <f7-block v-else-if="!Object.values(chartersToDraw).length">
                No dynamic charts configured for this project
            </f7-block>
            <f7-row>
                <f7-col width="50" v-for="unchart in chartersToDraw" :key="unchart.id">
                    <f7-card>
                        <f7-popover :class="`filter-${unchart.id} popover-max-height-fit-content`">
                            <f7-block class="no-margin no-padding-top text-align-center">
                                <f7-block-title class="margin-top">Filter by {{ filter.name }}</f7-block-title>

                                <f7-button
                                    @click="applyFilter(unchart.id)"
                                    fill
                                    small
                                    class="margin"
                                    :disabled="loadingCharts[unchart.id]"
                                >
                                    Apply filter
                                </f7-button>
                                <f7-button
                                    @click="clearFilter(unchart.id)"
                                    small
                                    class="margin"
                                    :disabled="loadingCharts[unchart.id]"
                                >
                                    Clear filter
                                </f7-button>
                            </f7-block>
                            <f7-list class="overflow-y-auto popover-max-height-50vh">
                                <f7-list-item
                                    v-for="alternative in filter.alternatives"
                                    :key="alternative.id"
                                    checkbox
                                    :title="alternative.tx"
                                    @change="handleCategoricalChartFilterChanged(unchart.id, alternative.id)"
                                    :checked="filters[unchart.id] && filters[unchart.id].includes(alternative.id)"
                                ></f7-list-item>
                            </f7-list>
                        </f7-popover>
                        <f7-card-header>
                            <span
                                class="bold"
                                :class="{
                                    'margin-auto': Object.keys(unchart.ns || {}).length > 20,
                                }"
                            >
                                {{ unchart.name }}
                            </span>
                            <span v-if="Object.keys(unchart.ns || {}).length > 20" class="margin-auto bold">
                                Frequency
                            </span>
                            <template
                                v-if="
                                    Object.keys(unchart.ns || {}).length &&
                                    unchart.tipo === 'elige_una' &&
                                    filter &&
                                    filter.alternatives &&
                                    filter.alternatives.length &&
                                    filter.id != unchart.id
                                "
                            >
                                <span>
                                    <f7-link :popover-open="`.filter-${unchart.id}`">
                                        <font-awesome-icon
                                            :icon="['fad', 'filter']"
                                            class="fa-sm margin-right-half"
                                        ></font-awesome-icon>
                                        Filter
                                    </f7-link>

                                    <small v-if="filters[unchart.id] && filters[unchart.id].length">
                                        – Current filters: {{ getCurrentFilters(unchart.id) }}
                                    </small>
                                </span>
                            </template>
                        </f7-card-header>
                        <template v-if="Object.keys(unchart.ns || {}).length">
                            <f7-card-content v-if="loadingCharts[unchart.id]">
                                <f7-row class="text-align-center margin">
                                    <f7-col>
                                        <f7-preloader></f7-preloader>
                                    </f7-col>
                                </f7-row>
                            </f7-card-content>
                            <f7-card-content
                                v-else-if="unchart.tipo == 'elige_una'"
                                class="text-align-center unos250"
                                :class="{
                                    'no-padding': Object.keys(unchart.ns || {}).length > 20,
                                }"
                            >
                                <div
                                    v-if="Object.keys(unchart.ns || {}).length > 20"
                                    class="data-table no-margin max343"
                                >
                                    <table>
                                        <tbody>
                                            <tr
                                                v-for="(alt, id) in sortAnswers(
                                                    unchart.id,
                                                    unchart.ns,
                                                    unchart.printer,
                                                    unchart.altsdic,
                                                )"
                                                :key="id"
                                                class="text-align-center"
                                            >
                                                <td>
                                                    {{ unchart.altsdic[id].tx }}
                                                </td>
                                                <td>{{ alt }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <bar-chart
                                    v-else
                                    :chart-data="unchart.printer"
                                    :options="noLegendOptionChart"
                                ></bar-chart>
                            </f7-card-content>
                            <f7-card-content v-else>
                                <f7-list>
                                    <f7-list-item divider title="Descriptive Statistics"></f7-list-item>
                                    <f7-list-item title="Average" :after="unchart.mean || '0'"></f7-list-item>
                                    <f7-list-item title="Count" :after="unchart.size || '0'"></f7-list-item>
                                    <f7-list-item title="Max" :after="unchart.max || '0'"></f7-list-item>
                                    <f7-list-item title="Q3" :after="unchart.q3 || '0'"></f7-list-item>
                                    <f7-list-item title="Median" :after="unchart.median || '0'"></f7-list-item>
                                    <f7-list-item title="Q1" :after="unchart.q1 || '0'"></f7-list-item>
                                    <f7-list-item title="Min" :after="unchart.min || '0'"></f7-list-item>
                                </f7-list>
                            </f7-card-content>
                        </template>
                        <f7-card-content v-else>
                            <f7-block class="margin-vertical text-align-center text-color-gray">No data</f7-block>
                        </f7-card-content>
                    </f7-card>
                </f7-col>
            </f7-row>
            <f7-row>
                <f7-col>
                    <!-- Age chart data -->
                    <template v-if="entitiesAgeChartData">
                        <f7-block-title>Duration data</f7-block-title>
                        <f7-card>
                            <f7-card-content v-if="loadingAgeChart">
                                <f7-row class="text-align-center margin">
                                    <f7-col>
                                        <f7-preloader></f7-preloader>
                                    </f7-col>
                                </f7-row>
                            </f7-card-content>
                            <template v-else>
                                <f7-card-header v-if="entitiesAgeChartData.filter">
                                    <!-- Add a filter -->
                                    <span>
                                        {{ entitiesAgeChartData.name }} ({{
                                            entitiesAgeChartData.durationName || "Duration"
                                        }})
                                    </span>
                                    <span v-if="!isDurationChartEmpty">
                                        <f7-link popover-open=".filter-age">
                                            <font-awesome-icon
                                                :icon="['fad', 'filter']"
                                                class="fa-sm margin-right-half"
                                            ></font-awesome-icon>
                                            Filter
                                        </f7-link>
                                        <small v-if="ageChartFilters.length">
                                            – Current filters: {{ currentAgeFilters }}
                                        </small>
                                    </span>
                                </f7-card-header>
                                <f7-card-content>
                                    <div v-if="isDurationChartEmpty">
                                        <f7-block class="margin-vertical text-align-center text-color-gray">
                                            No data
                                        </f7-block>
                                    </div>
                                    <div v-else class="text-align-center alto160">
                                        <bar-chart
                                            :chart-data="entitiesAgeChartData.chartData"
                                            :height="160"
                                            :chart-options="noLegendOptionChart"
                                        ></bar-chart>
                                    </div>
                                </f7-card-content>
                                <f7-card-footer v-if="!isDurationChartEmpty">
                                    <f7-list media-list style="width: 100%">
                                        <f7-list-item title="Mean" :after="entitiesAgeChartData.mean"></f7-list-item>
                                        <f7-list-item
                                            title="Standard deviation"
                                            :after="entitiesAgeChartData.standardDeviation"
                                        ></f7-list-item>
                                    </f7-list>
                                </f7-card-footer>
                            </template>
                        </f7-card>

                        <f7-popover class="filter-age popover-max-height-fit-content">
                            <template v-if="entitiesAgeChartData && entitiesAgeChartData.filter">
                                <f7-block class="no-margin no-padding-top text-align-center">
                                    <f7-block-title class="margin-top">
                                        Filter by {{ entitiesAgeChartData.filter.name }}
                                    </f7-block-title>
                                    <f7-button @click="applyAgeFilter" fill small class="margin">
                                        Apply filter
                                    </f7-button>
                                    <f7-button @click="clearAgeFilter" small class="margin">Clear filter</f7-button>
                                </f7-block>
                                <f7-list class="overflow-y-auto popover-max-height-50vh">
                                    <f7-list-item
                                        v-for="alternative in entitiesAgeChartData.filter.alternatives"
                                        :key="alternative.id"
                                        checkbox
                                        :title="alternative.tx"
                                        @change="handleAgeFilterChange(alternative.id)"
                                        :checked="ageChartFilters.includes(alternative.id)"
                                    ></f7-list-item>
                                </f7-list>
                            </template>
                        </f7-popover>
                    </template>
                </f7-col>
            </f7-row>
        </f7-block>
    </f7-page>
</template>
<script>
    import { mapGetters, mapState } from "vuex";
    import BarChart from "../components/BarChart.vue";
    //import PieChart from '../components/PieChart.js';
    import LineChart from "../components/LineChart.vue";
    export default {
        components: {
            BarChart,
            // PieChart,
            LineChart,
        },
        data() {
            return {
                loading: true,
                datacollection: null,
                chartersToDraw: {},
                filters: {},
                ageChartFilters: [],
                filter: {},
                updatedAt: null,
                totals: 0,
                institofil: [],
                filesCumulative: null,
                contributions: {
                    labels: [],
                    datasets: [],
                },
                loadingAgeChart: false,
                entitiesAgeChartData: null,
                letimecharter: {
                    datasets: [
                        {
                            label: "Cumulative Count",
                            borderColor: "green",
                            fill: false,
                            steppedLine: true,
                            data: [],
                        },
                    ],
                },
                loadingCharts: {},
                noLegendOptionChart: {
                    plugins: {
                        legend: {
                            display: false,
                        },
                        datalabels: {
                            display: true,
                            color: "white",
                            align: "end",
                            offset: -7,
                            font: {
                                weight: "bold",
                            },
                        },
                    },
                    responsive: true,
                    maintainAspectRatio: true,
                    indexAxis: "y",
                    scales: {
                        x: {
                            ticks: {
                                precision: 0,
                                beginAtZero: true,
                            },
                        },
                    },
                },
                myStyles: {
                    height: `160px`,
                    position: "relative",
                },

                reportTypes: [
                    {
                        id: "entitiesCount",
                        name: "Entities Count",
                        description: "Number of entities in the project",
                    },
                    {
                        id: "entitiesFilesCount",
                        name: "Entity Files Count",
                        description: "Number of files completed per entity",
                    },
                    {
                        id: "entitiesAnswersCount",
                        name: "Entity Data Answers Count",
                        description: "Number of answers for a given entity data",
                    },
                ],

                selectedReportTypes: {},
            };
        },
        computed: {
            ...mapState(["user", "projectInFocus"]),
            ...mapGetters(["projectInFocusInfo"]),
            suprivx() {
                return this.user.privx || {};
            },
            myPrivsHere() {
                let allprivs = this.user.privsByProject || {};
                return allprivs[this.$store.state.projectInFocus] || {};
            },
            entityDatas() {
                return Object.values(this.projectInFocusInfo.entityData || {}).filter((ed) => {
                    return !ed.disabled && (ed.tipo === "elige_many" || ed.tipo === "elige_una");
                });
            },
            currentAgeFilters() {
                return this.entitiesAgeChartData.filter.alternatives
                    .filter((alt) => this.ageChartFilters.includes(alt.id))
                    .map((alt) => alt.tx)
                    .join(", ");
            },
            isDurationChartEmpty() {
                return (this.entitiesAgeChartData?.mean || 0) === 0;
            },
        },
        //  watch:{
        //     projectInFocus(neoprojectid){
        //        // console.log('ATENTO, CAMBIO DE CENTRO',neoprojectid);
        //        this.requestChartInfo();
        //     }
        // },
        methods: {
            handleAgeFilterChange(alternativeId) {
                if (this.ageChartFilters.includes(alternativeId)) {
                    this.ageChartFilters = this.ageChartFilters.filter((id) => id !== alternativeId);
                } else {
                    this.ageChartFilters.push(alternativeId);
                }
            },

            async applyAgeFilter() {
                try {
                    this.loadingAgeChart = true;

                    const response = await this.commonExecute(
                        {
                            projectId: this.projectInFocus,
                            filterBy: this.ageChartFilters,
                            filterEntityDataId: this.entitiesAgeChartData.filter.id,
                        },
                        "v2_filterAgeChartDashboard",
                    );

                    if (response.error) {
                        throw new Error(response.error.message);
                    }

                    this.entitiesAgeChartData.chartData = response.chartData;
                    this.entitiesAgeChartData.mean = response.mean;
                    this.entitiesAgeChartData.standardDeviation = response.standardDeviation;

                    this.$f7.popover.close(".filter-age");

                    this.loadingAgeChart = false;
                } catch (error) {
                    console.error(error.message);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error.message, "Error");
                    this.loadingAgeChart = false;
                }
            },

            clearAgeFilter() {
                this.ageChartFilters = [];
                this.applyAgeFilter();
            },

            clearFilter(chartId) {
                this.filters[chartId] = [];
                this.applyFilter(chartId);
            },

            async applyFilter(chartId) {
                try {
                    this.$set(this.loadingCharts, chartId, true);
                    const response = await this.commonExecute(
                        {
                            projectId: this.projectInFocus,
                            filterBy: this.filters[chartId],
                            filterEntityDataId: this.filter.id, // to do: cambiar por alguna propiedad filter no dentro de entitiesGenderChartData (en root)
                            entityDataId: chartId,
                        },
                        "v2_filterCategoricalChartDashboard",
                    );
                    if (response.error) {
                        throw new Error(response.error.message);
                    }
                    const entityDataChart = this.chartersToDraw.find((chart) => chart.id === chartId);
                    this.$set(entityDataChart, "printer", response.chartData);
                    this.$set(this.loadingCharts, chartId, false);
                    this.$f7.popover.close(`.filter-${chartId}`);
                } catch (error) {
                    console.error(error.message);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error.message, "Error");
                    this.$set(this.loadingCharts, chartId, false);
                    this.$f7.popover.close(`.filter-${chartId}`);
                }
            },

            handleCategoricalChartFilterChanged(chartId, alternativeId) {
                if (!this.filters[chartId]) {
                    this.filters[chartId] = [];
                }

                if (this.filters[chartId].includes(alternativeId)) {
                    this.filters[chartId] = this.filters[chartId].filter((id) => id !== alternativeId);
                } else {
                    this.filters[chartId].push(alternativeId);
                }
            },

            getCurrentFilters(chartId) {
                if (!this.filters[chartId]) return null;
                const filterAlternatives = this.entityDatas.find((ed) => ed.id === this.filter.id)?.alternativas;
                if (!filterAlternatives) return null;
                return this.filters[chartId]
                    .map((alternativeId) => {
                        const alternative = filterAlternatives[alternativeId];
                        return alternative.tx;
                    })
                    .join(", ");
            },

            handleSelectedReportTypeChange(type) {
                this.selectedReportTypes = {};
                this.selectedReportTypes[type] = true;

                if (type !== "entitiesAnswersCount") {
                    this.downloadProjectExcelReport();
                }
            },

            handleSelectedEntityDataChange(entityDataId) {
                this.selectedReportTypes = {
                    entitiesAnswersCount: {
                        entityDataId: entityDataId,
                    },
                };

                this.downloadProjectExcelReport();
            },

            async downloadProjectExcelReport() {
                try {
                    if (!Object.keys(this.selectedReportTypes).length) {
                        return this.$f7.dialog.alert("Please select a report type to download");
                    }

                    this.$f7.popover.close(".report-type-popover");

                    this.$f7.dialog.preloader("Downloading Excel Report");
                    /*
                const response = await this.commonExecute(
                    {
                        projectId: this.projectInFocus,
                        reportTypes: this.selectedReportTypes,
                    },
                    'downloadProjectExcelReport',
                    false
                );

                if (response.error) {
                    throw new Error(response.error);
                }

                this.$f7.dialog.close();

                const { fileUrl } = response;

                window.open(fileUrl, '_blank');
                */
                    let reportType = null;
                    let reportName = null;
                    if (this.selectedReportTypes.entitiesFilesCount) {
                        reportType = "entitiesFilesCount";
                        reportName = "entitiesFilesCount";
                    } else if (this.selectedReportTypes.entitiesCount) {
                        reportType = "entitiesCount";
                        reportName = "entitiesCount";
                    } else {
                        reportType = this.selectedReportTypes.entitiesAnswersCount.entityDataId;
                        reportName = this.projectInFocusInfo.entityData[reportType].encabezado;
                    }
                    let idToken = await this.$firebase.auth().currentUser.getIdToken();
                    let endpoint = this.$store.state.dev
                        ? "https://us-central1-cielo-one.cloudfunctions.net"
                        : "https://us-central1-cielo-iddrc-braingeneregistry.cloudfunctions.net"; //'http://localhost:5001/cielo-one/us-central1' 'https://us-central1-cielo-one.cloudfunctions.net 'https://us-central1-cielo-iddrc-braingeneregistry.cloudfunctions.net'
                    let url = `${endpoint}/v2_exporter/downloadProjectExcelReport/${this.projectInFocus}/${this.user.uid}/${reportType}`;
                    let response = await this.$axios.get(url, {
                        headers: { Authorization: "Bearer " + idToken },
                        responseType: "blob", // important
                    });
                    if (response.data.type == "application/json") {
                        this.$f7.dialog.close();
                        var textPromise = await response.data.text();
                        return this.$f7.dialog.alert(textPromise, "Error");
                    }
                    const fileURL = window.URL.createObjectURL(
                        new Blob([response.data], {
                            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                        }),
                    );
                    let fileLink = document.createElement("a");
                    document.body.appendChild(fileLink);
                    fileLink.href = fileURL;
                    fileLink.setAttribute(
                        "download",
                        `${reportName}_report_${this.$dayjs().format("MM-DD-YYYY")}.xlsx`,
                    );
                    fileLink.classList.add("external");
                    fileLink.click();
                    window.URL.revokeObjectURL(fileURL);
                    this.$f7.dialog.close();
                } catch (error) {
                    console.error(error.message);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error.message);
                }
            },
            async requestChartInfo(forceUpdate = false) {
                //getEntitiesCreated
                try {
                    this.loading = true;
                    let datous = await this.commonExecute(
                        { projectid: this.projectInFocusInfo.id, forceUpdate: true },
                        "v2_getEntitiesDash",
                        false,
                    );
                    if (datous.error) {
                        throw new Error(datous.error.message);
                    }

                    this.updatedAt = datous.payload.updatedAt || null;
                    this.totals = datous.payload.totals;
                    this.filter = datous.payload.filter || [];

                    this.chartersToDraw = datous.payload.chartersToDraw || {};
                    this.institofil = datous.payload.institofil || [];
                    this.filesCumulative = datous.payload.filesCumulative || {};
                    this.entitiesAgeChartData = datous.payload.entitiesAgeChartData || null;

                    this.letimecharter = Object.assign(
                        {},
                        {
                            datasets: [
                                {
                                    label: "Cumulative Count",
                                    // backgroundColor: Utils.transparentize(Utils.CHART_COLORS.green, 0.5),
                                    borderColor: "green",
                                    fill: false,
                                    steppedLine: true,
                                    data: datous.payload.toChartCumulative,
                                },
                            ],
                        },
                    );
                    this.datacollection = Object.assign(
                        {},
                        {
                            labels: ["lunes", "martes", "miercoles", "jueves"],
                            datasets: [
                                {
                                    label: "Increase",
                                    data: [3, 4, 7, 8],
                                    fill: false,
                                    stepped: true,
                                },
                            ],
                        },
                    );
                    let pierchartinfo = datous.payload.pierchart || {};
                    this.contributions = Object.assign({}, this.contributions, {
                        labels: pierchartinfo.labelas,
                        datasets: [
                            {
                                data: pierchartinfo.valores,
                                backgroundColor: pierchartinfo.colores,
                            },
                        ],
                    });
                    this.loading = false;
                } catch (error) {
                    this.loading = false;
                    console.error(error);
                    this.$f7.dialog.alert(error.message, error.code);
                }
            },
            sortAnswers(pregid, answers, printer, altsdic) {
                const sortAlphabetically = this.projectInFocusInfo?.entityData?.[pregid]?.sortAlphabetically;
                let answersArray = Object.keys(answers);
                if (printer) {
                    answersArray = answersArray.filter((answerId) => {
                        return printer.labels.includes(altsdic[answerId].tx);
                    });
                    answersArray.forEach((answerId) => {
                        const answerIndex = printer.labels.indexOf(altsdic[answerId].tx);
                        answers[answerId] = printer.datasets[0].data[answerIndex];
                    });
                }
                if (!sortAlphabetically) {
                    let array = answersArray.sort((a, b) => {
                        if (answers[a] > answers[b]) return -1;
                        else if (answers[a] < answers[b]) return 1;
                        else if (answers[a] == answers[b]) {
                            return this.projectInFocusInfo?.entityData?.[pregid]?.alternativas[a].tx.localeCompare(
                                this.projectInFocusInfo?.entityData?.[pregid]?.alternativas[b].tx,
                            );
                        }
                    });
                    let toReturn = {};
                    array.forEach((alt) => {
                        toReturn[alt] = answers[alt];
                    });
                    return toReturn;
                } else {
                    let array = answersArray.sort((a, b) => {
                        return this.projectInFocusInfo?.entityData?.[pregid]?.alternativas[a].tx.localeCompare(
                            this.projectInFocusInfo?.entityData?.[pregid]?.alternativas[b].tx,
                        );
                    });
                    let toReturn = {};
                    array.forEach((alt) => {
                        toReturn[alt] = answers[alt];
                    });
                    return toReturn;
                }
            },
        },
    };
</script>
<style>
    .entimeta .item-media {
        display: block !important;
        text-align: center !important;
        margin: 0 auto !important;
    }
    .listadoinsticiones {
        max-height: 380px !important;
        overflow-y: auto;
    }
    .listadoinsticiones .item-media {
        text-align: center;
    }
    .listadoinsticiones .item-media a {
        margin: 0 auto;
    }
    .unsetcurosr {
        cursor: unset !important;
    }
    .badgebold .badge {
        font-weight: bold !important;
    }
    .alto160 {
        position: relative !important;
        min-height: 300px;
    }
    .unos250 {
        min-height: 250px !important;
    }
    .max343 {
        max-height: 343px;
    }
    .margin-auto {
        margin: auto !important;
    }
    .report-type-popover {
        width: 290px;
    }
    .popover-max-height-50vh {
        max-height: 50vh;
    }
    .overflow-y-auto {
        overflow-y: auto;
    }
    .popover-max-height-fit-content {
        max-height: fit-content;
    }
</style>
